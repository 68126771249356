import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, InputAdornment, Tooltip, useMediaQuery, useTheme, Select, MenuItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import {  Typography, Drawer } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'; // Add this import
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import { useRef } from 'react';
import { setTokens } from '../../../store/slices/authSlice';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const style = {
	zIndex: 1,
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		md: '80vw',
	},
	height: {
		xs: '100%',
		md: '80vh',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	borderRadius: '2%',
	outline: 'none',
	boxShadow: 24,
	p: 4,
	//display: 'flex',
};

const style2 = {
	zIndex: 2,
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	outline: 'none',
	p: 4,
	width: {
		xs: '100%',
		md: '60vw',
	},
};

export default function ChatModal({currentProject, setTokensFillModal}) {
	const [open,setOpen] = useState(false)
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const userId = localStorage.getItem('userId');
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [chats, setChats] = useState([])
	const [currentChat,setCurrentChat] = useState(null)
	const [fieldValue, setFieldValue] = useState('')
	const [messages, setMessages] = useState([])
	const messagesContainer = useRef()
	const theme = useTheme();
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
	const [loading, setLoading] = useState(false);
	//const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
	const [chatFees,setChatFees] = useState(0)
	const [drawerOpen, setDrawerOpen] = useState(true);
	const isMobile = useMediaQuery(theme.breakpoints.down('md')); 
	const [characterModalOpen, setCharacterModalOpen] = useState(false); 
	const [characters, setCharacters] = useState([]);
	const [selectedCharacter, setSelectedCharacter] = useState('');
	const [chatTitle, setChatTitle] = useState('')
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [chatToDelete, setChatToDelete] = useState(null);
	const [streamingMessage, setStreamingMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [botName, setBotName] = useState('STORYBUILT');
	const messagesEndRef = useRef(null);
	const textFieldRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedChat, setSelectedChat] = useState(null);
	const [editTitleOpen, setEditTitleOpen] = useState(false);
	const [newTitle, setNewTitle] = useState('');

	useEffect(() => {
		if (open || characterModalOpen) {
			document.body.style.overflow = 'hidden';
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';
		} else {
			document.body.style.overflow = '';
			document.body.style.position = '';
			document.body.style.width = '';
		}

		return () => {
			document.body.style.overflow = '';
			document.body.style.position = '';
			document.body.style.width = '';
		};
	}, [open, characterModalOpen]);

	useEffect(() => {
		if (messages.length > 0 && messages[messages.length - 1].role === 'assistant') {
			textFieldRef.current?.focus();
		}
	}, [messages]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages, streamingMessage]);

	useEffect(() => {
		
			setDrawerOpen(true); // Ensure the drawer is open when the chat modal opens
		
	}, [open]);

	useEffect(() => {
		console.log('Screen width:', window.innerWidth);
		console.log('isMobile:', isMobile);
	  }, [isMobile]);

	async function addCharacterChat() {
		setCharacterModalOpen(true); // Open the character modal
		getCharacters();
	  }

	  useEffect(() => {
		if (open || characterModalOpen) {
			document.body.style.overflow = 'hidden'; // Prevent background scrolling
		} else {
			document.body.style.overflow = 'auto'; // Re-enable background scrolling
		}
		return () => {
			document.body.style.overflow = 'auto'; // Clean up on unmount
		};
	}, [open, characterModalOpen]); // Depend on modal open states

	useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		  constants.BASE_URL + 'api/fees/get-fees',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  dispatch(removeLoadingFlag('get-fees'));
		  if(response.data.hasOwnProperty('characters')){
			setChatFees(response.data.chatMessage)
		  }
		}).catch(function (error) {
		  if (error.response.data === 'Permission denied') {
			navigate('/#');
		  }
		  // console.log(error);
		  dispatch(removeLoadingFlag('get-fees'));
		});
	  },[alert,dispatch,token])

	useEffect(()=> {
		if(currentChat && messages.length > 1) {
			const container = messagesContainer.current 
			container.scrollTop = messagesContainer.current?.scrollHeight;
		}
	},[messages])

	useEffect(()=>{
		if (userId && currentProject.title) {
			// dispatch(addLoadingFlag('get-chats'));
			axios
			.get(
			  constants.BASE_URL + 'api/chat/get-chats/' + userId,
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			).then(function (response) {
					console.log(response.data)
					// Filter chats based on currentProject._id
					const filteredChats = response.data.filter(chats => 
						chats.projectId === currentProject._id
					);
			
			//   dispatch(removeLoadingFlag('get-chats'));
			  setChats(filteredChats)
			  // console.log(filteredChats)
			}).catch(function (error) {
			  // console.log(error);
			//   dispatch(removeLoadingFlag('get-prompts'));
			});
		}
		
	},[userId, currentProject.title])

	const getCharacters = () => {
		axios
		.get(
			constants.BASE_URL +
				'api/projects/get-characters/' +
				currentProject._id,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then(function (response) {
			console.log(response.data);
			setCharacters(response.data);
		})
		.catch(function (error) {
			// console.log(error);
		});
	};

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

	async function addChat () {
		const getTitleCount = (title) => {
			const titleRegex = new RegExp(`^${title}( \\d+)?$`);
			let count = 0;
			let firstFindFlag = false
			chats.forEach(chat => {
				if (chat.title && titleRegex.test(chat.title)) {
					count++;
					if(!firstFindFlag) {
						count++;
						firstFindFlag = true
					}
				}
			});
			return count;
		};

		const newTitleNumberFuncResult =  getTitleCount(currentProject?.title)
		const newTitle = newTitleNumberFuncResult > 0 ? currentProject?.title + ' ' + newTitleNumberFuncResult : currentProject?.title
		dispatch(addLoadingFlag('add-chat'));
		await axios
		  .post(constants.BASE_URL + 'api/chat/add-chat', {
			userId,
			title: newTitle,
			projectId: currentProject?._id,
		  }, {
			headers: {
			  'Authorization': `Bearer ${token}`
			},
		  } ).then(function (response) {
			dispatch(removeLoadingFlag('add-chat'));
			const newChat = response.data.chat;
			setChats(prev => [...prev, newChat]);
			setCurrentChat(newChat); // Set the current chat to the newly created chat
			setBotName(null);
			if (isMobile) {
				setDrawerOpen(false); // Close the drawer on mobile after selecting the new chat
			}
		  })
		  .catch(function (error) {
			if (error.response?.data === 'error with openAi') {
				alert.show(
				  `Something went wrong with AI server`,
				  { type: 'error' }
				);
			}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
				if (localStorage.getItem('role')=== 'Enterprise User') {
				  alert.show(
					`Problem with the subscription. Please notify your Enterprise Admin.`,
					{ type: 'error' }
				  );
				} else {
				  alert.show(
					`Problem with subscription. Please check the Account Info page.`,
					{ type: 'error' }
				  );
				}
							
			} else if (error.response?.data === 'Not enough tokens') {
				if (localStorage.getItem('role') === 'Enterprise User') {
				  alert.show(
					`Not enough AI tokens. Please notify your Enterprise Admin.`,
					{ type: 'error' }
				  );
				} else {
				  alert.show(
					`You don't have enough tokens for this action.`,
					{ type: 'error' }
				  );
				  setTokensFillModal(true)
				}		
			} else {
				alert.show(
					`Something went wrong with text generation.`,
					{ type: 'error' }
				);
				// console.log(error);
			}
			dispatch(removeLoadingFlag('add-chat'));
		  });
	}

	const handleAddCharacterChat = async () => {
		if (!selectedCharacter) return;
	  
		const newTitle = chatTitle; // Use selected character as title
		dispatch(addLoadingFlag('add-chat'));
		await axios
		  .post(
			constants.BASE_URL + 'api/chat/add-chat',
			{
			  userId,
			  title: newTitle,
			  selectedCharacter: selectedCharacter.id,
			  projectId: currentProject?._id,
			},
			{
			  headers: {
				Authorization: `Bearer ${token}`,
			  },
			}
		  )
		  .then(function (response) {
			dispatch(removeLoadingFlag('add-chat'));
			setCurrentChat(response.data.chat);
			setChats((prev) => [...prev, response.data.chat]);
			setBotName(selectedCharacter.name)
			setCharacterModalOpen(false); // Close the character modal
			setSelectedCharacter(''); // Reset selected character
		  })
		  .catch(function (error) {
			dispatch(removeLoadingFlag('add-chat'));
		  });
	  };

	async function handleEditChat(chatId, newTitle) {
		const response = await axios.put(
			`${constants.BASE_URL}api/chat/edit-chat/${chatId}`,
			{ newTitle },  // Pass newTitle in the request body
			{
			  headers: {
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
		  
		  if (response.status !== 200) {
			throw new Error('Failed to update chat title');
		  }
	}

	async function deleteChat (chatId) {
		dispatch(addLoadingFlag('delete-chat'));
		console.log('chat', chatId, 'user', userId)
		await axios
		  .delete(constants.BASE_URL + 'api/chat/delete-chat/' + chatId + '/' + userId, {
			headers: {
			  'Authorization': `Bearer ${token}`
			},
		  }).then(function (response) {
			dispatch(removeLoadingFlag('delete-chat'));
			setChats(prev => prev.filter(chat => (chat._id || chat.chatId) !== chatId));
			alert.show(
				`Chat was successfully deleted`,
				{ type: 'success' }
			);
			if (currentChat.chatId || currentChat._id === chatId) {
				setCurrentChat(null)
				setMessages([])
			}
		  }).catch(function (error) {
			// console.log(error);
			dispatch(removeLoadingFlag('delete-chat'));
		  });
	}

	async function addMessage(message) {
		setLoading(true);
		setIsGenerationButtonAllowed(false);

		try {
			console.log('message', message)
			const response = await fetch(`${constants.BASE_URL}api/chat/add-message`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					chatId: currentChat._id || currentChat.chatId,
					content: message,
					character: currentChat.selectedCharacter || '',
				}),
			});

			const reader = response.body.getReader();
			let partialMessage = '';
			let currentMessage = { content: '', role: 'assistant' };

			while (true) {
				const { done, value } = await reader.read();
				if (done) break;

				const chunk = new TextDecoder().decode(value);
				const events = chunk.split('\n\n');

				for (const event of events) {
					if (event.startsWith('data: ')) {
						const data = JSON.parse(event.slice(6));
						switch (data.type) {
							case 'start':
								// Don't add a new message here, just prepare for streaming
								break;
							case 'message':
								partialMessage += data.content;
								setStreamingMessage(partialMessage);
								break;
							case 'toolCall':
							case 'codeInput':
							case 'codeLogs':
								// Handle tool calls and code interpreter outputs as needed
								console.log(data.type, data.content);
								break;
							case 'end':
								// Add the final message to the messages array
								setMessages(prev => [...prev, { role: 'assistant', content: partialMessage }]);
								setStreamingMessage('');
								break;
							case 'error':
								if (data.message.toLowerCase().includes('not enough tokens')) {
									setTokensFillModal(true);
								}
								alert.show(data.message, { type: 'error' });
								break;
						}
					}
				}
			}
		} catch (error) {
			console.error('Error sending message:', error);
			if (error.message && error.message.toLowerCase().includes('not enough tokens')) {
				setTokensFillModal(true);
			}
			alert.show('Error connecting to the server', { type: 'error' });
		} finally {
			setLoading(false);
			setIsGenerationButtonAllowed(true);
			getUserTokens();
			setFieldValue('');
		}
	}

	useEffect(() => {
		if (currentChat !== null) {
			getChatMessages()
		}
		
	},[currentChat])

	async function getChatMessages () {
		console.log(currentChat)
		let chatId = currentChat._id || currentChat.chatId
		axios
		.get(
		  constants.BASE_URL + 'api/chat/get-chat-messages/' + chatId,
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  setBotName(null)
		  setMessages(response.data.messages)
		  setBotName(response.data.characterName)
		  // console.log(response.data)
		}).catch(function (error) {
		  console.log(error);
		});
	}

	const handleSendMessage = () => {
		if (isGenerationButtonAllowed && !loading && fieldValue.trim()) {
			setMessages(prevMessages => [...prevMessages, {
				content: fieldValue,
				role: 'user'
			}]);
			addMessage(fieldValue);
			setFieldValue('');
		}
	};

	const handleMenuOpen = (event, chat) => {
		setAnchorEl(event.currentTarget);
		setSelectedChat(chat);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleEditTitle = () => {
		setEditTitleOpen(true);
		setNewTitle(selectedChat.title);
		handleMenuClose();
	};

	const handleDeleteChat = () => {
		setChatToDelete(selectedChat.chatId || selectedChat._id);
		setConfirmDialogOpen(true);
		handleMenuClose();
	};

	const handleTitleChange = (event) => {
		setNewTitle(event.target.value);
	};

	const handleTitleSubmit = async () => {
		try {
		  dispatch(addLoadingFlag('edit-chat'));
		  const chatId = selectedChat._id || selectedChat.chatId;
		  await handleEditChat(chatId, newTitle);
		  
		  setChats(chats.map(chat => 
			(chat._id === chatId || chat.chatId === chatId) 
			  ? {...chat, title: newTitle} 
			  : chat
		  ));
		  
		  setEditTitleOpen(false);
		  alert.show('Chat title was successfully updated', { type: 'success' });
		} catch (error) {
		  console.error('Error updating chat title:', error);
		  alert.show('Failed to update chat title', { type: 'error' });
		} finally {
		  dispatch(removeLoadingFlag('edit-chat'));
		}
	  };

	return (
		<> <Tooltip title="Chat with our AI assistant" arrow>
			<Box
				sx={{
					position: 'fixed',
					right: { xs: '15px', md: '7px' },
					bottom: { xs: '8px', md: '5px' },
					width: '50px',
					height: '50px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '50%',
					background: theme.palette.primary.main,
					border: '2px solid #000',
					zIndex: 100,
					cursor: 'pointer',
				}}
				onClick={() => {
					setOpen(true);
					setDrawerOpen(true);
				}}
				>
				<ChatIcon sx={{ fontSize: "30px" }} />
			</Box></Tooltip>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="chat-modal"
				aria-describedby="chat-with-ai"
			>
				<Box sx={style}>
					<IconButton
						onClick={() => {
							if (isMobile) {
							  setDrawerOpen(true);
							} else {
							  setOpen(false);
							}
						  }}
						sx={{
							position: 'absolute',
							top: 8,
							right: 8,
							zIndex: 300000,
							//backgroundColor: 'white',
							'&:hover': {
							backgroundColor: 'gray',
							}
						}}
						>
						<CloseIcon />
					</IconButton>
					<Drawer
						sx={{
							zIndex: 10000,
							width: {
								md: 270,
								xs: '100%',
							},
							'& .MuiDrawer-paper': {
								width: {
									md: 270,
									xs: '100%',
								},
								//height: { xs: '100%', md: 'auto' }, // Full height on xs screens
								backgroundColor: '#29323c',
								borderRadius: { xs: 0, md: '14px' },
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between', // Ensure content is spaced between
								'&::-webkit-scrollbar': {
									width: '2px',
									height: 0,
								},
								'&::-webkit-scrollbar-thumb': {
									borderRadius: '6px',
									backgroundColor: theme.palette.primary.main,
								},
							},
						}}
						variant={isMobile ? 'temporary' : 'permanent'} // Use temporary drawer on mobile
						open={drawerOpen}
						onClose={() => setDrawerOpen(false)} // Add onClose handler for temporary drawer
						anchor='left'
					>
						{isMobile &&
						<IconButton
							onClick={() => setOpen(false)}
							sx={{
								position: 'absolute',
								top: 8,
								right: 8,
								zIndex: 300000,
								//backgroundColor: 'white',
								'&:hover': {
								backgroundColor: 'gray',
								}
							}}
							>
							<CloseIcon />
						</IconButton>}
						<Box sx={{ flex: 1, overflowY: 'auto', mt: {xs: '50px', md: 0} }}>
							{chats.map((chat, index) => (
								<Box
									key={index}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: '100%',
										borderTop: '1px solid black',
										borderBottom: '1px solid black',
										padding: '15px 10px',
										cursor: 'pointer',
									}}
									onClick={() => {
										setCurrentChat(chat);
										if (isMobile) {
											setDrawerOpen(false);
										}
									}}
								>
									<Typography sx={{ flex: 1, textAlign: 'left' }}>
										{chat.title ? chat.title : 'Chat' + (index + 1)}
									</Typography>
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleMenuOpen(e, chat);
										}}
									>
										<MoreVertIcon />
									</IconButton>
								</Box>
							))}
						</Box>
						<Box sx={{ padding: '10px', paddingTop:'15px', background: '#29323c' }}>
							<Button
								variant='contained'
								onClick={addChat}
								sx={{
									fontSize: '16px',
									color: 'white',
									height: '40px',
									width: '100%',
									marginBottom: '10px',
								}}
								disableElevation
							>
								Add new chat
							</Button>
							<Button
								variant='contained'
								onClick={addCharacterChat}
								sx={{
									fontSize: '16px',
									color: 'white',
									height: '40px',
									width: '100%',
								}}
								disableElevation
							>
								Chat with a character
							</Button>
						</Box>
					</Drawer>
					<Box ref={messagesContainer} 
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
							height: { xs: '90%', md: '85%' },
							width: {
								xs: '100%', // Adjust the width for mobile
								md: 'calc(100% - 270px)'
							},		
							marginLeft: { xs: 0, md: '270px' },				
							position: 'static',
							overflow: 'auto',
							marginTop: '0px', 
							padding: { xs: '0 0px', md: '0 20px' },
							'& > :first-of-type': {
							},
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: theme.palette.primary.main,
							},
						}}>
					{(currentChat !== null && messages !== null) && messages.map((message, index) => (
						<Box
							key={index}
							sx={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
								marginBottom: '20px',
							}}
						>
							<Typography
								sx={{
									fontSize: '12px',
									fontWeight: 'bold',
									color: message.role === 'user' ? 'white' : 'white',
									//color: message.role === 'user' ? theme.palette.primary.main : theme.palette.secondary.main,
									marginBottom: '5px',
								}}
							>
								{message.role === 'user' ? 'You' : (botName || 'STORYBUILT')}
								</Typography>
							<Box
								sx={{
									maxWidth: '90%',
									padding: '10px',
									borderRadius: '10px',
									backgroundColor: message.role === 'user' ? theme.palette.primary.main : theme.palette.secondary.main,
									color: 'white',
								}}
							>	
								<ReactMarkdown>{message.content}</ReactMarkdown>
							</Box>
						</Box>
					))}
					{streamingMessage && (
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								marginBottom: '20px',
							}}
						>
							<Typography
								sx={{
									fontSize: '12px',
									fontWeight: 'bold',
									color: theme.palette.secondary.main,
									marginBottom: '5px',
								}}
							>
								{botName || 'STORYBUILT'}
							</Typography>
							<Box
								sx={{
									maxWidth: '90%',
									padding: '10px',
									borderRadius: '10px',
									backgroundColor: theme.palette.secondary.main,
									color: 'white',
								}}
							>
								<ReactMarkdown>{streamingMessage}</ReactMarkdown>
							</Box>
						</Box>
					)}
					<div ref={messagesEndRef} /> 
					{currentChat !== null && <TextField
									margin='normal'
									fullWidth
									id='textField'
									name='textField'
									multiline
									sx={{
										width: {
											xs: '90%', 
											md: 'calc(75vw - 290px)'
										},
										position: 'fixed',
                                        bottom: '5px',
									}}
									minRows={1}
									maxRows={3}
									placeholder='Brainstorm with our AI about your project here!'
									value={fieldValue}
									disabled={!isGenerationButtonAllowed ? loading : false}
									onChange={(e) => setFieldValue(e.target.value)}
									InputLabelProps={{ shrink: true }}
									inputRef={textFieldRef}
									onKeyDown={(event) => {
										if (event.key === 'Enter' && !event.shiftKey) {
											event.preventDefault();
											handleSendMessage();
										}
									}}
									InputProps={{
										endAdornment: (
										<Tooltip title={`AI Tokens: ${chatFees}`} arrow>
										  <InputAdornment position="end">
											<IconButton onClick={handleSendMessage}>
											  <SendIcon />
											</IconButton>
										  </InputAdornment>
										  </Tooltip>
										),
									  }}
								/>}
					</Box>
				</Box>
			</Modal>
			<Modal
				open={characterModalOpen}
				onClose={() => {setCharacterModalOpen(false); setChatTitle(null); setSelectedCharacter(null)}}
				aria-labelledby="character-modal"
				aria-describedby="select-character-to-chat"
				sx={{ zIndex: 1000000 }}
			>
				<Box sx={style2}>
					<Typography variant="h6" component="h2">
					Select a Character To Chat With
					</Typography>
					<Select
					value={selectedCharacter} // Bind to selectedCharacter state
					onChange={(e) => {setSelectedCharacter(e.target.value); setChatTitle(e.target.value.name)}} // Update selectedCharacter state
					displayEmpty
					fullWidth
					sx={{ mt: 2}}
					MenuProps={{
						sx: {
						  zIndex: 16000000, // Ensure this is higher than the modal's zIndex
						},
						PaperProps: {
						  style: {
							zIndex: 16000000, // Apply zIndex to the Paper component
						  },
						},
					  }}
					>
					<MenuItem value="" disabled>
						Select the character here
					</MenuItem>
					{characters?.map((character, index) => (
						<MenuItem key={index} 
						value={character}
						sx={{
							'&:hover': {
							  backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change background color on hover
							  color: 'primary.main', // Optional: Change text color on hover
							},
						  }}
						>
						{character.name}
						</MenuItem>
					))}
					</Select>
					{selectedCharacter && (
						<TextField
							label="Title for Chat"
							value={chatTitle}
							onChange={(e) => setChatTitle(e.target.value)}
							fullWidth
							sx={{ mt: 2 }}
						/>
					)}
					<Button
					variant='contained'
					onClick={handleAddCharacterChat} // Call handleAddCharacterChat on click
					sx={{ mt: 2 }}
					disabled={!selectedCharacter}
					>
					Add Character Chat
					</Button>
				</Box>
			</Modal>
			<Dialog
				open={editTitleOpen}
				onClose={() => setEditTitleOpen(false)}
				aria-labelledby="edit-title-dialog"
				sx={{ zIndex: 1000000 }}
			>
				<DialogTitle>Edit Chat Title</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						label="New Title"
						type="text"
						fullWidth
						value={newTitle}
						onChange={handleTitleChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={() => setEditTitleOpen(false)}>Cancel</Button>
					<Button variant='contained' onClick={handleTitleSubmit}>Save</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				aria-labelledby="delete-confirmation-dialog"
				sx={{ zIndex: 1000000 }}
			>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this chat?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
					<Button 
						variant='contained'
						onClick={() => {
							deleteChat(chatToDelete);
							setConfirmDialogOpen(false);
						}} 
						color="error"
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
				sx={{ zIndex: 1000000 }}
			>
				<MenuItem variant='contained' onClick={handleEditTitle}>Edit Title</MenuItem>
				<MenuItem variant='contained' onClick={handleDeleteChat}>Delete</MenuItem>
			</Menu>
		</>
	);
}
