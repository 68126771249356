import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  TextField,
  Box,
  Container,
  Typography,
  CssBaseline,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import * as constants from '../../helpers/constants';
import logoLong from '../../assets/images/logo-long.png';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { token } = useParams(); // Get token from URL
  const alert = useAlert();
  const navigate = useNavigate();

  console.log('token', token);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password length
    if (newPassword.length < 6) {
      alert.show('Password must be at least 6 characters long.', { type: 'error' });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      alert.show('Passwords do not match.', { type: 'error' });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${constants.BASE_URL}api/auth/reset-password`, { token, newPassword });

      // Check if the response status is 200, indicating success
      if (response.status === 200) {
        alert.show('Password reset successful. You can now log in.', { type: 'success' });
        navigate('/login');
      }
    } catch (error) {
      // Check if the error response contains the specific message
      if (error.response && error.response.status === 400 && error.response.data === 'Invalid or expired token') {
        alert.show('Invalid or expired token. Please request a new password reset.', { type: 'error' });
      } else {
        alert.show('Error resetting password. Please try again later.', { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <>
      {/* Navigation Bar */}
      <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
        <Toolbar>
          {/* Branding/Logo */}
          <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box component="img" src={logoLong} alt='' sx={{ height: { xs: '45px', md: '80px' } }} />
            </Link>
          </Typography>

          {/* Navigation Links */}
          <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
            <Button
              variant="contained"
              component={Link}
              to="/register"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 40px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Join
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 24px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
            Your new password must be at least 6 characters long.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="new password"
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmNewPassword"
              label="Confirm New Password"
              type={showConfirmNewPassword ? 'text' : 'password'}
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: '#fff' }}
              disabled={loading} // Disable button while loading
            >
              {loading ? <CircularProgress size={25} /> : 'Reset Password'}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
