import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Button, Container, CssBaseline, TextField, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import * as constants from '../../helpers/constants';
import logoLong from '../../assets/images/logo-long.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!constants.EMAIL_REGEXP.test(email)) {
      setIsEmailValid(false);
      alert.show('Email is not valid!', { type: 'error' });
      return;
    }
    setLoading(true);
    try {
      await axios.post(`${constants.BASE_URL}api/auth/forgot-password`, { 
        email,
        resetURL: window.location.origin
      });
      alert.show('Password reset link sent!', { type: 'success' });
    } catch (error) {
      alert.show('Error sending password reset link', { type: 'error' });
    } finally {
      setLoading(false); // Set loading to false when the request completes
    }
  };

  return (
    <>
    {/* Navigation Bar */}
    <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
    <Toolbar>
        {/* Branding/Logo */}
        <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box component="img" src={logoLong} alt='' sx={{height: { xs: '45px', md: '80px' } }} />
            </Link>
        </Typography>

        {/* Navigation Links */}
        <Box sx={{ display: 'flex', gap: {xs:1,md:2} }}>
            {/* <Button variant="text" 
                component={Link} 
                to="/research" 
                color="secondary" 
                sx={{ 
                    mt: 2, 
                    mb: 2, 
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    fontWeight: 'normal',
                    padding: { xs: '8px 16px', md: '12px 40px' },
                    height: { xs: '45px', md: '55px' } }}>
                AI Research
            </Button> */}
            <Button variant="contained" 
                component={Link} 
                to="/register" 
                color="secondary" 
                sx={{ 
                    mt: 2, 
                    mb: 2, 
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    padding: { xs: '8px 16px', md: '12px 40px' },
                    height: { xs: '45px', md: '55px' } }}>
                Join
            </Button>
            <Button variant="contained" 
                component={Link} 
                to="/login" 
                color="secondary" 
                sx={{ 
                    mt: 2, 
                    mb: 2, 
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    padding: { xs: '8px 16px', md: '12px 24px' },
                    height: { xs: '45px', md: '55px' } }}>
                Sign In
            </Button>
        </Box>
    </Toolbar>
    </AppBar>
  
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 300,
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())} // Convert email to lowercase
            error={!isEmailValid}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff', minWidth: 300 }}
            disabled={loading} // Disable button while loading
          >
            {loading ? <CircularProgress size={25} /> : 'Send Reset Link'}
          </Button>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default ForgotPassword;
