import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { useSelector } from 'react-redux';

const UpdateModal = ({ open, onClose }) => {
  const [versionInfo, setVersionInfo] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchVersionInfo = async () => {
      try {
        const response = await axios.get(`${constants.BASE_URL}api/auth/last-seen-version`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVersionInfo(response.data);
      } catch (error) {
        console.error('Error fetching version info:', error);
      }
    };

    if (open) {
      fetchVersionInfo();
    }
  }, [open, token]);

  const handleUpdateSeen = async () => {
    try {
      await axios.post(
        `${constants.BASE_URL}api/auth/update-last-seen-version`,
        { version: versionInfo.latestVersion },  // Send the latest version to update
        { headers: { Authorization: `Bearer ${token}` } }
      );
      onClose();
    } catch (error) {
      console.error('Error updating last seen version:', error);
    }
  };

  if (!versionInfo) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Update Released!</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Version {versionInfo.latestVersion} is now live.
        </Typography>
        <Typography variant="body1" gutterBottom>
          What's new:
        </Typography>
        <List>
          {versionInfo.updateNotes.map((note, index) => (
            <ListItem key={index}>
              <ListItemText primary={note} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            fullWidth={isMobile}
            //sx={{ order: isMobile ? 2 : 1 }}
          >
            Remind me later
          </Button>
          <Button
            component={Link}
            to="/whats-new"
            variant="contained"
            color="primary"
            onClick={handleUpdateSeen}
            fullWidth={isMobile}
           //sx={{ order: isMobile ? 3 : 2 }}
          >
            See all updates
          </Button>
          <Button
            onClick={handleUpdateSeen}
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            //sx={{ order: isMobile ? 1 : 3 }}
          >
            Okay!
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateModal;