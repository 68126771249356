import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  Button,
  AppBar,
  Toolbar,
  Chip,
  Stack,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import logoLong from '../../../assets/images/logo-long.png';
import blogPosts from '../BlogPosts';
import ReactMarkdown from 'react-markdown';

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline-block',
}));

const MarkdownContent = styled('div')(({ theme }) => ({
  '& h1': {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: theme.palette.text.primary,
  },
  '& h2': {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    marginBottom: '1rem',
    color: theme.palette.text.primary,
  },
  '& h3': {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    color: theme.palette.text.primary,
  },
  '& p': {
    marginBottom: '1rem',
    color: theme.palette.text.secondary,
  },
  '& ul, & ol': {
    marginBottom: '1rem',
    paddingLeft: '2rem',
    color: theme.palette.text.secondary,
  },
  '& li': {
    marginBottom: '0.5rem',
  },
  '& blockquote': {
    borderLeft: '4px solid #ccc',
    paddingLeft: '1rem',
    fontStyle: 'italic',
    marginBottom: '1rem',
    color: theme.palette.text.secondary,
  },
  '& code': {
    padding: '0.2rem 0.4rem',
    borderRadius: '4px',
    fontFamily: 'monospace',
  },
  '& pre': {
    padding: '1rem',
    borderRadius: '4px',
    overflowX: 'auto',
    marginBottom: '1rem',
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '& hr': {
    border: 'none',
    borderTop: '1px solid #ccc',
    margin: '2rem 0',
  },
}));

const BlogPostTemplate = () => {
  const { url } = useParams();
  const post = blogPosts.find((post) => post.url === url);
  console.log('Post Content Type:', typeof post.content);
console.log('Post Content:', post.content);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!post) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Post Not Found
        </Typography>
        <Button variant="contained" component={Link} to="/blog" color="primary">
          Back to Blog
        </Button>
      </Container>
    );
  }

  // Optional: Customize specific Markdown elements if needed
  const components = {
    // Example: Customize links to use react-router's Link for internal links
    a: ({ node, ...props }) => {
      const isInternal = /^\/(?!\/)/.test(props.href);
      return isInternal ? (
        <Link to={props.href} {...props} style={{ color: '#00FFFF' }}>
          {props.children}
        </Link>
      ) : (
        <a href={props.href} target="_blank" rel="noopener noreferrer" style={{ color: '#00FFFF' }}>
          {props.children}
        </a>
      );
    },
    // Example: Customize images for better responsiveness or adding alt text
    img: ({ node, ...props }) => (
      <Box
        component="img"
        src={props.src}
        alt={props.alt || 'Blog Post Image'}
        sx={{ maxWidth: '100%', height: 'auto' }}
      />
    ),
    // Add more custom renderers if needed
  };

  return (
    <>
      {/* SEO and Metadata */}
      <Helmet>
        <title>{`${post.title} | Storybuilt.AI Blog`}</title>
        <meta name="description" content={post.excerpt} />
        <link rel="canonical" href={`https://storybuilt.ai/blog/${post.url}`} />
        <meta property="og:title" content={`${post.title} | Storybuilt.AI Blog`} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:url" content={`https://storybuilt.ai/blog/${post.url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={post.image} />
      </Helmet>

      {/* Navigation Bar */}
      <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, mt: 1 }}>
            <Link
              to="/"
              aria-label="Storybuilt.AI Home"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box
                component="img"
                src={logoLong}
                alt="Storybuilt.AI Logo"
                sx={{ height: { xs: '45px', md: '80px' } }}
              />
            </Link>
          </Typography>
          <Button variant="contained" component={Link} to="/blog" color="secondary">
            Back to Blog
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <main>
        <Container maxWidth="md" sx={{ mb: 10 }}> {/* Add margin bottom here */}
          <article>
            {/* Header Section */}
            <Box component="header" sx={{ my: 8, textAlign: 'center' }}>
              <GradientTypography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ fontSize: '2.5rem' }}
              >
                {post.title}
              </GradientTypography>
              <Typography variant="subtitle1" color="text.secondary">
                By {post.author} |{' '}
                <time dateTime={post.date}>
                  {new Date(post.date).toLocaleDateString()}
                </time>
              </Typography>
            </Box>

            {/* Featured Image */}
            <Box
              component="img"
              src={post.image}
              alt={post.title}
              sx={{ width: '100%', height: 'auto', mb: 4 }}
            />
            
            {/* Markdown Content */}
            <MarkdownContent>
              <ReactMarkdown components={components}>
                {post.content}
              </ReactMarkdown>
            </MarkdownContent>
          </article>
            {/* Add category tags */}
    <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                {Array.isArray(post.category) ? (
                  post.category.map((cat, index) => (
                    <Chip key={index} label={cat} color="primary" size="small" />
                  ))
                ) : (
                  <Chip label={post.category} color="primary" size="small" />
                )}
              </Stack>
        </Container>
      </main>
  
      {/* Structured Data for SEO */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: post.title,
            datePublished: post.date,
            dateModified: post.date,
            author: {
              '@type': 'Person',
              name: post.author,
            },
            image: post.image,
            publisher: {
              '@type': 'Organization',
              name: 'Storybuilt.AI',
              logo: {
                '@type': 'ImageObject',
                url: 'https://storybuilt.ai/logo.png',
              },
            },
            url: `https://storybuilt.ai/blog/${post.url}`,
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': `https://storybuilt.ai/blog/${post.url}`,
            },
            description: post.excerpt,
          })}
        </script>
      </Helmet>
    </>
  );
};

export default BlogPostTemplate;
