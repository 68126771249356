import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Link,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Icons for showing/hiding password
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useNavigate } from 'react-router-dom';
import * as constants from '../../helpers/constants';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo.png';
import CardMedia from '@mui/material/CardMedia';
import { setTokens } from '../../store/slices/authSlice';
import { Link as RouterLink } from 'react-router-dom';
import logoLong from '../../assets/images/logo-long.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Storybuilt.AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [checked, setChecked] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const loading = useSelector((state) => state.loading.loading);

  // State for password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Your existing useEffect logic here
  }, [dispatch, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
      setIsEmailValid(false);
      alert.show('Email is not valid!', { type: 'error' });
      return;
    } else if (data.get('password').length < 6) {
      setIsPasswordValid(false);
      alert.show('Password should be longer!', { type: 'error' });
      return;
    } else {
      dispatch(addLoadingFlag('login'));
      try {
        const response = await axios.post(constants.BASE_URL + 'api/auth/login', {
          email: data.get('email'),
          password: data.get('password'),
        });

        const payload = {
          user: data.get('email'),
          token: response.data.token,
          userId: response.data.userId,
          role: response.data.role,
          generationTokens: 0,
          hideTokensFillModal: !!response.data?.hideTokensFillModal,
          tutorialFlag: response.data.tutorialFlag,
        };

        dispatch(setUser(payload));

        localStorage.setItem('email', data.get('email'));
        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('tutorialFlag', response.data.tutorialFlag);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('hideTokensFillModal', !!response.data?.hideTokensFillModal);

        if (checked) {
          // Store in local storage if "Remember Me" is checked
          localStorage.setItem('email', data.get('email'));
          localStorage.setItem('access_token', response.data.token);
          localStorage.setItem('userId', response.data.userId);
          localStorage.setItem('tutorialFlag', response.data.tutorialFlag);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('hideTokensFillModal', !!response.data?.hideTokensFillModal);
        }

        navigate('/products');
        setIsEmailValid(true);
        setIsPasswordValid(true);
        getUser(response.data.token);
      } catch (error) {
        setIsEmailValid(false);
        setIsPasswordValid(false);
        alert.show('Wrong credentials, or user not found', { type: 'error' });
      } finally {
        dispatch(removeLoadingFlag('login'));
      }
    }
  };

  const getUser = (token) => {
    axios
      .get(constants.BASE_URL + 'api/auth/get-user-info', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(setTokens(response.data?.tokens));
      })
      .catch(function (error) {
        // Handle error if necessary
      });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* Navigation Bar */}
      <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
        <Toolbar>
          {/* Branding/Logo */}
          <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box component="img" src={logoLong} alt='' sx={{ height: { xs: '45px', md: '80px' } }} />
            </RouterLink>
          </Typography>

          {/* Navigation Links */}
          <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
            <Button
              variant="contained"
              component={Link}
              to="/register"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 40px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Join
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 24px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          backgroundImage: {
            xs: 'none',
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '100vh',
          marginTop: '0px',
          paddingTop: '30px',
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            background: `${constants.DARK_GREY}`,
            padding: '24px',
            borderRadius: '15px',
            marginTop: {
              md: `0px`,
              xs: '0',
            },
            marginLeft: {
              md: `100px`,
              xs: 'auto',
            },
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <RouterLink to="/home">
              <CardMedia
                component="img"
                sx={{
                  height: 160,
                  width: 'auto',
                  cursor: 'pointer',
                }}
                image={logo}
                alt="#"
              />
            </RouterLink>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!isEmailValid}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={!isPasswordValid}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: 'white' }} // Set the icon color to white
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="register" variant="body2">
                    {"Sign up for an account here!"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 4 }} />
        </Container>
      </Box>
    </>
  );
};

export default Login;
