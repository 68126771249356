import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
    // CardActionArea,
    CardActions,
    Typography } from '@mui/material';


const GalleryProjectCardAddNew = ({handleClick,image,title}) => {
    return (
        <Card
        sx={{p: 1, maxWidth: '316px', cursor: 'pointer',
        }}
        onClick={(e) => {
            handleClick()
        }}
        >

        <CardMedia
            component="img"
            sx={{
                height: {
                    xs: 330,
                    sm: 400,
                },
                width: {
                    xs: 220,
                    sm: 300,
                },
            }}
            image={image}
            alt="#"
        />
        <CardActions>
            <Typography 
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                    textAlign: 'center',
                    width: '100%',
                    margin: '0'
                }}
            >
                {title}
            </Typography>
        </CardActions>
    </Card>
    )
}

export default GalleryProjectCardAddNew;