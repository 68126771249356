import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { addLoadingFlag, removeLoadingFlag } from '../../../store/slices/loadingSlice';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import Checkbox from '@mui/material/Checkbox';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function EnterpriseRegister({
	open,
	setOpen,
	onModalClose,
	getUsersFunc
}) {
	const stateLoading = useSelector((state) => state.loading.loading);
	const token = useSelector((state) => state.auth.token);
	const email = sessionStorage.getItem('email') || localStorage.getItem('email');
	const dispatch = useDispatch();
	const alert = useAlert();
	const [isEmailValid, setIsEmailValid] = useState(true)
	const [isPasswordValid, setIsPasswordValid] = useState(true)
	const [checked, setChecked] = useState(true);

	useEffect(() => {
		setIsEmailValid(true)
		setIsPasswordValid(true)
	},[open])

	const handleClose = () => {
		setOpen(false);
	  };

	const handleChange = (event) => {
	  setChecked(event.target.checked);
	};

	// console.log(email)
	const handleSubmit = async (e) => {
		if (stateLoading) {
		  return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
		  setIsEmailValid(false);
		  alert.show('Email is not valid.',{type: 'error'})
		  return
		} else if (data.get('password').length < 6) {
		  setIsPasswordValid(false);
		  alert.show('Password should be 6 characters or more.',{type: 'error'})
		  return
		} else {
		console.log('data email:', data.get('email'));
		console.log('data enterpriseAdminEmail:', email);
		dispatch(addLoadingFlag('register-enterprise-user'));
		alert.show('New Account is being built. Please wait one minute.',{type: 'success'})
		  await axios.post(constants.BASE_URL + 'api/auth/register-enterprise-user', {
			name: data.get('name'),
			email: data.get('email'),
			password: data.get('password'),
		  },{
			headers: {
			  'Authorization': `Bearer ${token}`
			}})
		  .then(function (response) {
			const payload = {
			  user: data.get('email'),
			  token: response.data.token
			};
			setIsEmailValid(true);
			setIsPasswordValid(true);
			getUsersFunc();
			handleClose();
			if (onModalClose) onModalClose();
			alert.show('Account was successfully created',{type: 'success'})
		  })
		  .catch(function (error) {
			setIsEmailValid(false);
			setIsPasswordValid(false);
			alert.show('Wrong credentials, or user already exists',{type: 'error'})
		  });
		}
		dispatch(removeLoadingFlag('register-enterprise-user'));
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					 <Typography component="h1" variant="h5">
              Create New Team Member
            </Typography>
			<TextField
                margin="normal"
                //required
                fullWidth
                id="name"
                label="User Name"
                name="name"
                //autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                //autoFocus
                error={!isEmailValid}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                //type="password"
                id="password"
                autoComplete="current-password"
                error={!isPasswordValid}
              />
			  	<Typography>This will add a user managed by your admin account. Upon clicking create, an additional seat will be added to your account and charged monthly. Additional tokens will also be added to your balance. Thanks! </Typography>
				<Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Create
              </Button>
			</Box>
			</Modal>
		</div>
	);
}
