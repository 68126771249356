import React, { useState, useEffect } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Divider, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as constants from '../../helpers/constants';

const WhatsNew = () => {
  const [versions, setVersions] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      const response = await axios.get(`${constants.BASE_URL}api/auth/version-history`);
      setVersions(response.data);
    } catch (error) {
      console.error('Error fetching versions:', error);
      setError('Failed to fetch version history. Please try again later.');
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="md" sx={{marginTop: 6}}>
      <Box sx={{ alignItems: 'center', mb: 0 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          variant="contained"
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        
        <Typography variant="h4" marginTop={6}>What's New</Typography>
        
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <List>
        {versions.map((version, index) => (
          <React.Fragment key={version._id}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={`Version ${version.version}`}
                secondary={
                  <Box component="span">
                    {version.updateNotes.map((note, noteIndex) => (
                      <Typography
                        key={noteIndex}
                        component="span"
                        variant="body2"
                        color="text.primary"
                        display="block"
                      >
                        • {note}
                      </Typography>
                    ))}
                  </Box>
                }
              />
            </ListItem>
            {index < versions.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default WhatsNew;