import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeCharacter from '../modals/ChangeCharacter';
import DeleteCharacter from '../modals/DeleteCharacter';
import { Draggable } from 'react-beautiful-dnd';
import * as constants from '../../helpers/constants';
import { Box } from '@mui/material';

export default function CharacterCards({
	character,
	characterId,
	episodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
	const [deleteCharacterOpen, setDeleteCharacterOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag((current) => !current);
	}, [character]);

	let draggableId;
	if (episodeIndex) {
		draggableId = episodeIndex * 10 + character?.id;
	} else {
		draggableId = character?.id;
	}
	return (
		<Draggable
			draggableId={'dragable-' + index}
			index={index}
			key={draggableId}
		>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, maxWidth: {xs:'200px', md:'170px'} }}
				>
					<div style={{ position: 'relative' }}>
						{cardChangeModalFlag && (
							<ChangeCharacter
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								character={character}
								characterId={characterId}
								episodeIndex={episodeIndex}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangeCharacter
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								character={character}
								characterId={characterId}
								episodeIndex={episodeIndex}
							/>
						)}
						<DeleteCharacter
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteCharacterOpen}
							setOpen={setDeleteCharacterOpen}
							character={character}
							characterId={characterId}
							episodeIndex={episodeIndex}
						/>

						{/* <CardActionArea> */}
						{(character?.image !== 'default') && (
						<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({
								position: 'relative',
								height: {xs:'200px', md:'170px'},
								width: {xs:'200px', md:'170px'},
								background: `linear-gradient(to bottom left,  ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								zIndex: 2,
								}}
								image={constants.IMAGE_URL + character?.image + '?time=' + character?.lastImageUpdate}
								alt={character?.alt}
								onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
							/>
							<Typography
								sx={{
									position: 'absolute',
									top: '30%',
									left: '0',
									right: '0',
									textAlign: 'center',
									color: 'black',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
								 {character?.name ? 
									(character.name.split(/\s/).slice(0, 3).map(word => word[0]).join('')) 
									: ''}
							</Typography>
						</Box>
							
						)}
						{(character?.image == 'default') && (
							<Box
							onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
								sx={{
								position: 'relative',
							}}>
								<CardMedia
									component='div'
									sx={(theme) => ({
										height: {xs:'200px', md:'170px'},
										width: {xs:'200px', md:'170px'},
										background: `linear-gradient(to bottom left,  ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									})}
									// image={placeholder}
									alt={character?.alt}
									onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
									// onError={(e) => {
									// 	e.target.src = placeholder;
									// }}
								/>	
								<Typography
									sx={{
										position: 'absolute',
										top: '30%',
										//left: '35%',
										left: '0',
										right: '0',
										textAlign: 'center',
										color: 'black',
										fontSize: '40px',
										textTransform: 'uppercase',
										color: 'white'
									}}
								>
										 {character?.name ? 
									(character.name.split(/\s/).slice(0, 3).map(word => word[0]).join('')) 
									: ''}
								</Typography>
							</Box>
						)}

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								onClick={() => setChangeCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Change Photo
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => setDeleteCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Delete
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions sx={{minHeight: '37.34px'}}>
							<Typography
								//gutterBottom
								variant='h5'
								component='div'
								onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
								sx={{
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "2px",
									maxWidth: 'calc(100% - 18px)',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{character?.name}
							</Typography>
						</CardActions>
						{/* </CardActionArea> */}
						<Tooltip>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
