import React, { useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, Button, Container, AppBar, Toolbar, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import logoLong from '../../assets/images/logo-long.png';
// Import feature images
import noGhostwriterImage from '../../assets/images/home/ghostwriter.gif';
import noPublishingCompanyImage from '../../assets/images/home/videocourses2.gif';
import noVideoCoursesImage from '../../assets/images/home/cashmoney.gif';
import chloeMeatball from '../../assets/images/home/chloe-meatball.gif';
import imageGen from '../../assets/images/home/imagegen.gif';
import storyStructure from '../../assets/images/home/storystructure.gif';
import { Helmet } from 'react-helmet';
const GradientTypography = styled(Typography)(({ theme }) => ({
    background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const Publishing = () => {
    const nohype = [
        { 
            title: "No Ghostwriter Needed", 
            description: "Use our advanced AI to help write and edit your book, script, or novel. Our AI will take your thoughts and ideas, and help to turn them into a finished product that reflects your style and taste.",
            image: noGhostwriterImage
        },
        { 
            title: "No Video Courses to Buy", 
            description: "Don't get talked into paying for video courses you really don't need. Our AI and Story Creation process is designed to be easy to follow and understand, and the writing it helps you produce will be way better than anything else out there.",
            image: noVideoCoursesImage
        },
        { 
            title: 'No "Publishing" Companies', 
            description: "All these companies promising the world are just middlemen trying to squeeze cash out of you or to take a huge chunk of your earnings. We have compiled a list of resources to help you market your own book, script, or novel.",
            image: noPublishingCompanyImage
        },
    ];

    const features = [
        { 
            title: "Everything You Need", 
            description: "From an advanced chatbot that can help you brainstorm ideas or become one of your characters, to custom AI that can take just a sliver of an idea and turn it into a full summary and outline in seconds, to being able to create images for your cover art, Storybuilt has you covered.",
            image: imageGen
        },
        { 
            title: "Writing That Comes From You", 
            description: "Don't get talked into paying thousands to end up with a book that you aren't proud of. Our AI will help you turn your thoughts and ideas into a finished product that you actually care about and are interested in.",
            image: chloeMeatball
        },
        { 
            title: 'A New Way to Write', 
            description: "We have created a new way to write that is more like a conversation with your friends. We give you the structure, you give your ideas and ask questions, and the AI does the rest.",
            image: storyStructure
        },
    ];

    useEffect(() => {
        // Preload critical assets
        const preloadLinks = [
            { rel: 'preload', href: logoLong, as: 'image' },
            { rel: 'preload', href: noGhostwriterImage, as: 'image' },
            { rel: 'preload', href: noPublishingCompanyImage, as: 'image' },
            { rel: 'preload', href: noVideoCoursesImage, as: 'image' },
        ];

        preloadLinks.forEach(link => {
            const linkElement = document.createElement('link');
            linkElement.rel = link.rel;
            linkElement.href = link.href;
            linkElement.as = link.as;
            document.head.appendChild(linkElement);
        });
    }, []);

    return (
        <>
         {/* React Helmet for OG and SEO Meta Tags */}
         <Helmet>
                <title>Storybuilt.AI - Publish Your Story</title>
                <meta name="description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Unlock your potential, with AI." />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Storybuilt.AI - Publish Your Story" />
                <meta property="og:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Unlock your potential, with AI." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.storybuilt.ai/" />
                <meta property="og:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Storybuilt.AI - Unleash Your Creative Potential" />
                <meta name="twitter:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Unlock your potential, with AI." />
                <meta name="twitter:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Additional Meta Tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.storybuilt.ai/" />
                
                {/* Add preload hints for critical assets */}
                <link rel="preload" href={logoLong} as="image" />
                <link rel="preload" href={noGhostwriterImage} as="image" />
                <link rel="preload" href={noPublishingCompanyImage} as="image" />
                <link rel="preload" href={noVideoCoursesImage} as="image" />
            </Helmet>
            <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box component="img" src={logoLong} alt='' sx={{height: { xs: '45px', md: '80px' } }} />
                        </Link>
                    </Typography>
                    <Box sx={{ display: 'flex', gap: {xs:1,md:2} }}>
                        <Button variant="contained" 
                            component={Link} 
                            to="/register" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 40px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Join
                        </Button>
                        <Button variant="contained" 
                            component={Link} 
                            to="/login" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 24px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Sign In
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg">
                {/* Hero Section */}
                <Box sx={{ my: 8, textAlign: 'center' }}>
                    <GradientTypography variant="h2" component="h1" gutterBottom>
                        Where AI Meets Publishing
                    </GradientTypography>
                    <Typography variant="h5" sx={{ mb: 6 }}>
                        Fiction, Non-Fiction, Books, Movies, Podcasts, and More... <br></br><br></br>Turn your AI-assisted ideas into bestsellers! <br></br> <br></br> No video courses, no influencers, no hard sells, no "coaches".
                    </Typography>
                    <Button variant="contained" color="primary" size="large" component={Link} to="/register">
                        Start Your Creative Journey
                    </Button>
                </Box>

                {/* Sales Section */}
                <Box sx={{ my: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <GradientTypography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
                        You Don't Need A "Guru"
                    </GradientTypography>
                    <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: 'lg' }}>
                        {nohype.map((nohype, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <StyledCard>
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={nohype.image}
                                        alt={nohype.title}
                                        loading="lazy"
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" gutterBottom>
                                            {nohype.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {nohype.description}
                                        </Typography>
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Features Section */}
                <Box sx={{ my: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <GradientTypography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
                        Storybuilt.AI is Unique
                    </GradientTypography>
                    <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: 'lg' }}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <StyledCard>
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={feature.image}
                                        alt={feature.title}
                                        loading="lazy"
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" gutterBottom>
                                            {feature.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Call to Action */}
                <Box sx={{ my: 8, textAlign: 'center' }}>
                    <GradientTypography variant="h3" gutterBottom>
                        Ready to Join the AI Writing Revolution?
                    </GradientTypography>
                    <Typography variant="h5" sx={{ mb: 4 }}>
                       {/*  Join thousands of successful authors who have published with us */}
                    </Typography>
                    <Button variant="contained" color="primary" size="large" component={Link} to="/register">
                        Get Started Now
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default Publishing;