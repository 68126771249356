import React, { useState, useCallback } from 'react';
import { CircularProgress, Typography, Card, CardContent, Snackbar, Alert, styled, keyframes } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import * as constants from '../helpers/constants';

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  gap: '20px',
  marginTop: '20px',
  backgroundColor: constants.DARK_GREY,
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
});

// Define the pulsing animation
const pulse = keyframes`
  0% {
    color: ${theme => theme.palette.primary.main};
    transform: scale(1);
  }
  50% {
    color: white;
    transform: scale(1.1);
  }
  100% {
    color: ${theme => theme.palette.primary.main};
    transform: scale(1);
  }
`;

const FileUpload = ({ onSuccess, onError }) => {
  const [uploading, setUploading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      setSnackbar({ open: true, message: 'No file selected', severity: 'error' });
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('pdfFile', file);
    
    try {
      const response = await axios.post(constants.BASE_URL + 'api/analysis/pdf-analysis', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          // console.log('Upload Progress:', Math.round((progressEvent.loaded / progressEvent.total) * 100) + '%');
        },
      });
      onSuccess(response.data, file);
      setSnackbar({ open: true, message: 'File uploaded successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error uploading file:', error);
      onError('Error uploading file');
      setSnackbar({ open: true, message: 'Error uploading file', severity: 'error' });
    } finally {
      setUploading(false);
    }
  }, [onSuccess, onError]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/pdf' });

  return (
    <>
      <StyledCard variant="outlined">
        <StyledCardContent {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudUploadIcon 
            sx={{
              fontSize: 60,
              animation: `${pulse} 2s ease-in-out infinite`,
              color: 'primary.main'
            }}
          />
          <Typography variant="h6">
            {isDragActive ? "Drop the file here ..." : "-Upload a PDF for analysis here-"}
          </Typography>
          {uploading && <CircularProgress />}
        </StyledCardContent>
      </StyledCard>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FileUpload;
