import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HeadsetIcon from '@mui/icons-material/Headset';
import BookIcon from '@mui/icons-material/Book';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import BrushIcon from '@mui/icons-material/Brush';
import WebIcon from '@mui/icons-material/Web';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CreateIcon from '@mui/icons-material/Create';
import MovieIcon from '@mui/icons-material/Movie';
import StarIcon from '@mui/icons-material/Star';

const PublishingOption = ({ title, icon, description, link }) => {
  const theme = useTheme();
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {React.cloneElement(icon, { color: 'primary', fontSize: 'large' })}
          <Typography variant="h5" component="div" sx={{ ml: 1, fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
        <Typography 
          variant="body2" 
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: 1.6,
            fontSize: '0.95rem',
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <Button 
        variant="contained" 
        href={link} 
        target="_blank" 
        rel="noopener noreferrer"
        sx={{ 
          alignSelf: 'flex-start', 
          m: 2,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          }
        }}
      >
        Learn More
      </Button>
    </Card>
  );
};

const Publish = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ mt: '80px', mb: 4, pt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Publish Your Work
      </Typography>
      <Typography variant="h5" sx={{ mb: 4, color: theme.palette.text.secondary }}>
        Explore options to self-publish your script, book, audiobook, or comic on popular platforms
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Amazon KDP"
            icon={<ShoppingCartIcon fontSize="large" />}
            description="Publish your e-book or paperback on Amazon's Kindle Direct Publishing platform. Reach millions of readers worldwide."
            link="https://kdp.amazon.com"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Audible ACX"
            icon={<HeadsetIcon fontSize="large" />}
            description="Turn your book into an audiobook with Audible's Audiobook Creation Exchange. Connect with narrators and produce professional audiobooks."
            link="https://www.acx.com"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="IngramSpark"
            icon={<BookIcon fontSize="large" />}
            description="Publish and distribute your book globally. Offers both print-on-demand and e-book publishing services."
            link="https://www.ingramspark.com"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Spotify"
            icon={<MusicNoteIcon fontSize="large" />}
            description="Upload your audiobooks directly to Spotify's platform and reach their vast audience of listeners worldwide."
            link="https://podcasters.spotify.com/submit-audiobook"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Amazon KDP Comics"
            icon={<BrushIcon fontSize="large" />}
            description="Publish your comics and graphic novels on Amazon's Kindle Direct Publishing platform. Use Kindle Comic Creator for panel view and more."
            link="https://kdp.amazon.com/en_US/how-to-publish-comics-graphic-novels"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="WEBTOON Canvas"
            icon={<WebIcon fontSize="large" />}
            description="Publish your webcomic series on WEBTOON Canvas, the world's largest webcomic platform. Reach a global audience and potentially become an official WEBTOON series."
            link="https://www.webtoons.com/en/creators101/getstarted"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Morris Publishing"
            icon={<LocalPrintshopIcon fontSize="large" />}
            description="Self-publish your comic book with Morris Publishing. They offer various formats including traditional comic books, graphic novels, and manga, with options for saddle-stitched or perfect binding."
            link="https://www.morrispublishing.com/comic-book-publishing/"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Blurb"
            icon={<PhotoLibraryIcon fontSize="large" />}
            description="Self-publish photo books, trade books, magazines, and more with Blurb. They offer a range of formats and distribution options, including selling through their bookstore or on Amazon."
            link="https://www.blurb.com/self-publish"
          />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Script2Comic"
            icon={<EmojiEventsIcon fontSize="large" />}
            description="Enter your script in the Script2Comic contest for a chance to have it made into a real comic book, published by top indie comic labels, and potentially optioned by leading Hollywood production companies."
            link="https://www.script2comic.com/"
          />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Negative Space Comics"
            icon={<CreateIcon fontSize="large" />}
            description="Enter comic book competitions hosted by Negative Space Comics. Get a chance to be published in their digital anthology and receive mentorship from industry professionals."
            link="https://negativespacecomics.com/"
          />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="The Black List"
            icon={<MovieIcon fontSize="large" />}
            description="Submit your screenplay to The Black List, a platform that connects writers with film industry professionals. Get your script evaluated and potentially discovered by producers and studios."
            link="https://blcklst.com/"
          />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
          <PublishingOption
            title="Coverfly"
            icon={<StarIcon fontSize="large" />}
            description="Submit your screenplay to Coverfly, a platform that connects emerging writers with industry professionals. Participate in competitions, get coverage, and increase your chances of being discovered."
            link="https://coverfly.com/"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          The landscape of publishing has transformed dramatically, making it easier than ever to bring your creative works to life. Whether you're crafting novels, scripts, comics, or audiobooks, the platforms and opportunities showcased above offer a wealth of options for self-publishing and promotion.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: theme.palette.text.primary }}>
          From e-books on Amazon KDP to audiobooks on Audible ACX and Spotify, from webcomics on WEBTOON Canvas to screenplays on The Black List, there's a platform tailored to your specific medium. These services not only provide the tools for publication but also connect you with vast audiences eager for fresh content.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: theme.palette.text.primary }}>
          Moreover, platforms like the Blacklist andCoverfly offer unique opportunities to showcase your work, potentially leading to professional collaborations and industry recognition. The democratization of publishing means that your creativity is no longer constrained by traditional gatekeepers.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: theme.palette.text.primary }}>
          As you embark on your publishing journey, remember to thoroughly research each platform's guidelines, royalty structures, and distribution options. While the process is more accessible than ever, success still requires quality work and strategic promotion.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, color: theme.palette.text.primary }}>
          To further enhance your chances of success, consider engaging with writing communities, attending industry workshops, or collaborating with professional editors. These resources can provide valuable insights, help refine your work, and prepare you for the exciting world of self-publishing and promotion.
        </Typography>
      </Box>
    </Container>
  );
};

export default Publish;