import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	TextField,
	Typography,
	CardMedia,
	Modal,
	IconButton,
	Avatar,
	Menu,
	MenuItem,
	Button,
	FormControl,
	InputLabel,
	Select,
	useMediaQuery,
} from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { logout } from '../../../store/slices/authSlice';
import * as constants from '../../../helpers/constants';
import ModalLoader from '../../ModalLoader';
import { JellyfishSpinner } from 'react-spinners-kit';
import placeholder from '../../../assets/images/placeholder.png';
import pptxgen from 'pptxgenjs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BorderColor } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ScrollContainer = {
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
	overflowX: 'auto',
	overflowY: 'scroll', // Allow vertical scroll
	width: '100%',
  };


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper', 
	border: '2px solid black',  
	borderRadius: 2,
	boxShadow: 24,
	p: 2,
	display: 'flex',  
	flexDirection: 'column',
	alignItems: 'center',
	zIndex: 1000,
	outline: 'none',
  };

  const style2 = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper', 
	border: '2px solid black',  
	borderRadius: 2,
	boxShadow: 24,
	p: 2,
	display: 'flex',  
	flexDirection: 'column',
	alignItems: 'center',
	zIndex: 1000,
	outline: 'none',
	minWidth: {xs:'90vw', md:'50vw'},
  };

  const preventScroll = `
  body.prevent-scroll {
    overflow: hidden;
  }
`;

export default function EditStoryboardFolder({
	folder,
	setCardsToRenderFunc,
}) {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const viewport = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const alert = useAlert();
	const token = useSelector((state) => state.auth.token);
	const [folderItem,setFolderItem] = useState(folder?.folder)
	const [scaledImage, setScaledImage] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [linkText, setLinkText] = useState('')
	const [currentIndex, setCurrentIndex] = useState(null); 
	const [anchorEl, setAnchorEl] = useState(null);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const location = useLocation();
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const [isCopyMenuOpen, setIsCopyMenuOpen] = useState(false); 
	const [selectedFolder, setSelectedFolder] = useState(''); 
	const [availableFolders, setAvailableFolders] = useState([]); 
	const scrollContainerRef = useRef(null); 

	useEffect(() => {
		const handleWheel = (event) => {
		  if (scrollContainerRef.current && !viewport) { // Check if viewport is not 'xs'
			event.preventDefault();
			scrollContainerRef.current.scrollLeft += event.deltaY; // Scroll horizontally
			scrollContainerRef.current.scrollLeft += event.deltaX; // Scroll vertically
		  }
		};
	  
		const container = scrollContainerRef.current;
		if (container) {
		  container.addEventListener('wheel', handleWheel); // Add wheel event listener
		}
	  
		return () => {
		  if (container) {
			container.removeEventListener('wheel', handleWheel); // Clean up event listener
		  }
		};
	  }, [viewport]); // Depend on viewport size

	useEffect(() => {
		document.head.insertAdjacentHTML("beforeend", `<style>${preventScroll}</style>`);
		fetchAvailableFolders();
	}, []);

	const fetchAvailableFolders = async () => {
		
		try {
            const response = await axios.get(`${constants.BASE_URL}api/projects/get-folders/` + projectId(), {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAvailableFolders(response.data);
		
        } catch (error) {
            console.error('Failed to fetch folders', error);
            alert.show('Failed to fetch folders', { type: 'error' });
        } 
    };

	const handleCopyMenuOpen = () => {
		setIsCopyMenuOpen(true); // Open the copy menu
		fetchAvailableFolders();
		handleMenuClose();
	};
	
	const handleCopyMenuClose = () => {
		setIsCopyMenuOpen(false); // Close the copy menu
	};

	const handleFolderSelect = (event) => {
		setSelectedFolder(event.target.value); // Set the selected folder
	};
	
	const handleCopyToFolder = async () => {
		dispatch(addLoadingFlag('copy-image'));
		if (selectedFolder && currentIndex !== null) {
			const imageToCopy = folderItem.images[currentIndex];
			const imageID = imageToCopy[0]; // Assuming the ID is the first element in the array
			console.log('imageid', imageID)
			console.log('image to copy #1', imageToCopy)

        // Proceed to find the image file on the backend using the extracted ID
        try {
            const response = await axios.get(constants.BASE_URL + 'api/projects/get-illustration-by-id/' + imageID, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
			console.log('response', response)
            const newImageId = response.data.id;
			console.log('new image data', newImageId)
			imageToCopy[0] = newImageId;
			console.log('image to copy #2', imageToCopy)

            const copyData = {
                targetFolderId: selectedFolder,
                imageArray: imageToCopy,
            };
		
			/* const copyData = {
				//sourceFolderId: folderItem.id,
				targetFolderId: selectedFolder,
				imageArray: imageToCopy,
			}; */
	
			
				// Perform Axios POST request to copy the image to another folder
				await axios.post(constants.BASE_URL + 'api/projects/copy-image-to-folder', {
					copyData,
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				
				alert.show('Image copied successfully', { type: 'success' });
				
			} catch (error) {
				if (error.response && error.response.status === 400 && error.response.data.msg === 'Image already exists in the target folder') {
					alert.show('Image already exists in the target folder', { type: 'error' });
				} else {
					console.error('Error copying image:', error);
					alert.show('Error copying image', { type: 'error' });
				}
			} finally {
				handleCopyMenuClose();
				setCardsToRenderFunc();
				dispatch(removeLoadingFlag('copy-image'));
			}
		}
	};
	

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget); // Open the menu
	  };
	  
	const handleMenuClose = () => {
	setAnchorEl(null); // Close the menu
	};

	 // Function to handle opening the edit modal
	 const handleOpenEditModal = (index, text) => {
		setCurrentIndex(index);
		setLinkText(text);
		setIsOpen(true);
		handleMenuClose(); // Close the menu when opening the edit modal
		document.documentElement.style.overflow = 'hidden'; // Prevent background scrolling
	  };
	
	  // Function to handle saving the edited text
	  const handleSave = async () => {
		if (currentIndex !== null) {
		  const updatedImages = [...folderItem.images];
		  if (Array.isArray(updatedImages[currentIndex])) {
			updatedImages[currentIndex][2] = linkText; // Update the text of the specific card
		  }
	  
		  // Prepare the data to be sent to the server
		  const updatedData = {
			folderId: folderItem.id,
			imageArrayId: updatedImages[currentIndex][0],
			newText: linkText,
		  };
		  
		  try {
			// Perform Axios POST request to save the updated text to the database
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-image-in-folder/' +
						projectId(),
					{
			  updatedData,
					},
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			);
	  
			//alert.show('Text updated successfully', { type: 'success' });
			setCardsToRenderFunc();
		  } catch (error) {
			console.error('Error updating text:', error);
			alert.show('Error updating text', { type: 'error' });
		  } finally {
			// Close the edit/delete modal and allow background scrolling
			setIsOpen(false);
			document.documentElement.style.overflow = 'auto';
		  }
		}
	  };

		// Function to handle deleting the card
		const handleDelete = () => {
			handleMenuClose();
			setIsDeleteConfirmOpen(true); // Open the delete confirmation modal
			document.documentElement.style.overflow = 'hidden';
		  };
		  
		const confirmDelete = async () => {
			if (currentIndex !== null) {
			  // Prepare the data to be sent to the server
			  const deleteData = {
				folderId: folderItem.id, 
				imageArrayId: folderItem.images[currentIndex][0].toString(),
			  };
		  
			  try {
				// Perform Axios DELETE request to delete the image from the database
				await axios.delete(constants.BASE_URL + 'api/projects/delete-image-in-folder', {
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				  data: deleteData,
				});
		  
				// Update the folder item state locally
				const updatedImages = folderItem.images.filter((_, index) => index !== currentIndex); // Remove the specific card from the array

				setFolderItem({ ...folderItem, images: updatedImages });

				//alert.show('Image deleted successfully', { type: 'success' });
				} catch (error) {
				console.error('Error deleting image:', error);
				alert.show('Error deleting image', { type: 'error' });
				} finally {
				setIsDeleteConfirmOpen(false); // Close the delete confirmation modal
				document.documentElement.style.overflow = 'auto';
				}
			}
			};
		  
		  const cancelDelete = () => {
			setIsDeleteConfirmOpen(false); // Close the delete confirmation modal
			document.documentElement.style.overflow = 'auto';
		  };

		  // Close the scaled image modal and allow background scroll
		const handleOpenScaledImage = (index) => {
			setScaledImage(index);
			document.documentElement.style.overflow = 'hidden'; // Prevent background scrolling
		};
		
		const handleCloseScaledImage = () => {
			setScaledImage(null);
			document.documentElement.style.overflow = 'auto'; // Allow background scrolling
		};
		
		// Close the edit modal and allow background scroll
		const handleCloseEditModal = () => {
			setIsOpen(false);
			document.documentElement.style.overflow = 'auto'; // Allow background scrolling
		};

	/* const downloadPresentation = () => {
		if (folderItem?.images?.length > 0) {
			const pptx = new pptxgen();
			
			if (folderItem.images.every(item => typeof item === 'string')) {
				folderItem.images.forEach((imagePath, index) => {
					let slide = pptx.addSlide(index);
					slide.addImage({ path: constants.IMAGE_URL + imagePath, x: 0, y: 0, w: 6, h: 4 }); // Adjust width (w) and height (h)
				  });
			} else {
				folderItem.images.filter(item => Array.isArray(item)).forEach(([imagePath, description], index) => {
					let slide = pptx.addSlide(index);
					slide.addImage({ path: constants.IMAGE_URL + imagePath, x: 0, y: 0, w: 6, h: 4 }); // Adjust width (w) and height (h)
					slide.addText(description, { x: 6, y: 2, w: 4, h: 1, fontSize: 14, color: '000' }); // Adjust y position for text
				  });
			}
		  
			pptx.writeFile('presentation');
		  } else {
			alert.show(
				`Something went wrong!`,
				{ type: 'error' }
			);
		  }		  
		  
	  };
 */
	  const onDragEnd = async (result) => {
		const { destination, source } = result;
		if (!destination || destination.index === source.index) {
			return;
		}
	
		const newImages = Array.from(folderItem?.images);
		const [reorderedItem] = newImages.splice(source.index, 1);
		newImages.splice(destination.index, 0, reorderedItem);
	
		// Update the order in imageArray[3] for each image
		const updatedImages = newImages.map((image, index) => {
			if (Array.isArray(image)) {
				const newImage = [...image]; // Create a copy of the image array
				newImage[3] = index; // Set the order number at position 3
				console.log('newImage', newImage)
				return newImage;
			}
			return image; // Adjust as needed if image is not an array
		});
		console.log('updatedImages', updatedImages)
		setFolderItem((prevState) => ({
			...prevState,
			images: updatedImages,
		}));
	
		// Prepare the data to be sent to the server
		const reorderData = {
			newOrder: updatedImages
			/* newOrder: updatedImages.map((image) => ({
				imageArrayId: Array.isArray(image) ? image[0] : image, // Adjust this if image structure is different
				order: Array.isArray(image) ? image[3] : null, // Get the order from image[3] if it exists
			})), */
		};
		console.log('reorderData', reorderData);
		
		try {
			// Perform Axios POST request to save the new order to the database
			await axios.post(`${constants.BASE_URL}api/projects/reorder-images-in-folder/` + folderItem.id, 
			reorderData, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			//alert.show('Order updated successfully', { type: 'success' });
		} catch (error) {
			console.error('Error updating order:', error);
			alert.show('Error updating order', { type: 'error' });
		}
	};
	
	
	return (
		<>
			<Modal
        open={isOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box sx={style2}>
          <TextField
            label="Edit Text"
            value={linkText}
			multiline
			minRows={4}
			maxRows={12}
            onChange={(e) => setLinkText(e.target.value)} // Update the text as the user types
            fullWidth
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

	  <Modal
		open={isDeleteConfirmOpen}
		onClose={cancelDelete}
		aria-labelledby="delete-confirmation-title"
		aria-describedby="delete-confirmation-description"
		>
		<Box sx={style}>
			<Typography id="delete-confirmation-title" variant="h6" component="h2">
			Confirm Deletion
			</Typography>
			<Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
			Are you sure you want to delete this?
			</Typography>
			<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
			<Button variant="contained" color="primary" onClick={confirmDelete}>
				Yes
			</Button>
			<Button variant="contained" color="primary" onClick={cancelDelete}>
				No
			</Button>
			</Box>
		</Box>
		</Modal>

	  <Modal
        open={scaledImage !== null}
        onClose={handleCloseScaledImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
				<Box sx={style}>
					{scaledImage !== null && 
						<CardMedia
								component='img'
								sx={{
									height: {xs:'auto', md:'75vh'},
									width: {xs:'90vw', md:'auto'},
									cursor: 'pointer',
									alignItems: 'center'
								}}
								image={typeof folderItem?.images[scaledImage] === 'string' ? constants.IMAGE_URL + folderItem?.images[scaledImage] : constants.IMAGE_URL + folderItem?.images[scaledImage][0]}
								alt={'generated image'}
								onClick={() => {
									setScaledImage(null)
									setIsOpen(false)
								}}
								onError={(e) => {
									e.target.src = placeholder;
								}}
							/> 
						} 
						<Box sx={{
							maxHeight: '100px', // Set a fixed maximum height
							overflowY: 'auto',  // Enable vertical scrolling
							width: {xs:'90vw', md:'75vh'},      // Set the width to 100%
							p: 1,  
							pt: 0, 
							mt: 2,            // Add some padding inside the box
						}}>
							<Typography>
							{linkText}
							</Typography>
						</Box>
				</Box>
			</Modal>
			<Modal
				open={isCopyMenuOpen}
				onClose={handleCopyMenuClose}
				aria-labelledby="copy-modal-title"
				aria-describedby="copy-modal-description"
			>
				<Box sx={style2}>
					<FormControl fullWidth>
						<InputLabel id="folder-select-label">Select Folder</InputLabel>
						<Select
							labelId="folder-select-label"
							label="Select Folder"
							value={selectedFolder}
							onChange={handleFolderSelect}
						>
							{availableFolders.map((folder) => (
								<MenuItem key={folder.id} value={folder.id}>
									{folder.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
						<Button variant="contained" color="primary" onClick={handleCopyToFolder}>
							Copy
						</Button>
						<Button variant="contained" color="primary" onClick={handleCopyMenuClose}>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
			
			<Typography>{folderItem?.title}</Typography>
			<Box ref={scrollContainerRef} sx={ScrollContainer}>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable" direction={viewport ? 'vertical' : 'horizontal'}>
					{(provided) => (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						sx={{
						display: 'flex',
						flexDirection: {xs:'column', md:'row'},
						gap: '20px',
						paddingTop: '10px',
						justifyContent: 'start',
						}}
					>
						{folderItem?.images?.map((link, index) => (
						<Draggable key={index} draggableId={`item-${index}`} index={index}>
							{(provided) => (
							<Box
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								sx={{
								minWidth: '100px',
								minHeight: '100px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								backgroundColor: 'black',
								boxShadow: 3,
								padding: '10px',
								borderRadius: '5px',
								position: 'relative',
								}}
							>
								<CardMedia
								component="img"
								sx={{ width: {xs:'80vw', md:'375px'}, height: {xs:'auto', md:'auto'},}}
								image={Array.isArray(link) ? constants.IMAGE_URL + link[0] : constants.IMAGE_URL + link}
								alt="generated image"
								onClick={() => {
									handleOpenScaledImage(index); // Open the existing modal with the clicked card
									setLinkText(Array.isArray(link) ? link[2] : '');
								  }}
								onError={(e) => e.target.src = placeholder}
								/>
								{Array.isArray(link) && link[2] && (
								<Typography
									sx={{
									mt: 1,
									p: '0px',
									fontSize: '14px',
									width: {xs:'75vw', md:'340px'},
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									WebkitLineClamp: 3,
									WebkitBoxOrient: 'vertical',
									textAlign: 'center',
									cursor: 'pointer',
									}}
									onClick={() => {
										setScaledImage(index); // Open the existing modal with the clicked card
										setLinkText(link[2]);
									  }}
								>
									{link[2]}
									</Typography>
									)}
									<IconButton
										onClick={(event) => {
										setCurrentIndex(index); // Set the current index
										setLinkText(Array.isArray(link) ? link[2] : ''); // Set the link text
										handleMenuOpen(event); // Open the menu
										}}
										size="small"
										sx={{
										ml: 2,
										position: 'absolute',
										right: '0',
										bottom: '6px',
										}}
										aria-controls={anchorEl ? 'account-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={anchorEl ? 'true' : undefined}
									>
										<Avatar
										sx={{
											width: 24,
											height: 24,
											bgcolor: 'transparent',
										}}
										>
										<MoreVertIcon style={{ color: '#fff' }} />
										</Avatar>
									</IconButton>

									<Menu
										id="account-menu"
										anchorEl={anchorEl}
										open={Boolean(anchorEl)}
										onClose={handleMenuClose} // Close the menu
									>
										<MenuItem onClick={() => handleOpenEditModal(currentIndex, linkText)}>Edit</MenuItem>
										<MenuItem onClick={handleCopyMenuOpen}>Copy to Another Folder</MenuItem>
										<MenuItem onClick={handleDelete}>Delete</MenuItem>
									</Menu>
									</Box>
								)}
								</Draggable>
						))}
						{provided.placeholder}
					</Box>
					)}
				</Droppable>
				</DragDropContext>
				</Box>
				</>
			);
			}

			{/* <div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							position: 'fixed',
							bottom: '0',
							right: '0',
							width: '100%',
							paddingRight: '30px',
							backgroundColor: '#2f3944',
							zindex: '1',
						}}
					> */}
						{/* <Button
							fullWidth
							variant='contained'
							sx={{
								mt: 3,
								mb: 2,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '10px'
							}}
							onClick={() =>
								downloadPresentation()
							}
						>
								Export Presentation
						</Button> */}
					/* </div> */
		
