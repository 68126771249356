import axios from 'axios';
import { useRef, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {
	Box,
	FormControl,
	Grid,
	IconButton,
	Popover,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Tooltip,
	ListSubheader,
} from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { logout } from '../../../store/slices/authSlice';
import * as constants from '../../../helpers/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import deepObjectCompare from '../../../helpers/deepObjectCompare';
import AddSceneImages from '../../modals/AddSceneImages';
import DeleteScene from '../../modals/DeleteScene';
import Collapsible from '../../../utils/Collapsible';
import StopIcon from '@mui/icons-material/Stop';
import { setTokens } from '../../../store/slices/authSlice';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const style = {
	width: '100%',
	display: 'flex',
	'flex-direction': 'column',
	minHeight: {
		xs: '100vh',
		sm: 'auto'
	}
};

export default function EditStory({
	story,
	setCardsToRenderFunc,
	setting,
	characters,
	handleExportPdf,
	doesCardHaveUnsavedData,
	handleProjectHeaderVisibility,
	episodesArray,
	currentProject,
	setTokensFillModal,
}) {
	
	const [loading, setLoading] = useState(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const [storyName, setStoryName] = useState('');
	const token = useSelector((state) => state.auth.token);
	const navigate = useNavigate();
	const storyTypes = constants.STORYTYPES;
	const [title, setTitle] = useState(story?.title);
	const [type_of_story, setType_of_story] = useState(Array.isArray(story?.type_of_story) ? story.type_of_story : [story?.type_of_story ?? []]);
	const [other_things, setOther_things] = useState(story?.other_things);
	const [contextStories, setContextStories] = useState(story?.contextStories ? story?.contextStories : []);
	const [contextSummaries, setContextSummaries] = useState([]);
	const [summary, setSummary] = useState(story?.summary);
	const [isImageGenerationOpen,setIsImageGenerationOpen] = useState(false)
	const [summary_ai_adjust, setSummary_ai_adjust] = useState(story?.summary_ai_adjust);
	const [list_of_acts, setList_of_acts] = useState(story?.list_of_acts);
	const [list_of_acts_ai_adjust, setList_of_acts_ai_adjust] = useState(story?.list_of_acts_ai_adjust);
	const [acts, setActs] = useState(story?.acts);
	const [settingValue, setSettingValue] = useState(story?.setting ? story?.setting : []);
	const [charactersValue, setCharactersValue] = useState(story?.characters ? story?.characters :[]);
	const [settingSummaryValue, setSummarySettingValue] = useState(story?.summarySetting ? story?.summarySetting : []);
	const [charactersSummaryValue, setSummaryCharactersValue] = useState(story?.summaryCharacters ? story?.summaryCharacters :[]);
	const buttonsRef = useRef();
	const sceneFormatArray = ['Script','Story', 'Non-Fiction'];
	const [isDeleteSceneOpen, setIsDeleteSceneOpen] = useState(false)
	const [isDeleteActOpen, setIsDeleteActOpen] = useState(false)
	const deletionSceneIndexRef = useRef()
	const deletionActIndexRef = useRef()
	const deletionSceneNumberRef = useRef()
	const deletionActNumberRef = useRef()
	const currentSocket = useRef()
	const currentWebSocketObject = useRef();
	const currentGenerationSetFunc = useRef()
	const currentGenerationFieldValue = useRef('')
	const [currentGenerationFieldKey,setCurrentGenerationFieldKey] = useState()
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
	//const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);	
	const [stories, setStories] = useState([]);
	const location = useLocation();
	const projectId = location.pathname.slice(9, location.pathname.length);
	const [item, setItem] = useState(null);
	const [summaryTemp, setSummaryTemp] = useState(0.7);
	const [list_of_actsTemp, setList_of_actsTemp] = useState(0.7);
	const [list_of_scenesTemp, setList_of_scenesTemp] = useState(0.7);
	const [sceneBeatsTemp, setSceneBeatsTemp] = useState(0.7);
	const [scriptTemp, setScriptTemp] = useState(0.7);
	
	const preventScroll = `
		body.prevent-scroll {
			overflow: hidden;
		}
		`;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	useEffect(() => {
		const handleScroll = () => {
		  if (anchorEl) {
			setAnchorEl(null);
		  }
		};
	  
		window.addEventListener('scroll', handleScroll, true);
	  
		return () => {
		  window.removeEventListener('scroll', handleScroll, true);
		};
	  }, [anchorEl]);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
		

	const [storyFees, setStoryFees] = useState({
		summary: 0,
		list_of_acts: 0,
		list_of_scenes: 0,
		list_of_beats_for_the_scene: 0,
		scene_full_script: 0,
	  });
	  
	function checkIfCardHasUnsavedData () {
		if (!story) return

		const newCardData = {
			title,
		}

		if (other_things) newCardData.other_things = other_things;
		if (summary) newCardData.summary = summary;
		if (summary_ai_adjust) newCardData.summary_ai_adjust = summary_ai_adjust;
		if (list_of_acts) newCardData.list_of_acts = list_of_acts;
		if (list_of_acts_ai_adjust) newCardData.list_of_acts_ai_adjust = list_of_acts_ai_adjust;
		if ((story?.acts?.length > 0 && acts.length > 0) || story?.acts?.length !== acts?.length) newCardData.acts = acts;
		if (type_of_story !== story?.type_of_story) newCardData.type_of_story = type_of_story;
		if (contextStories !== story?.contextStories) newCardData.contextStories = contextStories;
		if (charactersValue.length > 0) newCardData.characters = charactersValue;
		if (settingValue.length > 0) newCardData.setting = settingValue;
		if (settingSummaryValue.length > 0) newCardData.summarySetting = settingSummaryValue;
		if (charactersSummaryValue.length > 0) newCardData.summaryCharacters = charactersSummaryValue;

		const isEqual = deepObjectCompare(newCardData,story)

		if (!isEqual) {
			doesCardHaveUnsavedData.current = true
		} else {
			doesCardHaveUnsavedData.current = false
		}
	};

	checkIfCardHasUnsavedData()

	useEffect(() => {
		document.head.insertAdjacentHTML("beforeend", `<style>${preventScroll}</style>`);
		document.documentElement.style.overflow = 'auto';
		setType_of_story(story?.type_of_story ?? []);
		setTitle(story?.title ?? '');
		setOther_things(story?.other_things ?? '');
		setContextStories(story?.contextStories ?? []);
		setContextSummaries(story?.contextSummaries ?? []);
		setSummary(story?.summary ?? '');
		setSummary_ai_adjust(story?.summary_ai_adjust ?? '');
		setList_of_acts(story?.list_of_acts ?? '');
		setList_of_acts_ai_adjust(story?.list_of_acts_ai_adjust ?? '');
		setActs(story?.acts ?? [
			{
				list_of_scenes: '',
				list_of_scenes_ai_adjust: '',
				actSetting: [],
				actCharacters: [],
				act_number: 0,
				scenes: [{ 
					scene_number: 0,
					list_of_beats_for_the_scene: '',
					list_of_beats_for_the_scene_ai_adjust: '',
					scene_full_script: '',
					scene_full_script_ai_adjust: '',
					characters: [],
					setting: [],
					scriptSceneFormat: 'Script',
				}]
			}
		]);
		setCharactersValue(story?.characters ?? []);
		setSettingValue(story?.setting ?? []);
		setSummaryCharactersValue(story?.summaryCharacters ?? []);
		setSummarySettingValue(story?.summarySetting ?? []);
		
	}, [story]);

	useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		};
	 }, []); 
	 
	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				// console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				// console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				// console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					// console.log('clientID set')
					currentSocket.current = data.clientId
					currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					currentGenerationSetFunc.current(currentGenerationFieldValue.current + data.content)
					currentGenerationFieldValue.current = currentGenerationFieldValue.current + data.content
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
					setLoading(false)
					currentGenerationSetFunc.current('')
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					/* alert.show(`Text was successfully generated`, {
						type: 'success',
					}); */
					setLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
					getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				// console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				setCurrentGenerationFieldKey('');
				// Handle WebSocket connection closure
			};

		});
	}

	useEffect(() => {
		// Add event listener to monitor the visibility of the search bar
		const handleResize = () => {
		  const searchBar = buttonsRef.current // Replace with the actual ID of your search bar
		  if (searchBar && searchBar?.offsetWidth > 0) {
			searchBar.style.bottom = 0
		  }
		};
	
		window.addEventListener('resize', handleResize);
		handleResize(); // Call it once to initialize the state
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	const handleFillFieldWithAI = async (fieldKey, adjust) => {
		if (!title || type_of_story.length === 0) {
			alert.show('Title and Type of Story are required fields.', { type: 'error' });
			return;
		  }
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}

		async function generationFunction () {
			currentGenerationFieldValue.current = ''
			setIsGenerationButtonAllowed(false)
			const allFields = {
				title,
				other_things,
				contextSummaries,
				summary,
				list_of_acts,
			};
			const allSetFieldFunctions = [
				setTitle,
				setOther_things,
				setContextSummaries,
				setSummary,
				setList_of_acts,
			];

			const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
				const character = characters?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character]
			},[])

			const settingToAI = (settignArr) => settignArr?.reduce((accumulator,setId) => {
				const settingItem = setting?.find(item => item?.settingItem?.id === setId)
				return accumulator = [...accumulator,settingItem]
			},[])

			const currentFieldSetFunctionIndex = Object.keys(allFields).findIndex(
				(key) => key === fieldKey
			);

			const objectWithoutCurrentField = Object.keys(allFields)
				.filter((key) => key !== fieldKey && allFields[key] && key !== 'title')
				.reduce((obj, key) => {
					return Object.assign(obj, {
						[key]: allFields[key],
					});
				}, {});

			setLoading(true);

			const charactersToServer = fieldKey === 'summary' ? charactersToAI(charactersSummaryValue) : charactersToAI(charactersValue)
			const settingToServer = fieldKey === 'summary' ? settingToAI(settingSummaryValue) : settingToAI(settingValue)
			const prevValue = fieldKey === 'summary' ? summary : list_of_acts

			const data = {
				summaryTemp,
				list_of_actsTemp,
				baseFields: {
					...objectWithoutCurrentField,
					type_of_story,
					contextSummaries,
				},
				fieldToFill: fieldKey,
				adjust: adjust,
				setting: settingToServer,
				characters: charactersToServer,
				projectTitle: currentProject?.title,
				projectDescription: currentProject?.quickstartSummary,
				projectRules: currentProject?.rules,
				clientId: currentSocket.current
				//projectRules: currentProject?.rules
			}

			if (prevValue?.length > 0) data.prevValue = prevValue
			currentGenerationSetFunc.current = (data) => allSetFieldFunctions[currentFieldSetFunctionIndex](data)
			setCurrentGenerationFieldKey(fieldKey);

			axios
				.post(
					constants.BASE_URL + 'api/ai/generate-story-field',
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(function (response) {
					// allSetFieldFunctions[currentFieldSetFunctionIndex](
					// 	response.data
					// );
					// setLoading(false);				
					// alert.show(
					// 	`Text was successfully generated`,
					// 	{ type: 'success' }
					// );
				})
				.catch(function (error) {
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'Enterprise User') {
						  alert.show(
							`Problem with the subscription. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`Problem with subscription. Please check the Account Info page.`,
							{ type: 'error' }
						  );
						}
									
					} else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation.`,
							{ type: 'error' }
						);
						// console.log(error);
					}
					setLoading(false);
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
				});
			}
	};

	function sendMessageToServer(message) {
		// console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			// console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

	function stopGeneration () {
		sendMessageToServer('stop generation')
	}

	function handleDragEnd(result) {
		if (!result.destination) return;
		setActs(prevActs => {
			const newActs = [...prevActs];
			const actIndex = Number.parseInt(result.destination.droppableId.slice(11));
			const [removed] = newActs[actIndex].scenes.splice(result.source.index, 1);
			newActs[actIndex].scenes.splice(result.destination.index, 0, removed);
			return newActs;
		});
		// console.log('acts',acts)
	}
	
	const handleFillActFieldWithAI = async (fieldKey, adjust, prevValue, charactersProps, settingProps, actNumber, actIndex) => {
		if (!title || type_of_story.length === 0) {
			alert.show('Title and Type of Story are required fields.', { type: 'error' });
			return;
		  }
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}

		async function generationFunction () {
			currentGenerationFieldValue.current = ''
			setIsGenerationButtonAllowed(false)
			const allFields = {
				summary,
				list_of_acts,
			};

			const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
				const character = characters?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character]
			},[])

			const settingToAI = (settignArr) => settignArr?.reduce((accumulator,setId) => {
				const settingItem = setting?.find(item => item?.settingItem?.id === setId)
				return accumulator = [...accumulator,settingItem]
			},[])

			setLoading(true);

			const charactersToServer =  charactersToAI(charactersProps)
			const settingToServer = settingToAI(settingProps) 

			const data = {
				list_of_scenesTemp,
				baseFields: {
					...allFields,
					type_of_story,
				},
				fieldToFill: fieldKey,
				adjust: adjust,
				setting: settingToServer,
				characters: charactersToServer,
				projectTitle: currentProject?.title,
				projectDescription: currentProject?.quickstartSummary,
				projectRules: currentProject?.rules,
				actIndex: actNumber,
				clientId: currentSocket.current
			}

			if (prevValue?.length > 0) data.prevValue = prevValue
			currentGenerationSetFunc.current = (data) => {
				const newActs  = acts.map(oldAct => { return {...oldAct}})
				if (newActs[actIndex]) {
					newActs[actIndex].list_of_scenes = data;
				}
				setActs(newActs)
			};
			setCurrentGenerationFieldKey(`act${actIndex} ${fieldKey}`);
			axios
				.post(
					constants.BASE_URL + 'api/ai/generate-act-field',
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(function (response) {
					
					// setLoading(false);				
					// alert.show(
					// 	`Text was successfully generated`,
					// 	{ type: 'success' }
					// );
				})
				.catch(function (error) {
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'Enterprise User') {
						  alert.show(
							`Problem with the subscription. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`Problem with subscription. Please check the Account Info page.`,
							{ type: 'error' }
						  );
						}
									
					} else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation.`,
							{ type: 'error' }
						);
						// console.log(error);
					}
					setLoading(false);
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
				});
			}
	};

	const deleteSceneFunc = () => {
		const newActs  = JSON.parse(JSON.stringify(acts))
		const newScenes  = newActs[deletionActIndexRef.current]?.scenes?.map(scene => { return {...scene}})
		newScenes.splice(deletionSceneIndexRef.current, 1)
		if (deletionActIndexRef?.current !== undefined && newActs[deletionActIndexRef.current]) {
			newActs[deletionActIndexRef.current].scenes = newScenes;
		}
		setActs(newActs)
		alert.show(
			`Scene was successfully deleted`,
			{ type: 'success' }
		);
	}

	const deleteActFunc = () => {
		const newScenes  = acts.map(scene => { return {...scene}})
		newScenes.splice(deletionActIndexRef.current, 1)
		setActs(newScenes)
		alert.show(
			`Act was successfully deleted`,
			{ type: 'success' }
		);
	}

	const handleFillSceneWithAI = async (field, fieldKey, adjust,charactersArray, settingArray, setFunc, list_of_beats_for_the_scene,sceneFormat,sceneIndex,list_of_scenes) => {
		if (!title || type_of_story.length === 0) {
			alert.show('Title and Type of Story are required fields.', { type: 'error' });
			return;
		  }
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}

		async function generationFunction () {
			currentGenerationFieldValue.current = ''
			setIsGenerationButtonAllowed(false)
			const allFields = {
				summary,
				list_of_acts,
				list_of_scenes,
				type_of_story,
				
			};

			if (list_of_beats_for_the_scene) {
				allFields.list_of_beats_for_the_scene = list_of_beats_for_the_scene
			}

			setLoading(true);

			const charactersToAI = charactersArray?.reduce((accumulator,charId) => {
				const character = characters?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character]
			},[])

			const settingToAI = settingArray?.reduce((accumulator,setId) => {
				const settingItem = setting?.find(item => item?.settingItem?.id === setId)
				return accumulator = [...accumulator,settingItem]
			},[])

			const data = {
				scriptTemp,
				sceneBeatsTemp,
				baseFields: allFields,
				fieldKey: fieldKey,
				adjust: adjust,
				setting: settingToAI,
				characters: charactersToAI,
				projectTitle: currentProject?.title,
				projectDescription: currentProject?.quickstartSummary,
				projectRules: currentProject?.rules,
				sceneIndex: sceneIndex,
				clientId: currentSocket.current
			}


			if (field?.length > 0) data.field = field
			if (sceneFormat) data.sceneFormat = sceneFormat
			currentGenerationSetFunc.current = (data) => setFunc(data);
			setCurrentGenerationFieldKey(`scene${sceneIndex} ${fieldKey}`);
			axios
				.post(
					constants.BASE_URL + 'api/ai/generate-scene-field',
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(function (response) {
					// setFunc(response.data)	
					// alert.show(
					// 	`Text was successfully generated`,
					// 	{ type: 'success' }
					// );
					// setLoading(false);
				})
				.catch(function (error) {
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'Enterprise User') {
						  alert.show(
							`Problem with the subscription. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`Problem with subscription. Please check the Account Info page.`,
							{ type: 'error' }
						  );
						}
									
					} else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation.`,
							{ type: 'error' }
						);
						// console.log(error);
					}
					setLoading(false);
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
				});
			}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (stateLoading) {
			return
		}

		if (title) {
			setStoryName(title);
			dispatch(addLoadingFlag('update-story'));
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-story-by-id/' +
						story.id,
					{
						title,
						other_things,
						contextStories,
						summary,
						summary_ai_adjust,
						list_of_acts,
						list_of_acts_ai_adjust,
						acts,
						type_of_story,
						characters: charactersValue,
						setting: settingValue,
						summarySetting: settingSummaryValue,
						summaryCharacters: charactersSummaryValue,
					}
					,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					alert.show(
						`'${
							storyName ? storyName : story?.title
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-story'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-story'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
					
					alert.show(
						`Something went wrong, could not add illustrations.`,
						{ type: 'error' }
					);
					
				});
		}
	};

	const handleChange = (event) => {
		const { value } = event.target;
		const selectedValues = Array.isArray(value) ? value.filter(v => v !== '') : [value].filter(v => v !== '');
		console.log('Selected values:', selectedValues); // Debugging step
		setType_of_story(selectedValues);
	};
	
	useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		constants.BASE_URL + 'api/fees/get-fees',
		{
			headers: {
			Authorization: `Bearer ${token}`,
			},
		}
		).then(function (response) {
		dispatch(removeLoadingFlag('get-fees'));
		if(response.data.hasOwnProperty('story')){
			setStoryFees(response.data.story)
		}
		}).catch(function (error) {
		if (error.response.data === 'Permission denied') {
			navigate('/#');
		}
		// console.log(error);
		dispatch(removeLoadingFlag('get-fees'));
		});
	},[alert,dispatch,token])

	useEffect(() => {
		const fetchStories = async () => {
			//setLoading(true); // Optional: manage loading state
			dispatch(addLoadingFlag()); // Start loading flag
			try {
				const response = await axios.get(`${constants.BASE_URL}api/projects/get-stories/${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				//console.log('stories API', response.data?.stories);
				// Filter out stories with null titles right after fetching
				const validStories = response.data?.stories.filter(stories => stories != null);
				setStories(validStories || []);
			} catch (error) {
				console.error('Error fetching stories:', error);
				alert.show('Failed to load stories!', { type: 'error' });
			} finally {
				//setLoading(false); // Optional: manage loading state
				dispatch(removeLoadingFlag()); // End loading flag
			}
		};
	
		if (projectId && token && item) {
			fetchStories();
		}
	}, [projectId, token, item, dispatch, alert]);

	useEffect(() => {
        if (stories.length > 0 && contextStories.length > 0) {
            const selectedSummaries = stories.filter(story =>
                contextStories.includes(story.id)
            ).map(story => story.summary);
			//currenly using list of acts from the selected stories. Switch to summary after testing.
            setContextSummaries(selectedSummaries);
        }
    }, [stories, contextStories]);

	const handleContextStoriesChange = (event) => {
		// Update the state with selected story IDs
		const selectedStoryIds = event.target.value;
		setContextStories(selectedStoryIds);
	
		// Filter the summaries based on the selected IDs
		const selectedSummaries = stories.filter(story => 
			selectedStoryIds.includes(story.id)
		).map(story => story.summary);
		setContextSummaries(selectedSummaries);
	};

	useEffect(() => {
        const query = new URLSearchParams(location.search);
        const itemValue = query.get('item');
        setItem(itemValue);
    }, [location.search]);
  
		//console.log('dropdown', projectId, ' context IDs', contextStories, 'context summaries', contextSummaries)
		
	return (
		<Box component='form' onSubmit={handleSubmit} noValidate sx={style}>
			<AddSceneImages
				projectId={projectId}
				acts={acts}
				summary={summary}
				//scenes={scenes}
				list_of_acts={list_of_acts}
				// episodesArray={episodesArray}
				open={isImageGenerationOpen}
				setOpen={setIsImageGenerationOpen}
				setting={setting}
				characters={characters}
				storyCharacters={charactersValue}
				storySetting={settingValue}
				setTokensFillModal={setTokensFillModal}
				/>
				<>
					<Box
						sx={{
							overflow: 'scroll',
							paddingRight: '8px',
						}}
					>
						<DeleteScene open={isDeleteSceneOpen} setOpen={setIsDeleteSceneOpen} sceneIndex={deletionSceneNumberRef.current} deleteFunc={()=> deleteSceneFunc()} text={'Scene'}/>
						<DeleteScene open={isDeleteActOpen} setOpen={setIsDeleteActOpen} sceneIndex={deletionActNumberRef.current} deleteFunc={()=> deleteActFunc()} text={'Act'}/>
						<Accordion sx={{ marginBottom: 2 }}>
						  <AccordionSummary
						    expandIcon={<ExpandMoreIcon />}
						    aria-controls="tips-and-tricks-content"
						    id="tips-and-tricks-header"
						  >
						    <Typography variant="h6">Tips & Tricks</Typography>
						  </AccordionSummary>
						  <AccordionDetails>
						    <Typography paragraph>
						      -Selecting the "Type of Story" you are creating is critical as it will dictate how the AI structures its responses.
						    </Typography>
						    <Typography paragraph>
						      -Even if you have used Quickstart to build your project, and the Summary field has already been filled out, after selecting the Type of Story, hit the generate button. It will build you a much better summary.
						    </Typography>
						    <Typography paragraph>
						      -You can make changes directly to the generated text, but we find it easier and more effective to give the AI some comments in the box below the generate button, and then click generate.
						    </Typography>
						    <Typography paragraph>
						      -You do not have to select characters and settings for every generated field, and if you are writing something non-fiction, you may not have explicit characters and settings anyways.
						    </Typography>
							<Typography paragraph>
						      -Copy and paste the text you are working on into the chatbot (the chat icon in the lower right corner) to work with the AI on it.
						    </Typography>
						  </AccordionDetails>
						</Accordion>
						<TextField
							sx={{
								width: '100%'
							}}
							margin='normal'
							required
							fullWidth
							//autoFocus
							name='title'
							placeholder=''
							label='Title'
							id='title'
							defaultValue={story?.title}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							InputLabelProps={{ shrink: true }}
							onFocus={()=>handleProjectHeaderVisibility(false)}
							onBlur={()=>handleProjectHeaderVisibility(true)}
							inputProps={{ maxLength: 200 }}

						/>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "4px" }}
							>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Type of Story* (You can select more than one)
							</InputLabel>
							<Select
								value={type_of_story}
								onChange={handleChange}
								required
								labelId='test-select-label'
								variant='outlined'
								label='Type of Story* (You can select more than one)'
								fullWidth
								multiple
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								<ListSubheader>Non-Fiction</ListSubheader>
								{storyTypes.nonFiction.map((item, index) => (
									<MenuItem key={`non-fiction-${index}`} value={item}>{item}</MenuItem>
								))}
								<ListSubheader>Fiction</ListSubheader>
								{storyTypes.fiction.map((item, index) => (
									<MenuItem key={`fiction-${index}`} value={item}>{item}</MenuItem>
								))}
							</Select>
							</FormControl>
						<Box >
							<TextField
								sx={{
									width: '100%'
								}}
								margin='normal'
								fullWidth
								name='other_things'
								placeholder='List other stories, books, films, shows, etc that are reminiscent of this story.'
								label='What Other Things Is It Like?'
								id='other_things'
								minRows={4}
								maxRows={12}
								defaultValue={story?.other_things}
								value={other_things}
								onChange={(e) => setOther_things(e.target.value)}
								InputLabelProps={{ shrink: true }}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								inputProps={{ maxLength: 400 }}
			
							/>
						</Box>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "4px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Other Stories From This Project You Want To Use As Context (You can select more than one)
							</InputLabel>
							{Date.now() &&
								<Select
									value={contextStories}
									onChange={handleContextStoriesChange}
									labelId='test-select-label'
									variant='outlined'
									label='Other Stories From This Project You Want To Use As Context (You can select more than one)'
									fullWidth
									multiple
									notched={true}
									InputLabelProps={{ shrink: true }}
								>
									{stories.filter(stories => stories?.title !== story?.title).map((stories, index) => (
										<MenuItem key={index} value={stories?.id}>
											{stories?.title}
										</MenuItem>
									))}
								</Select> 
							} 
						</FormControl>
						<Box sx={{
							border: '1px solid #5f676f',
							padding: '10px',
							borderRadius: '3px',
							mt: '10px',
							}}>
							
							<Box sx={{ paddingLeft: '28px' }}>
							<Typography variant= 'h6'>
								Create a Story Summary
							</Typography>
							</Box>
							<Collapsible title= ""> 
							<Box>
								<TextField
									margin='normal'
									fullWidth
									id='summary'
									label='Summary'
									name='summary'
									multiline
									minRows={4}
									maxRows={12}
									disabled={currentGenerationFieldKey === `summary` ? true : false}
									defaultValue={story?.summary}
									placeholder='Begin writing a summary of your story here. Use the 🤖 button below, and the AI will take what you wrote, combine it with all of the great Characters and Settings that you have created, and generate a fully fleshed out story summary for you. Make sure that you have selected "Type of Story" above so that your story has the appropriate structure.'
									value={summary}
									onChange={(e) => setSummary(e.target.value)}
									InputLabelProps={{ shrink: true }}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 8000 }}
								/>
								
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: {
										xs: '1fr',
										sm: '1fr 1fr',
									},
									gap: {
										xs: '0',
										sm: '20px',
									},
								}}
							>
							{Array.isArray(characters) &&
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "12px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Characters the AI Should Include:
									</InputLabel>
									{Date.now() &&
										<Select
											value={charactersSummaryValue}
											onChange={(e) => {
												if (e.target.value.includes('Select All')){
													setSummaryCharactersValue(characters?.map(item => item?.character?.id))
												} else {
													setSummaryCharactersValue(e.target.value)
												}
											}}
											labelId='test-select-label'
											variant='outlined'
											label='Characters the AI Should Include:'
											fullWidth
											multiple
											notched={true}
											InputLabelProps={{ shrink: true }}
										>
											<MenuItem value={'Select All'}>Select All</MenuItem>
											{characters?.map(item => <MenuItem value={item?.character?.id}>{item?.character?.name}</MenuItem>)}
										</Select>
									}
								</FormControl>
								}
								{Array.isArray(setting) &&
									<FormControl
										fullWidth
										variant='outlined'
										style={{ marginTop: "12px" }}
									>
										<InputLabel
											variant='outlined'
											id='test-select-label'
											shrink={true}
										>
											Settings the AI Should Include:
										</InputLabel>
										{Date.now() &&
											<Select
												value={settingSummaryValue}
												onChange={(e) => {
													if (e.target.value.includes('Select All')){
														setSummarySettingValue(setting?.map(item => item?.settingItem?.id))
													} else {
														setSummarySettingValue(e.target.value)
													}
												}}
												labelId='test-select-label'
												variant='outlined'
												label='Settings the AI Should Include:'
												fullWidth
												multiple
												notched={true}
												InputLabelProps={{ shrink: true }}
											>
												<MenuItem value={'Select All'}>Select All</MenuItem>
												{setting?.map(item => <MenuItem value={item?.settingItem?.id}>{item?.settingItem?.title}</MenuItem>)}
											</Select>
										}
									</FormControl>
								}
							</Box>
							
							<Stack direction="row" spacing='6px' marginTop='10px' alignItems="center" >
							<Tooltip arrow title={`AI Tokens: ${storyFees.summary}`}>
								<Button
									onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('summary',summary_ai_adjust)}
									variant='contained'
									component='label'
									sx={{
										height: '56px',
										marginTop: '16px',
										marginBottom: '8px',
										fontSize: '36px',
									}}
									disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'summary') : false}
								>
									{(loading && currentGenerationFieldKey === 'summary') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'summary') ? <StopIcon /> : ('🤖'))}
								</Button>	
								</Tooltip>
								<Typography
									sx={{
										paddingLeft: {
											xs: 0, 
											md: 0 
										},
										fontSize: {
											xs: '12px', 
											md: '16px' 
										},
										marginTop: {
											xs: '8px', 
											md: '0px'
										}
									}}
									>
									Standard</Typography>
													<Switch
														checked={summaryTemp === 1}
														onChange={() => setSummaryTemp(summaryTemp === 0.7 ? 1 : 0.7)}
														name="summaryTempToggle"
														color="primary"
														size={window.innerWidth < 600 ? "small" : "medium"}
													/>
												<Typography
													sx={{
														fontSize: {
															xs: '12px', // Adjust font size for xs
															md: '16px' // Default font size for md and above
														},
														marginTop: {
															xs: '8px', // Add margin for better spacing on xs
															md: '0px'
														}
													}}
													>
										More Creative</Typography>
										<Tooltip
											arrow
											title="More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas."
											enterTouchDelay={0}
											leaveTouchDelay={3000}
											>
											<IconButton size="small" onClick={handleClick}>
											<InfoIcon fontSize="small" color="primary"/>
											</IconButton>
											</Tooltip>
											<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											>
											<Typography sx={{ p: 2 }}>
												More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas.
											</Typography>
											</Popover>
											</Stack>	
												
							<Box >
								<TextField
									margin='normal'
									fullWidth
									name='summary_ai_adjust'
									label='How would you like the 🤖 AI to adjust the above?'
									id='summary_ai_adjust'
									defaultValue={story?.summary_ai_adjust}
									multiline
									minRows={4}
									maxRows={12}
									InputLabelProps={{ shrink: true }}
									placeholder='Include a stronger B plot, make the ending having an unexpected twist, kill off one of the main characters mid-way thru the story, etc.'
									value={summary_ai_adjust}
									onChange={(e) => setSummary_ai_adjust(e.target.value)}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 4000 }}
								/>
							</Box>
							</Collapsible>
							</Box> 
						<Box sx={{
							border: '1px solid #5f676f',
							padding: '10px',
							borderRadius: '3px',
							marginTop: '8px'
						}}>
							<Box sx={{ paddingLeft: '28px' }}>
							<Typography variant= 'h6'>Create a List of Acts / a List of Major Moments</Typography>
							</Box>
							<Collapsible title= "">
							<Box>
								<TextField
									margin='normal'
									fullWidth
									id='list_of_acts'
									label='List of Acts'
									name='list_of_acts'
									//autoFocus
									multiline
									minRows={4}
									maxRows={12}
									disabled={currentGenerationFieldKey === `list_of_acts` ? true : false}
									defaultValue={story?.list_of_acts}
									placeholder='Go ahead and click 🤖. The AI will grab the Story Summary from above and use it to generate this List of Acts. You can always make changes to what is generated here or give comments to the AI below to have it make the adjustments for you.'
									value={list_of_acts}
									onChange={(e) => setList_of_acts(e.target.value)}
									InputLabelProps={{ shrink: true }}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 16000 }}

								/>
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: {
										xs: '1fr',
										sm: '1fr 1fr',
									},
									gap: {
										xs: '0',
										sm: '20px',
									},
								}}
							>
							{Array.isArray(characters) &&
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "12px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Characters the AI Should Include:
									</InputLabel>
									{Date.now() &&
										<Select
											value={charactersValue}
											onChange={(e) => {
												if (e.target.value.includes('Select All')){
													setCharactersValue(characters?.map(item => item?.character?.id))
												} else {
													setCharactersValue(e.target.value)
												}
											}}
											labelId='test-select-label'
											variant='outlined'
											label='Characters the AI Should Include:'
											fullWidth
											multiple
											notched={true}
											InputLabelProps={{ shrink: true }}
										>
											<MenuItem value={'Select All'}>Select All</MenuItem>
											{characters?.map(item => <MenuItem value={item?.character?.id}>{item?.character?.name}</MenuItem>)}
										</Select>
									}
								</FormControl>
								}
								{Array.isArray(setting) &&
									<FormControl
										fullWidth
										variant='outlined'
										style={{ marginTop: "12px" }}
									>
										<InputLabel
											variant='outlined'
											id='test-select-label'
											shrink={true}
										>
											Settings the AI Should Include:
										</InputLabel>
										{Date.now() &&
											<Select
												value={settingValue}
												onChange={(e) => {
													if (e.target.value.includes('Select All')){
														setSettingValue(setting?.map(item => item?.settingItem?.id))
													} else {
														setSettingValue(e.target.value)
													}
												}}
												labelId='test-select-label'
												variant='outlined'
												label='Settings the AI Should Include:'
												fullWidth
												multiple
												notched={true}
												InputLabelProps={{ shrink: true }}
											>
												<MenuItem value={'Select All'}>Select All</MenuItem>
												{setting?.map(item => <MenuItem value={item?.settingItem?.id}>{item?.settingItem?.title}</MenuItem>)}
											</Select>
										}
									</FormControl>
								}
							</Box>
							<Stack direction="row" spacing='6px' marginTop='10px' alignItems="center" >
							<Tooltip arrow title={`AI Tokens: ${storyFees.list_of_acts}`}>
								<Button
									onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('list_of_acts',list_of_acts_ai_adjust)}
									variant='contained'
									component='label'
									sx={{
										height: '56px',
										marginTop: '16px',
										marginBottom: '8px',
										fontSize: '36px',
									}}
									disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'list_of_acts') : false}
								>
									{(loading && currentGenerationFieldKey === 'list_of_acts') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'list_of_acts') ? <StopIcon /> : ('🤖'))}
								</Button>
								</Tooltip>
								<Typography
									sx={{
										paddingLeft: {
											xs: 0, 
											md: 0 
										},
										fontSize: {
											xs: '12px', 
											md: '16px' 
										},
										marginTop: {
											xs: '8px', 
											md: '0px'
										}
									}}
									>
									Standard</Typography>
													<Switch
														checked={list_of_actsTemp === 1}
														onChange={() => setList_of_actsTemp(list_of_actsTemp === 0.7 ? 1 : 0.7)}
														name="list_of_actsTempToggle"
														color="primary"
														size={window.innerWidth < 600 ? "small" : "medium"}
													/>
												<Typography
													sx={{
														fontSize: {
															xs: '12px', // Adjust font size for xs
															md: '16px' // Default font size for md and above
														},
														marginTop: {
															xs: '8px', // Add margin for better spacing on xs
															md: '0px'
														}
													}}
													>
										More Creative</Typography>
										<Tooltip
											arrow
											title="More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas."
											enterTouchDelay={0}
											leaveTouchDelay={3000}
											>
											<IconButton size="small" onClick={handleClick}>
											<InfoIcon fontSize="small" color="primary"/>
											</IconButton>
											</Tooltip>
											<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											>
											<Typography sx={{ p: 2 }}>
												More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas.
											</Typography>
											</Popover>
											</Stack>	
								<Box>
								<TextField
									margin='normal'
									fullWidth
									name='list_of_acts_ai_adjust'
									label='How would you like the 🤖 AI to adjust the above?'
									id='list_of_acts_ai_adjust'
									defaultValue={story?.list_of_acts_ai_adjust}
									multiline
									minRows={4}
									maxRows={12}
									InputLabelProps={{ shrink: true }}
									placeholder='Include a stronger B plot, make the ending having an unexpected twist, kill off one of the main characters mid-way thru the story, etc.'
									value={list_of_acts_ai_adjust}
									onChange={(e) => setList_of_acts_ai_adjust(e.target.value)}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 4000 }}
								/>
							</Box> </Collapsible> </Box>

							{
								acts && acts.map((act,actIndex) => <Box key={actIndex} sx={{
									border: '1px solid #5f676f',
									padding: '10px',
									borderRadius: '3px',
									marginTop: '8px'
								}}>
									<Box sx={{ paddingLeft: '28px' }}>
									<Typography variant= 'h6'>Act {act?.act_number ? act?.act_number + 1 : actIndex + 1}</Typography>
									</Box>
									<Collapsible title= "">
									<Box>  
									<TextField
									margin='normal'
									fullWidth
									id='list_of_scenes'
									label='List of Scenes / List of Chapters'
									name='list_of_scenes'
									//autoFocus
									multiline
									minRows={4}
									maxRows={12}
									disabled={currentGenerationFieldKey === `act${actIndex} list_of_scenes` ? true : false}
									defaultValue={act?.list_of_scenes}
									placeholder='COPY AND PASTE the ACT you are trying to generate from above into here. And you can always make changes to what is generated here or give comments to the AI below.'
									value={act?.list_of_scenes}
									onChange={(e) => {
										const newActs  = acts.map(oldAct => { return {...oldAct}})
										newActs[actIndex].list_of_scenes = e.target.value
										setActs(newActs)
									}}
									InputLabelProps={{ shrink: true }}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 16000 }}
								/> 
							</Box> 
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: {
										xs: '1fr',
										sm: '1fr 1fr',
									},
									gap: {
										xs: '0',
										sm: '20px',
									},
								}}
							>
							{Array.isArray(characters) &&
								<FormControl
									fullWidth
									variant='outlined'
									style={{ marginTop: "12px" }}
								>
									<InputLabel
										variant='outlined'
										id='test-select-label'
										shrink={true}
									>
										Characters the AI Should Include:
									</InputLabel>
									{Date.now() && 
										<Select
											value={act?.actCharacters ? act?.actCharacters : []}
											onChange={(e) => {
												const newActs  = acts.map(oldAct => { return {...oldAct}})
												if (e.target.value.includes('Select All')){
													newActs[actIndex].actCharacters = characters?.map(item => item?.character?.id)
												} else {
													newActs[actIndex].actCharacters = e.target.value
												}
												setActs(newActs)
											}}
											labelId='test-select-label'
											variant='outlined'
											label='Characters the AI Should Include:'
											fullWidth
											multiple
											notched={true}
											InputLabelProps={{ shrink: true }}
										>
											<MenuItem value={'Select All'}>Select All</MenuItem>
											{characters?.map(item => <MenuItem value={item?.character?.id}>{item?.character?.name}</MenuItem>)}
										</Select>
									}
								</FormControl>
								}
								{Array.isArray(setting) &&
									<FormControl
										fullWidth
										variant='outlined'
										style={{ marginTop: "12px" }}
									>
										<InputLabel
											variant='outlined'
											id='test-select-label'
											shrink={true}
										>
											Settings the AI Should Include:
										</InputLabel>
										{Date.now() &&
											<Select
												value={act?.actSetting ? act?.actSetting : []}
												onChange={(e) => {
													const newActs  = acts.map(oldAct => { return {...oldAct}})
													if (e.target.value.includes('Select All')){
														newActs[actIndex].actSetting = setting?.map(item => item?.settingItem?.id)
													} else {
														newActs[actIndex].actSetting = e.target.value
													}
													setActs(newActs)
												}}
												labelId='test-select-label'
												variant='outlined'
												label='Settings the AI Should Include:'
												fullWidth
												multiple
												notched={true}
												InputLabelProps={{ shrink: true }}
											>
												<MenuItem value={'Select All'}>Select All</MenuItem>
												{setting?.map(item => <MenuItem value={item?.settingItem?.id}>{item?.settingItem?.title}</MenuItem>)}
											</Select>
										}
									</FormControl>
								} 
								</Box>
								<Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}> 
								<Stack direction="row" spacing='6px' marginTop='10px' alignItems="center" >
								<Tooltip arrow title={`AI Tokens: ${storyFees.list_of_scenes}`}>
									<Button
										onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillActFieldWithAI(
											'list_of_scenes',
											act?.list_of_scenes_ai_adjust,
											act?.list_of_scenes,
											act?.actCharacters,
											act?.actSetting,
											act?.act_number + 1,
											actIndex
											)}
										variant='contained'
										component='label'
										sx={{
											height: '56px',
											marginTop: '16px',
											marginBottom: '8px',
											fontSize: '36px',
										}}
										disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === `act${actIndex} list_of_scenes`) : false}
									>
									{(loading && currentGenerationFieldKey === `act${actIndex} list_of_scenes`) ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === `act${actIndex} list_of_scenes`) ? <StopIcon /> : ('🤖'))}
									</Button>	
									</Tooltip>
									<Typography
									sx={{
										paddingLeft: {
											xs: 0, 
											md: 0 
										},
										fontSize: {
											xs: '12px', 
											md: '16px' 
										},
										marginTop: {
											xs: '8px', 
											md: '0px'
										}
									}}
									>
									Standard</Typography>
													<Switch
														checked={list_of_scenesTemp === 1}
														onChange={() => setList_of_scenesTemp(list_of_scenesTemp === 0.7 ? 1 : 0.7)}
														name="list_of_scenesTempToggle"
														color="primary"
														size={window.innerWidth < 600 ? "small" : "medium"}
													/>
												<Typography
													sx={{
														fontSize: {
															xs: '12px', // Adjust font size for xs
															md: '16px' // Default font size for md and above
														},
														marginTop: {
															xs: '8px', // Add margin for better spacing on xs
															md: '0px'
														}
													}}
													>
										More Creative</Typography>
										<Tooltip
											arrow
											title="More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas."
											enterTouchDelay={0}
											leaveTouchDelay={3000}
											>
											<IconButton size="small" onClick={handleClick}>
											<InfoIcon fontSize="small" color="primary"/>
											</IconButton>
											</Tooltip>
											<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											>
											<Typography sx={{ p: 2 }}>
												More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas.
											</Typography>
											</Popover>
											</Stack>	
								</Box> 
								<Box>
								<TextField
									margin='normal'
									fullWidth
									name='list_of_scenes_ai_adjust'
									label='How would you like the 🤖 AI to adjust the above?'
									id='list_of_scenes_ai_adjust'
									defaultValue={act?.list_of_scenes_ai_adjust}
									multiline
									minRows={4}
									maxRows={12}
									InputLabelProps={{ shrink: true }}
									placeholder='Include a scene where we meet the team members, have a series of scenes where the original crime is committed, leave us with a cliffhanger, etc.'
									value={act?.list_of_scenes_ai_adjust}
									onChange={(e) => {
										const newActs  = acts.map(oldAct => { return {...oldAct}})
										newActs[actIndex].list_of_scenes_ai_adjust = e.target.value									
										setActs(newActs)
									}}
									onFocus={()=>handleProjectHeaderVisibility(false)}
									onBlur={()=>handleProjectHeaderVisibility(true)}
									inputProps={{ maxLength: 4000 }}
								/>
							</Box> </Collapsible>
							<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId={'scene-list-' + actIndex}>
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
							{
							act.scenes && act?.scenes?.map((scene,sceneIndex)=> {
								return (
									<Draggable
										draggableId={'dragable-' + sceneIndex}
										index={sceneIndex}
										key={sceneIndex}
									>
									{(provided) => (
										<Box 
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											key={sceneIndex} 
											sx={{
												border: '3px solid #5f676f',
												padding: '10px',
												borderRadius: '3px',
												marginTop: '8px'
											}}
										>
										<Box sx={{ paddingLeft: '28px' }}>
										<Typography variant= 'h6'>Scene or Chapter {sceneIndex + 1}</Typography> 
										</Box>
										<Collapsible title= "">
										<Box>
											<TextField
												margin='normal'
												fullWidth
												id='list_of_beats_for_the_scene'
												label='Create a List of Story Beats for this Scene or Chapter'
												name='list_of_beats_for_the_scene'
												//autoFocus
												multiline
												minRows={4}
												maxRows={12}
												disabled={currentGenerationFieldKey === `scene${scene?.scene_number + 1} list_of_beats_for_the_scene` ? true : false}
												defaultValue={scene?.list_of_beats_for_the_scene}
												placeholder='COPY AND PASTE your scene or chapter from above, or begin writing the major beats you would like to hit during this scene or chapter. Click 🤖 at any time. '
												value={scene?.list_of_beats_for_the_scene}
												onChange={(e) => {
													const newActs  = acts.map(oldAct => { return {...oldAct}})
													const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
													newScenes[sceneIndex].list_of_beats_for_the_scene = e.target.value
													newActs[actIndex].scenes = newScenes
													setActs(newActs)
												}}
												InputLabelProps={{ shrink: true }}
												onFocus={()=>handleProjectHeaderVisibility(false)}
												onBlur={()=>handleProjectHeaderVisibility(true)}
												inputProps={{ maxLength: 8000 }}
											/>
										</Box> 
										<Box
											sx={{
												display: 'grid',
												gridTemplateColumns: {
													xs: '1fr',
													sm: '1fr 1fr',
												},
												gap: {
													xs: '0',
													sm: '20px',
												},
											}}
										>
										{Array.isArray(characters) &&
											<FormControl
												fullWidth
												variant='outlined'
												style={{ marginTop: "12px" }}
											>
												<InputLabel
													variant='outlined'
													id='test-select-label'
													shrink={true}
												>
													Characters the AI Should Include:
												</InputLabel>
												{Date.now() && 
													<Select
														value={scene?.characters ? scene?.characters : []}
														onChange={(e) => {
															const newActs  = acts.map(oldAct => { return {...oldAct}})
															const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
															if (e.target.value.includes('Select All')){
																newScenes[sceneIndex].characters = characters?.map(item => item?.character?.id)
															} else {
																newScenes[sceneIndex].characters = e.target.value
															}													
															newActs[actIndex].scenes = newScenes
															setActs(newActs)

														}}
														labelId='test-select-label'
														variant='outlined'
														label='Characters the AI Should Include:'
														fullWidth
														multiple
														notched={true}
														InputLabelProps={{ shrink: true }}
													>
														<MenuItem value={'Select All'}>Select All</MenuItem>
														{characters?.map(item => <MenuItem value={item?.character?.id}>{item?.character?.name}</MenuItem>)}
													</Select>
												}
											</FormControl>
											}
											{Array.isArray(setting) &&
											<FormControl
												fullWidth
												variant='outlined'
												style={{ marginTop: "12px" }}
											>
												<InputLabel
													variant='outlined'
													id='test-select-label'
													shrink={true}
												>
													Settings the AI Should Include:
												</InputLabel>
												{Date.now() &&
													<Select
														value={scene?.setting ? scene?.setting : []}
														onChange={(e) => {
															const newActs  = acts.map(oldAct => { return {...oldAct}})
															const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
															if (e.target.value.includes('Select All')){
																newScenes[sceneIndex].setting = setting?.map(item => item?.settingItem?.id)
															} else {
																newScenes[sceneIndex].setting = e.target.value
															}													
															newActs[actIndex].scenes = newScenes
															setActs(newActs)
														}}
														labelId='test-select-label'
														variant='outlined'
														label='Settings the AI Should Include:'
														fullWidth
														multiple
														notched={true}
														InputLabelProps={{ shrink: true }}
													>
														<MenuItem value={'Select All'}>Select All</MenuItem>
														{setting?.map(item => <MenuItem value={item?.settingItem?.id}>{item?.settingItem?.title}</MenuItem>)}
													</Select>
												}
											</FormControl>
											}
										</Box>
										
										<Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}> 
										<Stack direction="row" spacing='6px' marginTop='10px' alignItems="center" >
										<Tooltip arrow title={`AI Tokens: ${storyFees.list_of_beats_for_the_scene}`}>
											<Button
												onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillSceneWithAI(
													scene?.list_of_beats_for_the_scene,
													'list_of_beats_for_the_scene',
													scene?.list_of_beats_for_the_scene_ai_adjust,
													scene?.characters,
													scene?.setting,
													(value)=> {
														const newActs  = acts.map(oldAct => { return {...oldAct}})
														const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
														newScenes[sceneIndex].list_of_beats_for_the_scene = value
														newActs[actIndex].scenes = newScenes
														setActs(newActs)
													},
													"",
													null,
													scene?.scene_number + 1,
													act?.list_of_scenes,
													)}

												variant='contained'
												component='label'
												sx={{
													height: '56px',
													marginTop: '16px',
													marginBottom: '8px',
													fontSize: '36px',
													paddingX: '8px'
												}}
												disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} list_of_beats_for_the_scene`) : false}
												>
												{(loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} list_of_beats_for_the_scene`) ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} list_of_beats_for_the_scene`) ? <StopIcon /> : ('🤖'))}
											</Button>
											</Tooltip>
											</Stack>	
											<Stack direction="row" spacing='0px' marginTop='10px' alignItems="center" >
											<Typography
									sx={{
										paddingLeft: {
											xs: 0, 
											md: 0 
										},
										fontSize: {
											xs: '12px', 
											md: '16px' 
										},
										marginTop: {
											xs: '8px', 
											md: '0px'
										}
									}}
									>
									Standard</Typography>
													<Switch
														checked={sceneBeatsTemp === 1}
														onChange={() => setSceneBeatsTemp(sceneBeatsTemp === 0.7 ? 1 : 0.7)}
														name="sceneBeatsTempToggle"
														color="primary"
														size={window.innerWidth < 600 ? "small" : "medium"}
													/>
												<Typography
													sx={{
														fontSize: {
															xs: '12px', // Adjust font size for xs
															md: '16px' // Default font size for md and above
														},
														marginTop: {
															xs: '8px', // Add margin for better spacing on xs
															md: '0px'
														}
													}}
													>
										More Creative</Typography>
										<Tooltip
											arrow
											title="More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas."
											enterTouchDelay={0}
											leaveTouchDelay={3000}
											>
											<IconButton size="small" onClick={handleClick}>
											<InfoIcon fontSize="small" color="primary"/>
											</IconButton>
											</Tooltip>
											<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											>
											<Typography sx={{ p: 2 }}>
												More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas.
											</Typography>
											</Popover>
											</Stack>
										</Box>
										<Box>
											<TextField
												margin='normal'
												fullWidth
												name='list_of_scenes_ai_adjust'
												label='How would you like the 🤖 AI to adjust the above?'
												id='list_of_scenes_ai_adjust'
												defaultValue={scene?.list_of_beats_for_the_scene_ai_adjust}
												multiline
												minRows={4}
												maxRows={12}
												InputLabelProps={{ shrink: true }}
												placeholder='Start with a flashback of the main character and his father that explains why he has a fear of water, make the relationship with his father strict but loving, have their dialogue reveal that his mother died recently, etc.'
												value={scene?.list_of_beats_for_the_scene_ai_adjust}
												onChange={(e) => {
													const newActs  = acts.map(oldAct => { return {...oldAct}})
													const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
													newScenes[sceneIndex].list_of_beats_for_the_scene_ai_adjust = e.target.value
													newActs[actIndex].scenes = newScenes
													setActs(newActs)
												}}
												onFocus={()=>handleProjectHeaderVisibility(false)}
												onBlur={()=>handleProjectHeaderVisibility(true)}
												inputProps={{ maxLength: 4000 }}
											/>
										</Box>
										<Box><Divider variant="fullWidth" sx={{ borderBottomWidth: 3 }} /></Box>
										<Box>
											<TextField
												margin='normal'
												fullWidth
												id='scene_full_script'
												label={`Now Let's Write the Scene or Chapter`}
												name='scene_full_script'
												multiline
												minRows={4}
												maxRows={12}
												defaultValue={scene?.scene_full_script}
												placeholder='You do not need to copy and paste anything from above here. Write any ideas you have for the scene here. Click 🤖 at any time.'
												value={scene?.scene_full_script}
												onChange={(e) => {
													const newActs  = acts.map(oldAct => { return {...oldAct}})
													const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
													newScenes[sceneIndex].scene_full_script = e.target.value
													newActs[actIndex].scenes = newScenes
													setActs(newActs)
												}}
												disabled={currentGenerationFieldKey === `scene${scene?.scene_number + 1} scene_full_script` ? true : false}
												InputLabelProps={{ shrink: true }}
												onFocus={()=>handleProjectHeaderVisibility(false)}
												onBlur={()=>handleProjectHeaderVisibility(true)}
											/>
										</Box>
										<Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
										<Tooltip arrow title={`AI Tokens: ${storyFees.scene_full_script}`}> 
										<Button
												onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillSceneWithAI(
													scene?.scene_full_script,
													'scene_full_script',
													scene?.scene_full_script_ai_adjust,
													scene?.characters,
													scene?.setting,
													(value)=> {
														const newActs  = acts.map(oldAct => { return {...oldAct}})
														const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
														newScenes[sceneIndex].scene_full_script = value
														newActs[actIndex].scenes = newScenes
														setActs(newActs)
													},
													//acts[sceneIndex].list_of_beats_for_the_scene,
													scene?.list_of_beats_for_the_scene,
													scene?.scriptSceneFormat,
													scene?.scene_number + 1,
													act?.list_of_scenes,
													)}

												variant='contained'
												component='label'
												sx={{
													height: '56px',
													marginTop: '16px',
													marginBottom: '8px',
													fontSize: '36px',
												}}
												disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} scene_full_script`) : false}
												>
												{(loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} scene_full_script`) ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === `scene${scene?.scene_number + 1} scene_full_script`) ? <StopIcon /> : ('🤖'))}
										</Button>
										</Tooltip>
										<Grid container spacing={1} alignItems="center" marginTop="10px">
											<Grid item container xs={12} md="auto" alignItems="center" spacing={1}>
												<Grid item>
													<Typography
														sx={{
															paddingLeft: {
																xs: 0,
																md: 2
															},
															fontSize: {
																xs: '12px',
																md: '16px'
															},
															marginTop: {
																xs: '0px',
																md: '0px'
															}
														}}
													>
														Standard
													</Typography>
												</Grid>
												<Grid item>
													<Switch
														sx={{
															marginLeft: {
																xs: '-10px',
																md: '0px'
															}
														}}
														checked={scriptTemp === 1}
														onChange={() => setScriptTemp(scriptTemp === 0.7 ? 1 : 0.7)}
														name="scriptTempToggle"
														color="primary"
														size={window.innerWidth < 600 ? "small" : "medium"}
													/>
												</Grid>
												<Grid item>
													<Typography
														sx={{
															paddingLeft: {
																xs: 0,
																md: 0
															},
															fontSize: {
																xs: '12px',
																md: '16px'
															},
															marginLeft: {
																xs: '-15px',
																md: '0px'
															}
														}}
													>
														More Creative
													</Typography>
												</Grid>
												<Grid item>
												<Tooltip
											arrow
											title="More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas."
											enterTouchDelay={0}
											leaveTouchDelay={3000}
											>
											<IconButton size="small" onClick={handleClick}>
											<InfoIcon fontSize="small" color="primary"/>
											</IconButton>
											</Tooltip>
											<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											>
											<Typography sx={{ p: 2 }}>
												More Creative will give the AI more freedom when it generates text for you. It may not follow your prompts as closely, and may generate more of a random response, but can be useful for exploring new ideas.
											</Typography>
											</Popover>
												</Grid>
												<Grid item xs={12} md="auto">
													<FormControl
														sx={{
															flexGrow: 1,
															mr: 2,
															mt: {
																xs: 2, // Add top margin for xs to create spacing
																md: 0
															},
															width: {
																xs: '100%', // Full width on small screens
																md: '275px' // Auto width on larger screens
															}
														}}
														variant='outlined'
													>
														<InputLabel
															variant='outlined'
															id='test-select-label'
															shrink={true}
														>
															Select the Format of your Scene
														</InputLabel>
														<Select
															value={scene.scriptSceneFormat}
															onChange={(e) => {
																const newActs = acts.map(oldAct => { return { ...oldAct } });
																const newScenes = newActs[actIndex]?.scenes?.map(scene => { return { ...scene } });
																newScenes[sceneIndex].scriptSceneFormat = e.target.value;
																newActs[actIndex].scenes = newScenes;
																setActs(newActs);
															}}
															labelId='test-select-label'
															variant='outlined'
															label='Select the Format of your Scene'
															notched={true}
															InputLabelProps={{ shrink: true }}
														>
															{sceneFormatArray?.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>

											
										</Box>
										
										<Box>
											<TextField
												margin='normal'
												fullWidth
												name='scene_full_script_ai_adjust'
												label='How would you like the 🤖 AI to adjust the above?'
												id='scene_full_script_ai_adjust'
												defaultValue={scene?.scene_full_script_ai_adjust}
												multiline
												minRows={4}
												maxRows={12}
												InputLabelProps={{ shrink: true }}
												placeholder='Tell the AI how you would like it to adjust the generated scene. Do you want it to feature one character more? Do you want the tone to be more serious or more funny? Do you want something specific to happen in the scene?'
												value={scene?.scene_full_script_ai_adjust}
												onChange={(e) => {
													const newActs  = acts.map(oldAct => { return {...oldAct}})
													const newScenes  = newActs[actIndex]?.scenes?.map(scene => { return {...scene}})
													newScenes[sceneIndex].scene_full_script_ai_adjust = e.target.value
													newActs[actIndex].scenes = newScenes
													setActs(newActs)
												}}
												onFocus={()=>handleProjectHeaderVisibility(false)}
												onBlur={()=>handleProjectHeaderVisibility(true)}
												inputProps={{ maxLength: 4000 }}
											/>
										</Box> 
										<Box>
											
											<Button
												onClick={() => {
													setIsDeleteSceneOpen(true)
													deletionSceneIndexRef.current = sceneIndex
													deletionActIndexRef.current = actIndex
													deletionSceneNumberRef.current = scene?.scene_number
													deletionActNumberRef.current = act?.act_number
												}}
												fullWidth
												variant='contained'
												component='label'
												sx={{
													height: '40px',
													marginTop: '10px',
													marginBottom: '6px',
													marginLeft: '6px',
													color: 'white',
													width: 'auto',
													fontSize: '14px'
												}}
												disabled={!isGenerationButtonAllowed}
											>
												<DeleteIcon sx={{color: 'white'}}/>  Delete this Scene
											</Button> 
										</Box> 
										</Collapsible>

									</Box> 
									)}
									</Draggable>							
								)
							})
						} 
						 </div>
						)}
						</Droppable>
						</DragDropContext>
						<Button
							onClick={() => {
								const newActs = JSON.parse(JSON.stringify(acts));
								const newScenes = newActs[actIndex]?.scenes?.map(scene => ({ ...scene }));
							
								// Find the missing scene number in the sequence
								let missingSceneNumber;
								for (let i = 0; i < newScenes.length; i++) {
									if (!newScenes.find(scene => scene.scene_number === i)) {
										missingSceneNumber = i;
										break;
									}
								}
							
								// If no missing scene number found, push a new scene to the end
								if (missingSceneNumber === undefined) {
									newScenes.push({
										scene_number: newScenes.length,
										list_of_beats_for_the_scene: '',
										list_of_beats_for_the_scene_ai_adjust: '',
										scene_full_script: '',
										scene_full_script_ai_adjust: '',
										scriptSceneFormat: 'Script',
									});
									//alert.show(`Scene ${newScenes.length} was successfully added`, { type: 'success' });
								} else {
									// Insert the new scene at the missing scene number position
									newScenes.splice(missingSceneNumber, 0, {
										scene_number: missingSceneNumber,
										list_of_beats_for_the_scene: '',
										list_of_beats_for_the_scene_ai_adjust: '',
										scene_full_script: '',
										scene_full_script_ai_adjust: '',
										scriptSceneFormat: 'Script',
									});
									//alert.show(`Scene ${missingSceneNumber + 1} was successfully added`, { type: 'success' });
								}
							
								newActs[actIndex].scenes = newScenes;
								setActs(newActs);
							}}
							
							fullWidth
							variant='contained'
							sx={{
								height: '40px',
								marginTop: '10px',
								marginBottom: '6px',
								marginLeft: '6px',
								color: 'white',
								width: 'auto',
								fontSize: '14px'
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Add scene or chapter
						</Button>
						<Button
							onClick={() => {
								const newActs  = acts.map(oldAct => { return {...oldAct}})
								const actIndex = newActs.findIndex((act,actIndx) => act?.act_number ? act?.act_number !== actIndx : false)
								if (actIndex < 0) {
									newActs.push({ 	
										list_of_scenes: '',
										list_of_scenes_ai_adjust: '',
										actSetting: [],
										actCharacters: [],
										act_number: newActs.length,
										scenes: [{ 
											scene_number: 0,
											list_of_beats_for_the_scene: '',
											list_of_beats_for_the_scene_ai_adjust: '',
											scene_full_script: '',
											scene_full_script_ai_adjust: '',
											scriptSceneFormat: 'Script',
										}]
									})
									/* alert.show(
										`Act ${newActs.length} was successfully added`,
										{ type: 'success' }
									); */
								} else {
									newActs.splice(actIndex,0,{ 	
										list_of_scenes: '',
										list_of_scenes_ai_adjust: '',
										actSetting: [],
										actCharacters: [],
										act_number: actIndex,
										scenes: [{ 
											scene_number: 0,
											list_of_beats_for_the_scene: '',
											list_of_beats_for_the_scene_ai_adjust: '',
											scene_full_script: '',
											scene_full_script_ai_adjust: '',
											scriptSceneFormat: 'Script',
										}]
									})
									/* alert.show(
										`Act ${actIndex + 1} was successfully added`,
										{ type: 'success' }
									); */
								}
								setActs(newActs)
							}}
							variant='contained'
							component='label'
							sx={{
								height: '40px',
								marginTop: '10px',
								marginBottom: '6px',
								marginLeft: '6px',
								color: 'white',
								width: 'auto',
								fontSize: '14px'
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Add act
						</Button>
						<Button
							onClick={() => {
								setIsDeleteActOpen(true)
								deletionActIndexRef.current = actIndex
								deletionActNumberRef.current = act?.act_number
							}}
							variant='contained'
							component='label'
							sx={{
								height: '40px',
								marginTop: '10px',
								marginBottom: '6px',
								marginLeft: '6px',
								color: 'white',
								width: 'auto',
								fontSize: '14px'
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Delete act 
						</Button>
						</Box> 
						
						)}
						
						</Box>
						
					
					<Box
						ref={buttonsRef}
						sx={(theme) => ({
							display: 'flex',
							justifyContent: 'flex-end',
							position: 'fixed',
							bottom: '0',
							right: '0',
							width: '100%',
							paddingRight: {xs:'50px', md:'35px'},
							backgroundColor: theme.palette.background.default,
							zIndex: '1',
						})}
					>	
						<Button
							fullWidth
							onClick={() => setIsImageGenerationOpen(true)}
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginLeft: '10px',
								marginRight: '10px',
								letterSpacing: '0.5px', // Adjust letter spacing
    							lineHeight: {xs: '1.2', md: 'inherit'} // Adjust line height
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Create Images from Story
						</Button>
						
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								lineHeight: {xs: '1.2', md: 'inherit'},
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '10px',
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Save
						</Button>
						<Button
							fullWidth
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '30px',
    							lineHeight: {xs: '1.2', md: 'inherit'} // Adjust line height
							}}
							disabled={!isGenerationButtonAllowed}
							onClick={() =>
								handleExportPdf()
							}
						>
								Export PDF
						</Button>
					</Box>
				</>
		</Box>
	);
}
