import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Snackbar, Alert, List, ListItem, ListItemText, Divider, Card, CardContent, CssBaseline, Dialog, DialogActions, DialogTitle, Button, CircularProgress, Box } from '@mui/material';
import FileUpload from '../../utils/FileUpload';
import * as constants from '../../helpers/constants';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import MarkdownIt from 'markdown-it';
import htmlToPdfmake from 'html-to-pdfmake';
import axios from 'axios';
import { setTokens } from '../../store/slices/authSlice';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Analysis = () => {
  const [uploadResult, setUploadResult] = useState([]);  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [file, setFile] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false); 
  const [finalAnalysis, setFinalAnalysis] = useState('');
  const [numberOfPages, setNumberOfPages] = useState();
  const [tokenCost, setTokenCost] = useState(0);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [tokensFillModal, setTokensFillModal] = useState(false);
  const md = new MarkdownIt();

  const convertMarkdownToPdfmake = (markdown) => {
    const renderedHtml = md.render(markdown);
    const pdfmakeContent = htmlToPdfmake(renderedHtml);
    return pdfmakeContent;
  };

  const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

  const handleUploadSuccess = (result, uploadedFile) => {
    setFile(uploadedFile);
    setTokenCost(result.tokenCost);
    if (result.characterCount) {
      setCharacterCount(result.characterCount);
      console.log('Character count:', result.characterCount);
      setOpenDialog(true);
    }
  };

  const handleConfirmAnalysis = async () => {
    setOpenDialog(false);
    setIsAnalyzing(true); // Start showing progress indicator
    setSnackbarMessage("Your PDF has been received by our AI."); // Update snackbar message to indicate analysis
    setSnackbarOpen(true); // Show snackbar

    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('confirm', 'true');

    try {
      await axios.post(constants.BASE_URL + 'api/analysis/pdf-analysis', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        const result = response.data; // Ensure the response data is correctly accessed
        setUploadResult(result.analysesResults || []);
        setFinalAnalysis(result.finalAnalysis);
        setNumberOfPages(result.numberOfPages);
        getUserTokens();
        setSnackbarMessage('Analysis completed successfully.');
      }).catch(function (error) {
        if (error.response?.data === 'Insufficient tokens for processing.') {
          setSnackbarMessage('You do not have enough tokens to process this file.');
          setTokensFillModal(true);
        } else {
          setSnackbarMessage('An error occurred during processing.');
        }
        console.error('Error:', error);
      });
    } catch (error) {
      console.error('Unexpected error:', error);
      setSnackbarMessage('An unexpected error occurred.');
    } finally {
      setIsAnalyzing(false); // Hide progress indicator
      setSnackbarOpen(false); // Hide the snackbar once analysis is done
    }
  };

  const handleConfirmStyle = async () => {
    setOpenDialog(false);
    setIsAnalyzing(true); // Start showing progress indicator
    setSnackbarMessage("Your PDF has been received by our AI."); // Update snackbar message to indicate analysis
    setSnackbarOpen(true); // Show snackbar

    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('confirm', 'true');

    try {
      await axios.post(constants.BASE_URL + 'api/analysis/pdf-style', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        const result = response.data; // Ensure the response data is correctly accessed
        setUploadResult(result.analysesResults || []);
        setFinalAnalysis(result.finalAnalysis);
        setNumberOfPages(result.numberOfPages);
        getUserTokens();
        setSnackbarMessage('Analysis completed successfully.');
      }).catch(function (error) {
        if (error.response?.data === 'Insufficient tokens for processing.') {
          setSnackbarMessage('You do not have enough tokens to process this file.');
          setTokensFillModal(true);
        } else {
          setSnackbarMessage('An error occurred during processing.');
        }
        console.error('Error:', error);
      });
    } catch (error) {
      console.error('Unexpected error:', error);
      setSnackbarMessage('An unexpected error occurred.');
    } finally {
      setIsAnalyzing(false); // Hide progress indicator
      setSnackbarOpen(false); // Hide the snackbar once analysis is done
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUploadError = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const textForExport = (chunkAnalyses, finalAnalysis) => {
    const content = [];
    
    if (chunkAnalyses) {
      chunkAnalyses.forEach((analysis, index) => {
        content.push({ text: `Section ${index + 1} Analysis:`, style: 'header' });
        const markdownContent = typeof analysis.chunkAnalysis === 'string' ? convertMarkdownToPdfmake(analysis.chunkAnalysis) : [];
        content.push(...markdownContent);
      });
    }
    
    content.push({ text: 'Final Analysis:', style: 'header' });
    const finalMarkdownContent = typeof finalAnalysis === 'string' ? convertMarkdownToPdfmake(finalAnalysis) : [];
    content.push(...finalMarkdownContent);
    
    return {
      content: content,
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10], // Top, Right, Bottom, Left
        },
        markdown: {
          fontSize: 12,
          margin: [0, 5, 0, 5]
        }
      }
    };
  };
  

  const exportAnalysisToPDF = (chunkAnalyses, finalAnalysis) => {
    console.log('text for export:', textForExport(chunkAnalyses, finalAnalysis));
    const docDefinition = textForExport(chunkAnalyses, finalAnalysis);
    pdfMake.createPdf(docDefinition).download('analysis-results.pdf');
  };

  const renderAnalysis = (analysis) => {
    if (typeof analysis === 'string') {
      return <ReactMarkdown remarkPlugins={[remarkGfm]}>{analysis}</ReactMarkdown>;
    } else if (typeof analysis === 'object') {
      return (
        <>
          {/* {analysis.summary && <Typography variant="body2">Summary: {analysis.summary}</Typography>}
          {analysis.sentiment && <Typography variant="body2">Sentiment: {analysis.sentiment}</Typography>}
          {analysis.keyPoints && (
            <>
              <Typography variant="body2">Key Points:</Typography>
              <List>
                {analysis.keyPoints.map((point, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={point} />
                  </ListItem>
                ))}
              </List>
            </>
          )} */}
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <AutoFillTokensModal open={tokensFillModal} setOpen={setTokensFillModal} currentProjectName={file?.name}/>
      <CssBaseline />
      <Box mt={5}> {/* Add marginTop to the FileUpload component */}
      <FileUpload onSuccess={(result, file) => handleUploadSuccess(result, file)} onError={handleUploadError} />
      </Box>      
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{`This document will cost ${tokenCost} tokens to analyze. Would you like to proceed?`}</DialogTitle>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseDialog}>Cancel</Button>
          <Button variant='contained' onClick={handleConfirmAnalysis}>
            Script Coverage
          </Button>
          <Button variant='contained' onClick={handleConfirmStyle}>
            Writing Style
          </Button>
        </DialogActions>
      </Dialog>
      <Box position="relative" display="flex" flexDirection="column" alignItems="center">
        {isAnalyzing && (
          <>
            <CircularProgress style={{ margin: '20px auto' }} />
            <Typography variant="body1">Your PDF is being analyzed by our AI.</Typography>
          </>
        )}
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {(uploadResult.length === 0) && (isAnalyzing === false) && (
  <Box 
    sx={{ 
      maxWidth: '800px',
      margin: '40px auto',
      padding: '24px',
      backgroundColor: (theme) => theme.palette.background.alt,
      borderRadius: '12px',
      boxShadow: 2
    }}
  >
    <Typography 
      variant="h5" 
      gutterBottom 
      sx={{ 
        fontWeight: 500,
        color: (theme) => theme.palette.primary.main,
        marginBottom: '16px'
      }}
    >
      Welcome to Storybuilt's Script Analysis
    </Typography>
    <Typography 
      sx={{ 
        fontSize: '1.1rem',
        lineHeight: 1.6,
        color: (theme) => theme.palette.text.primary,
        '& strong': {
          color: (theme) => theme.palette.primary.main,
          fontWeight: 500
        }
      }}
    >
      After uploading your PDF, you can choose between two powerful AI tools:
      <Box component="ul" sx={{ mt: 2, mb: 2 }}>
        <li><strong>Script Coverage AI</strong> - Provides comprehensive analysis of your script</li>
        <li><strong>Writing Style AI</strong> - Analyzes and summarizes the writing style, which you can add to your Storybuilt project rules field to emulate this style</li>
      </Box>
      Upload your PDF to get started!
    </Typography>
  </Box>
)}
      {uploadResult && uploadResult.length > 0 && (
      <div style={{ marginTop: '25px', background: constants.DARK_GREY }}>
        <Typography ml="2rem" pt='10px' color="white" variant="h4">Analysis Results:</Typography>
        <Typography ml="3rem" pt='10px' color="white" variant="h4">{file?.name}</Typography>
        <Typography ml="2rem" pt='10px' color="white" variant="h6">Total Number of Pages Analyzed: {numberOfPages} </Typography>
        <List>
          {uploadResult.map((result, index) => (
            result.chunkAnalysis ? (
            <React.Fragment key={index}>
              <Card variant="outlined" sx={{ margin: '10px 10px' }}>
                <CardContent>
                  <Typography variant="h6">Section {index + 1} Analysis:</Typography>
                  <Divider style={{ margin: '10px 0' }} />
                  {renderAnalysis(result.chunkAnalysis)}
                </CardContent>
              </Card>
              <Divider light />
            </React.Fragment>
            ) : null
          ))}
        </List>
        <React.Fragment>
              <Card variant="outlined" sx={{ margin: '10px 10px', marginBottom: '40px'}}>
                <CardContent>
                  <Typography variant="h6">Final Analysis:</Typography>
                  <Divider style={{ margin: '10px 0' }} />
                  {renderAnalysis(finalAnalysis)}
                </CardContent>
              </Card>
              <Divider light />
            </React.Fragment>
            
      </div>
    )}
    <div
  style={{
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '100%',
    paddingRight: '30px',
    backgroundColor: '#2f3944',
    zindex: '1',
  }}
>
        <Button 
              //fullWidth
              variant='contained'
              sx={{
                mt: 1,
                mb: 1,
                color: '#fff',
                width: 'fit-content',
                'align-self': 'self-end',
                marginLeft: '30px',
              }}
              onClick={() => exportAnalysisToPDF(uploadResult, finalAnalysis)}>
              Export to PDF
            </Button>
      </div>
  </div>
  );
};

export default Analysis;