import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert'
import axios from 'axios';
import { addLoadingFlag,removeLoadingFlag } from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import { logout } from '../../../store/slices/authSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    sm: '400px'
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function DeleteCharacter({open,setOpen,character,characterId,episodeIndex,setCardsToRenderFunc}) {
  const handleClose = () => setOpen(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const token = useSelector((state) => state.auth.token);
  const projectId= () =>location.pathname.slice(9,location.pathname.length);
  const characterName = useRef()
  const navigate = useNavigate()
  const stateLoading = useSelector((state) => state.loading.loading);

  const handleSubmit = async (e) => {
    if (stateLoading) {
      return
    }
    e.preventDefault();
      characterName.current = character.name
      dispatch(addLoadingFlag('delete-character'))
      await axios.delete(constants.BASE_URL + 'api/projects/delete-character-by-id/' + character.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          "from": "all",
          "project_id": projectId()
        },
      } )
      .then(function (response) {
        dispatch(removeLoadingFlag('delete-character'))
        setCardsToRenderFunc()
      })
      .catch(function (error) {
        dispatch(removeLoadingFlag('delete-character'))
        setCardsToRenderFunc()
        if (error.response?.data === 'User does not exist.') {
          navigate('login')
          dispatch(logout())
        }
      });
      handleClose()
      const params = {};
      for(let entry of searchParams.entries()) {
        params[entry[0]] = entry[1];
      }
      setSearchParams({...params, item: null})
      alert.show(`'${characterName.current}' was successfully deleted!`,{type: 'success'})
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={style}>
        <Typography textAlign='center' sx={{ m: 0, p: 2 }}>
          DELETE CHARACTER: {character?.name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{
          display: 'flex', gap: '10px', justifyContent: 'center'
        }}>
          <Button
            type="submit"
            
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff' }}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff' }}
          >
            No
          </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}