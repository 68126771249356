import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import AdminChangePassword from '../../components/modals/AdminChangePassword';
import EnterpriseRegister from '../../components/modals/EnterpriseRegister';
import EditUser from '../../components/modals/EditUser';
import { useNavigate } from 'react-router-dom';

const UsersManagement = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const loadingFlags = useSelector((state) => state.loading);
  const navigate = useNavigate()
  const alert = useAlert();
  const [users, setUsers] = useState([])
  const [changeModalOpen, setChangeModalOpen] = useState(false)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserMail,setCurrentUserMail] = useState('');

  const getUsers = () => {
    dispatch(addLoadingFlag('get-enterprise-users'));
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-enterprise-users',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-enterprise-users'));
      alert.show(
        `Got users`,
        { type: 'success' }
      );
      setUsers(response.data)
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      // console.log(error);
      dispatch(removeLoadingFlag('get-enterprise-users'));
    });
  }

  const deleteUser = async (userId) => {
    dispatch(addLoadingFlag('delete-user'));

    try {
        const response = await axios.post(constants.BASE_URL + 'api/auth/delete-enterprise-user', {
            userId,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        dispatch(removeLoadingFlag('delete-user'));
        alert.show(
            `User deleted`,
            { type: 'success' }
        );
        getUsers();
    } catch (error) {
        dispatch(removeLoadingFlag('delete-user'));
        
        if (error.response) {
            if (error.response.data === 'Permission denied') {
                navigate('/#');
            } else if (error.response.data && error.response.data.error) {
                alert.show(
                    `Error: ${error.response.data.error}`,
                    { type: 'error' }
                );
            } else {
                alert.show(
                    `An unknown error occurred.`,
                    { type: 'error' }
                );
            }
        } else {
            alert.show(
                `An unknown error occurred.`,
                { type: 'error' }
            );
        }
    }
  };

  useEffect(()=> {
    getUsers()
  },[alert,dispatch,token])
  
  const handleRegisterModalClose = () => {
    window.location.reload(); // Refresh the page when the modal closes
  };
  
    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          <AdminChangePassword open={changeModalOpen} setOpen={setChangeModalOpen} userMail={currentUserMail}/>
          <EnterpriseRegister open={registerModalOpen} setOpen={setRegisterModalOpen} getUsersFunc={getUsers}  onModalClose={handleRegisterModalClose}/>
          <EditUser open={editModalOpen} setOpen={setEditModalOpen} user={currentUser} getUsers={getUsers} />

          {!loading && <>
              <Box
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 8,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
                  {/* <Button
                  onClick={() => ''}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '56px',
                    color: 'white',
                    marginTop: '16px',
                    marginBottom: '8px',
                    fontSize: '36px',
                  }}
                >
                  SAVE
                </Button> */}
              </Box>
              <Typography variant='h4' sx={{ my: '20px' }}> Team Management </Typography>
              <Button
                  onClick={() => {
                    setRegisterModalOpen(true)
                  }}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Create new user
                </Button>
                <Typography> Total Number of Team Accounts: {users.length} </Typography>
             {users.map(user => 
               <Box
               key={user._id}
                  sx={{
                    padding: '15px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    width: '70vw'
                  }}
                  fullWidth
               >
                <Typography>User Name: {user?.name}</Typography>
                <Typography>User Email: {user?.email}</Typography>
                <Button
                onClick={() => {
                  setCurrentUser(user);
                  setEditModalOpen(true);
                }}
                variant='contained'
                component='label'
                sx={{
                  height: '36px',
                  color: 'white',
                  marginTop: '8px',
                  marginBottom: '8px',
                  fontSize: '14px',
                  marginRight: '8px'
                }}
              >
                Edit User Info
              </Button>
                <Button
                  onClick={() => {
                    setCurrentUserMail(user?.email)
                    setChangeModalOpen(true)
                  }}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                >
                  Change password
                </Button>
                <Button
                  onClick={() => deleteUser(user._id, false)}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '36px',
                    color: 'white',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    marginRight: '8px'
                  }}
                  // disabled
                >
                  Delete User
                </Button>
              </Box>)
              }
          
            </Box>
          </>}
      </Container>
  );
};

export default UsersManagement;

