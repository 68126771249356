import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useAlert } from 'react-alert';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		md: '600px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

export default function CreateNewProject({ open, setOpen, getAllProjects, projectsLength }) {
	const handleClose = () => setOpen(false);
	const dispatch = useDispatch();
	const alert = useAlert();
	const token = useSelector((state) => state.auth.token);
	const stateLoading = useSelector((state) => state.loading.loading);
	const navigate = useNavigate();
	const [imageFile, setImageFile] = useState();
	const [imageInput, setImageInput] = useState();
	const [titleInput, setTitleInput] = useState();
	const [descriptionInput, setDescriptionInput] = useState();

	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		data.append('order',projectsLength)
		if (data.get('image')?.size === 0) data.delete('image');

		if (data.get('title')) {
			if (await checkImageSize(imageInput)) {
				dispatch(addLoadingFlag('create-project'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');
					try {
						const options = {
							maxSizeMB: 0.90,
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image',compressedFile)
						await request()

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request()
				}
			}
		}

		async function request () {
			await axios
					.post(constants.BASE_URL + 'api/projects/create', data, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(async function (response) {
						dispatch(removeLoadingFlag('create-project'));
						handleClose();
						// console.log()
						if (response?.data?.id) {
							navigate('/project-info/' + response.data.id)
						} else {
							await getAllProjects();
						}
				
						alert.show(`Project was successfully created!`, {
							type: 'success',
						});
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('create-project'));
						if (error.response?.data === 'User does not exist') {
							navigate('login');
							dispatch(logout());
						}
						
							alert.show(
								`Something went wrong, could not add image.`,
								{ type: 'error' }
							);
						
					});
		}
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				// console.log('error');
				setImageFile('');
				setImageInput('');
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageInput('');
				setImageFile(event.target.value);
				alert.show(`Image was successfully uploaded`, {
					type: 'success',
				});
			}
		}
	};

	const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography sx={{ m: 0, p: 2 }}>
						CREATE NEW PROJECT
					</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<TextField
						margin='normal'
						required
						fullWidth
						id='title'
						label='Enter title'
						name='title'
						InputLabelProps={{ shrink: true }}
						autoFocus
						value={titleInput}
						onChange={(e) => setTitleInput(e.target.value)}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<TextField
							margin='normal'
							fullWidth
							name='imageURL'
							label='Enter web link to image'
							placeholder='Leave Blank for Default Image'
							id='image'
							InputLabelProps={{ shrink: true }}
							value={imageInput}
							onChange={(e) => {
								// console.log(e.target.value);
								setImageInput(e.target.value);
								setImageFile('');
							}}
						/>
						<Typography sx={{ m: 0, p: 2 }}>OR</Typography>
						<Button
							variant='contained'
							component='label'
							sx={{
								height: '56px',
								marginTop: '16px',
								marginBottom: '8px',
								textAlign: 'center',
							}}
						>
							Upload Image
							<input
								value={imageFile}
								name={'image'}
								onChange={(e) => {
									handleFileChange(e);
								}}
								hidden
								accept='image/*'
								type='file'
							/>
						</Button>
					</div>
					{/* <TextField
						margin='normal'
						InputLabelProps={{ shrink: true }}
						required
						fullWidth
						multiline
						minRows={4}
						maxRows={12}
						name='description'
						label='Enter description'
						id='description'
						value={descriptionInput}
						onChange={(e) => setDescriptionInput(e.target.value)}
							/>  */}
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Create
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
