import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
	Box,
	Modal,
	Button,
	FormControl,
	InputLabel,	
	Select,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeImage from '../modals/ChangeImage';
import DeleteImage from '../modals/DeleteImage';
import { Draggable } from 'react-beautiful-dnd';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../store/slices/loadingSlice';
import * as constants from '../../helpers/constants';

export default function ImageCard({
	image,
	episodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
	setting,
	characters,
	stories,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
	const [deleteCharacterOpen, setDeleteCharacterOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const location = useLocation();
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const token = useSelector((state) => state.auth.token);
	const [isCopyMenuOpen, setIsCopyMenuOpen] = useState(false); 
	const [selectedFolder, setSelectedFolder] = useState(''); 
	const [availableFolders, setAvailableFolders] = useState([]); 
	const dispatch = useDispatch();
	const alert = useAlert();

	const style2 = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper', 
		border: '2px solid black',  
		borderRadius: 2,
		boxShadow: 24,
		p: 2,
		display: 'flex',  
		flexDirection: 'column',
		alignItems: 'center',
		zIndex: 1000,
		outline: 'none',
		minWidth: {xs:'90vw', md:'50vw'},
	  };

	const open = Boolean(anchorEl);
	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);	
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setCardChangeModalFlag((current) => !current);
	}, [image]);

	let draggableId;
	if (episodeIndex) {
		draggableId = episodeIndex * 10 + image?.id;
	} else {
		draggableId = image?.id;
	}

	const fetchAvailableFolders = async () => {
		
		try {
            const response = await axios.get(`${constants.BASE_URL}api/projects/get-folders/` + projectId(), {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAvailableFolders(response.data);
		
        } catch (error) {
            console.error('Failed to fetch folders', error);
            alert.show('Failed to fetch folders', { type: 'error' });
        } 
    };

	const handleCopyMenuOpen = () => {
		setIsCopyMenuOpen(true); // Open the copy menu
		fetchAvailableFolders();
		handleMenuClose();
	};
	
	const handleCopyMenuClose = () => {
		setIsCopyMenuOpen(false); // Close the copy menu
	};

	const handleFolderSelect = (event) => {
		setSelectedFolder(event.target.value); // Set the selected folder
	};
	
	const handleCopyToFolder = async () => {
		dispatch(addLoadingFlag('copy-image'));
		
			const imageToCopy = image;
			const imageID = imageToCopy.image; 
			console.log('imageid', imageID)
			console.log('image to copy #1', imageToCopy)
			
        // Proceed to find the image file on the backend using the extracted ID
        try {
            const response = await axios.get(constants.BASE_URL + 'api/projects/get-illustration-by-id/' + imageID, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
			console.log('response', response)
            const newImageId = response.data.id;
			console.log('new image data', newImageId)
			let newImageArray = [];
			newImageArray[0]=(newImageId);
			newImageArray[1] = (imageToCopy.prompt || "") + " : " + (imageToCopy.imageStyles || "");
			newImageArray[2] = imageToCopy.title || "";
			console.log('image to copy #2', newImageArray)

            const copyData = {
                targetFolderId: selectedFolder,
                imageArray: newImageArray,
            };
		
			/* const copyData = {
				//sourceFolderId: folderItem.id,
				targetFolderId: selectedFolder,
				imageArray: imageToCopy,
			}; */
	
			
				// Perform Axios POST request to copy the image to another folder
				await axios.post(constants.BASE_URL + 'api/projects/copy-image-to-folder', {
					copyData,
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				
				alert.show('Image copied successfully', { type: 'success' });
				
			} catch (error) {
				if (error.response && error.response.status === 400 && error.response.data.msg === 'Image already exists in the target folder') {
					alert.show('Image already exists in the target folder', { type: 'error' });
				} else {
					console.error('Error copying image:', error);
					alert.show('Error copying image', { type: 'error' });
				}
			} finally {
				handleCopyMenuClose();
				dispatch(removeLoadingFlag('copy-image'));
			}
		
	};




	return (
		<Draggable
			draggableId={'dragable-' + index}
			index={index}
			key={draggableId}
		>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, maxWidth: {xs:'200px', md:'170px'} }}
				>
					<div style={{ position: 'relative' }}>
					<Modal
							open={isCopyMenuOpen}
							onClose={handleCopyMenuClose}
							aria-labelledby="copy-modal-title"
							aria-describedby="copy-modal-description"
						>
							<Box sx={style2}>
								<FormControl fullWidth>
									<InputLabel id="folder-select-label">Select Folder</InputLabel>
									<Select
										labelId="folder-select-label"
										label="Select Folder"
										value={selectedFolder}
										onChange={handleFolderSelect}
									>
										{availableFolders.map((folder) => (
											<MenuItem key={folder.id} value={folder.id}>
												{folder.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
									<Button variant="contained" color="primary" onClick={handleCopyToFolder}>
										Copy
									</Button>
									<Button variant="contained" color="primary" onClick={handleCopyMenuClose}>
										Cancel
									</Button>
								</Box>
							</Box>
						</Modal>
						{cardChangeModalFlag && (
							<ChangeImage
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								image={image}
								imageId={image?.id}
								episodeIndex={episodeIndex}
								setting={setting}
								characters={characters}
								stories={stories}
							/>
						)}
						{!cardChangeModalFlag && (
							<ChangeImage
								setCardsToRenderFunc={setCardsToRenderFunc}
								open={changeCharacterOpen}
								setOpen={setChangeCharacterOpen}
								image={image}
								imageId={image?.id}
								episodeIndex={episodeIndex}
								setting={setting}
								characters={characters}
								stories={stories}
							/>
						)}
						<DeleteImage
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteCharacterOpen}
							setOpen={setDeleteCharacterOpen}
							image={image}
							imageId={image?.id}
							episodeIndex={episodeIndex}
						/>
	
						{/* <CardActionArea> */}
						{cardChangeModalFlag && (
							<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({

								position: 'relative',
								height: {xs:'200px', md:'170px'},
								width: {xs:'200px', md:'170px'},
								background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								zIndex: 2,
								}}
								image={constants.IMAGE_URL + image?.image + '?time=' + image?.lastImageUpdate}
								alt={'generated image'}
								onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
							/>
							<Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{image?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
						</Box>
						)}
						{!cardChangeModalFlag && (
							<Box
							onClick={onCardHandleClick}
							sx={(theme) => ({
								position: 'relative',
								height: {xs:'200px', md:'170px'},
								width: {xs:'200px', md:'170px'},
								background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								}}
								image={constants.IMAGE_URL + image?.image + '?time=' + image?.lastImageUpdate}
								alt={'generated image'}
								onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
							/>
						</Box>
						)}

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleMenuClose}
							onClick={handleMenuClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								onClick={() => setChangeCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Change Image
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleCopyMenuOpen}>Copy to an Image Folder</MenuItem>
							<MenuItem
								onClick={() => setDeleteCharacterOpen(true)}
							>
								<Typography textAlign='center'>
									Delete Image
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions sx={{minHeight: '37.34px'}}>
							<Typography
								//gutterBottom
								variant='h5'
								component='div'
								onClick={() => {window.scrollTo(0, 0); onCardHandleClick()}}
								sx={{
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "2px",
									maxWidth: 'calc(100% - 18px)',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{image?.title}
							</Typography>
						</CardActions>
						{/* </CardActionArea> */}
						<Tooltip>
							<IconButton
								onClick={handleMenuOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '0px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
