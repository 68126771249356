import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
	// CardActionArea,
	CardActions,
	Typography,
	Button,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeCharacter from '../modals/ChangeCharacter';
import DeleteFolder from '../modals/DeleteFolder';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import placeholder from '../../assets/images/placeholder.png';
import * as constants from '../../helpers/constants';
import { Box } from '@mui/material';
import orangeFolder from '../../assets/images/orange-folder.svg'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import ModalLoader from '../ModalLoader';
import axios from 'axios';

export default function ImageFolderCard({
	folder,
	folderId,
	episodeIndex,
	onCardHandleClick,
	index,
	setCardsToRenderFunc,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	// const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
	const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
	const [cardChangeModalFlag, setCardChangeModalFlag] = useState(false);
	const [title, setTitle] = useState('');
	const [titleModal, setTitleModal] = useState(false)
	const token = useSelector((state) => state.auth.token);
	const location = useLocation();
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const open = Boolean(anchorEl);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		outline: 'none',
		p: 4,
		width: {
			xs: '100%',
			sm: '818px',
		},
	};
	async function updateFolderTitle () {
		setLoading(true)
		console.log('axios folder id:', title + folder?.id)
			await axios
				.put(
					constants.BASE_URL +
					'api/projects/update-folder-by-id/' +
					folder.id,
					{title: title},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose()
					setTitleModal(false)
					setTitle('')
					setLoading(false)
					setCardsToRenderFunc();
				});
	}
	useEffect(() => {
		setCardChangeModalFlag((current) => !current);
	}, [folder]);

	let draggableId;
	if (episodeIndex) {
		draggableId = episodeIndex * 10 + folder?.id;
	} else {
		draggableId = folder?.id;
	}
	return (
		
		<Draggable
			draggableId={'draggable-' + index}
			index={index}
			key={draggableId}
		>
			{(provided) => (
				<Card
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p: 0, width: { xs: 'max-content', sm: '140px' }, background: 'none', boxShadow: 'none', marginBottom: '10px'}}
				>
					<div style={{
						position: 'relative', 
						display: 'flex', 
						flexDirection: 'column', 
						alignItems: 'center',
						
					}}>
						<Modal open={titleModal} onClose={() => setTitleModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
							<Box component='form' noValidate sx={style}>
								<Typography sx={{ m: 0, p: 2 }}>Edit Folder Title</Typography>
								<IconButton aria-label='close' onClick={() => setTitleModal(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
									<CloseIcon />
								</IconButton>
								
								<TextField
									margin='normal'
									required
									fullWidth
									id='title'
									label='New Title'
									name='title'
									autoFocus
									defaultValue={folder?.title}
									//value={title}
									InputLabelProps={{ shrink: true }}
									onChange={(e) => setTitle(e.target.value)}
									onKeyPress={(ev) => {
										if (ev.key === 'Enter') {
											updateFolderTitle();
											ev.preventDefault();
										}
									}}
								/>
								<Button onClick={updateFolderTitle} fullWidth /* disabled={title.trim().length === 0} */ variant='contained' sx={{ mt: 3, mb: 2, color: '#fff' }}>
									Save
								</Button>
							</Box>
						</Modal>
						<DeleteFolder
							setCardsToRenderFunc={setCardsToRenderFunc}
							open={deleteFolderOpen}
							setOpen={setDeleteFolderOpen}
							folder={folder}
							episodeIndex={episodeIndex}
						/> 

						{/* <CardActionArea> */}
						<Tooltip arrow title={folder?.title} placement='right'>
						<CardMedia
							component='img'
							sx={{
								height: 100,
								width: 100,
								zIndex: 2,
							}}
							image={orangeFolder}
							alt={'Orange folder'}
							onClick={() => onCardHandleClick()}
							onError={(e) => {
								e.target.src = placeholder;
							}}
						/>
						</Tooltip>

						<Menu
							anchorEl={anchorEl}
							id='account-menu'
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem
								onClick={() => {setTitleModal(true); console.log(folder?.id)}}>
								<Typography textAlign='center'>
									Change Title
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => setDeleteFolderOpen(true)}
							>
								<Typography textAlign='center'>
									Delete Folder
								</Typography>
							</MenuItem>
						</Menu>

						<CardActions sx={{marginTop: '-12px', width: '100%', display: 'flex', alignItems: 'center'}}>
							<Tooltip arrow title={folder?.title} placement='right'>
							<Typography
								variant='h5'
								onClick={() => onCardHandleClick()}
								sx={{
									textAlign: 'center',
									width: '100%',
									fontSize: '16px',
									paddingTop: '0px',
									paddingLeft: "10px",
									maxWidth: '90%',
									textOverflow: 'ellipsis',
									//whiteSpace: 'nowrap',
									overflow: 'hidden',
									flexGrow: 1,
								}}
							>
								{folder?.title}
							</Typography>
							</Tooltip>
						{/* </CardActionArea> */}
						<Tooltip arrow sx={{marginTop: '-10px'}}>
							<IconButton
								onClick={handleOpen}
								size='small'
								sx={{
									ml: 2,
									position: 'absolute',
									right: '0px',
									bottom: '2px',
								}}
								aria-controls={
									open ? 'account-menu' : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
							>
								<Avatar
									sx={{
										width: 24,
										height: 24,
										bgcolor: 'transparent',
									}}
								>
									<MoreVertIcon style={{ color: '#fff' }} />
								</Avatar>
							</IconButton>
						</Tooltip>
						</CardActions>
					</div>
				</Card>
			)}
		</Draggable>
	);
}
