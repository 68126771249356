import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.auth.role);

  // Debugging logs
  console.log('isAuthenticated:', isAuthenticated);
  console.log('userRole:', userRole);
  console.log('adminOnly:', adminOnly);

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  if (adminOnly && userRole !== 'Admin') {
    // Redirect non-admin users to a different page, e.g., home or unauthorized
    return <Navigate to='/' />;
  }

  return children;
};

export default ProtectedRoute;
