export const dedent = (strings, ...values) => {
    // Combine the string parts and values
    let fullString = strings.reduce((acc, str, i) => acc + str + (values[i] || ''), '');
  
    // Split into lines
    const lines = fullString.split('\n');
  
    // Remove leading and trailing empty lines
    while (lines.length && lines[0].trim() === '') {
      lines.shift();
    }
    while (lines.length && lines[lines.length - 1].trim() === '') {
      lines.pop();
    }
  
    // Find minimum indentation
    const indentLengths = lines
      .filter(line => line.trim()) // Ignore empty lines
      .map(line => line.match(/^(\s*)/)[0].length);
  
    const minIndent = Math.min(...indentLengths);
  
    // Remove the minimum indentation
    const dedentedLines = lines.map(line => line.slice(minIndent));
  
    // Join back into a single string
    return dedentedLines.join('\n');
  };
  