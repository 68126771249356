import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { addLoadingFlag, removeLoadingFlag } from '../../../store/slices/loadingSlice';
import Countdown from 'react-countdown';
import { keyframes } from '@mui/system';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';

import TokenIcon from '@mui/icons-material/Token';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const AutoFillTokensModal = ({ open, setOpen, currentProjectName, noCountdown }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [offerDeadline] = useState(Date.now() + 5 * 60 * 1000);
  const [selectedSku, setSelectedSku] = useState('004');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const tokenPackages = [
    { sku: '003', tokens: 10000, price: 10, bonus: '0' },
    { sku: '004', tokens: 27500, price: 25, bonus: '10' },
    { sku: '005', tokens: 60000, price: 50, bonus: '20' },
  ];

  const calculateDiscountedPrice = (price) => {
    return (price * 0.95).toFixed(2); // 5% discount
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handlePurchase = async () => {
    dispatch(addLoadingFlag('add-tokens'));
    const currentFullUrl = window.location.href;

    try {
      console.log('selectedSku', selectedSku);
      console.log('currentFullUrl', currentFullUrl);
      const response = await axios.post(
        `${constants.BASE_URL}api/auth/add-tokens`,
        {
          successUrl: `${currentFullUrl}?stripe_result=success`,
          cancelUrl: `${currentFullUrl}?stripe_result=cancel`,
          sku: selectedSku,
          noCountdown,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      dispatch(removeLoadingFlag('add-tokens'));
      if (response.data === 'subscription not bought') {
        showSnackbar('Subscription required to purchase tokens', 'error');
      } else if (response.data === 'subscription not active') {
        showSnackbar('Please activate your subscription to purchase tokens', 'error');
      } else {
        // Open Stripe checkout in a new window
        const stripeWindow = window.open(response.data, '_blank', 'width=600,height=800');
        if (stripeWindow) {
          // Window opened successfully
          showSnackbar('Stripe checkout opened in a new window', 'success');

          // Set up an interval to check if the window has been closed or redirected
          const checkWindow = setInterval(() => {
            try {
              if (stripeWindow.closed) {
                clearInterval(checkWindow);
                showSnackbar('Stripe checkout window closed', 'info');
              } else if (stripeWindow.location.href.includes(currentFullUrl)) {
                clearInterval(checkWindow);
                const url = new URL(stripeWindow.location.href);
                const stripeResult = url.searchParams.get('stripe_result');
                if (stripeResult === 'success') {
                  showSnackbar('Payment completed successfully!', 'success');
                  // Refresh the user's token balance or perform other necessary actions
                } else if (stripeResult === 'cancel') {
                  showSnackbar('Payment was cancelled', 'info');
                }
                stripeWindow.close();
              }
            } catch (e) {
              // An error will be thrown if we try to access stripeWindow.location.href
              // when the window is on a different origin (i.e., on the Stripe page)
              // We can safely ignore this error
            }
          }, 1000);

        } else {
          // Pop-up blocked
          showSnackbar('Please allow pop-ups to open the Stripe checkout', 'error');
        }
      }
    } catch (error) {
      console.error('Error in adding tokens:', error);
      dispatch(removeLoadingFlag('add-tokens'));
      showSnackbar('An error occurred while processing your request.', 'error');
    }
    handleClose();
  };

  const getTimeColor = (time) => {
    if (time <= 2 * 60 * 1000) return theme.palette.error.main; // Red for last 2 minutes
    if (time <= 4 * 60 * 1000) return theme.palette.warning.main; // Orange for last 4 minutes
    return theme.palette.success.main; // Green otherwise
  };

  const pulse = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `;

  const CountdownRenderer = ({ minutes, seconds, completed }) => {
    const timeLeft = minutes * 60 * 1000 + seconds * 1000;
    const color = getTimeColor(timeLeft);

    if (completed) {
      return <Typography variant="h6">You beat the clock!</Typography>;
    }

    return (
      <Typography
        variant="h6"
        component="span"
        sx={{
          color: color,
          animation: `${pulse} 1s ease-in-out infinite`,
          display: 'inline-block',
        }}
      >
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
          <Box display="flex" alignItems="center">
            <TokenIcon sx={{ mr: 1 }} />
            <Typography variant="h5">Keep The Inspiration Flowing!</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
            Don't let your journey with <strong>{currentProjectName || 'expanding your creative horizons'}</strong> stop. There is so much more to explore!
            <br />
            Purchase more AI tokens now and continue crafting amazing stories!
          </Typography>

          {/* Updated Urgency Message with Countdown Timer */}
          {!noCountdown && (
            <Box display="flex" alignItems="center" sx={{ mb: 3, ml: 2 }}>
              <AccessTimeIcon color="error" sx={{ mr: 1 }} />
            <Typography variant="h6" component="span">
              GET AN EXTRA 5% OFF! Offer expires in:{' '}
              <Countdown date={offerDeadline} renderer={CountdownRenderer} />
              </Typography>
            </Box>
          )}

          <Grid container spacing={2}>
            {tokenPackages.map((pkg) => (
              <Grid item xs={12} sm={4} key={pkg.sku}>
                <Card
                  variant={selectedSku === pkg.sku ? 'outlined' : 'elevation'}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderColor:
                      selectedSku === pkg.sku ? theme.palette.primary.main : undefined,
                      borderWidth: selectedSku === pkg.sku ? 2 : undefined,
                      cursor: 'pointer',
                  }}
                  onClick={() => setSelectedSku(pkg.sku)}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" align="center">
                      {pkg.tokens.toLocaleString()} Tokens
                    </Typography>
                    <Box sx={{ position: 'relative', display: 'inline-block', width: '100%', textAlign: 'center' }}>
                      {!noCountdown && (
                      <Typography
                        variant="h4"
                        align="center"
                        sx={{
                          textDecoration: 'line-through',
                          color: theme.palette.error.main,
                          mb: 0,
                        }}
                      >
                          ${pkg.price}
                        </Typography>
                      )}
                      {noCountdown && (
                        <Typography
                        variant="h4"
                        align="center"
                        sx={{
                          mb: 0,
                          color: theme.palette.success.main,
                        }}
                      >
                        ${pkg.price}
                      </Typography>
                      )}
                    </Box>
                    {!noCountdown && (
                    <Typography variant="h4" align="center" sx={{ color: theme.palette.success.main }}>
                        ${calculateDiscountedPrice(pkg.price)}
                      </Typography>
                    )}
                    {pkg.bonus && (
                      <Typography variant="body2" color="success.main" align="center">
                        +{pkg.bonus}% Bonus Tokens!
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      variant={selectedSku === pkg.sku ? 'contained' : 'outlined'}
                      color="primary"
                      fullWidth
                      onClick={() => setSelectedSku(pkg.sku)}
                    >
                      {selectedSku === pkg.sku ? 'Selected' : 'Select'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" sx={{ mt: 2, ml: 2 }}>
            Tokens never expire, and can be used for any project.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined">
            Maybe Later
          </Button>
          <Button onClick={handlePurchase} variant="contained" color="secondary">
            Purchase Now
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AutoFillTokensModal;
