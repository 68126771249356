import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  TextField,
  Typography,
  Tooltip,
  Container,
  CardMedia,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants';
import Loader from '../../components/Loader';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, setTokens } from '../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';
import TabChangeModal from '../../components/modals/TabChangeModal';
import StopIcon from '@mui/icons-material/Stop';
import ArrowLeft from '@mui/icons-material/ArrowCircleLeft';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';
import AddSceneImages from '../../components/modals/AddSceneImages/AddSceneImages';
import WestIcon from '@mui/icons-material/West';
import { useTheme } from '@mui/material/styles';
import InstantStoryboard from '../../components/modals/InstantStoryboard';

const fieldContainerStyles = {
	display: 'flex',
  gap: '30px',
  width: '100%'
};

export default function ProjectInfo({
  projectId,
  doesCardHaveUnsavedData, 
  handleProjectHeaderVisibility, 
  currentProject, 
  //setTokensFillModal
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const [project, setProject] = useState()
  const [imageFile, setImageFile] = useState();
	const [imageInput, setImageInput] = useState();
	const [titleInput, setTitleInput] = useState();
  const [type, setType] = useState('fiction');
  const [quickstartSummary,setQuickstartSummary] = useState();
  const [tagline, setTagline] = useState();
  const [quickstartSummaryAdjust, setQuickstartSummaryAdjust] = useState();
  const [rules, setRules] = useState();
	const [wasImageChanged, setWasImageChanged] = useState(false);
  //const doesCardHaveUnsavedData = useRef(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPageModal, setOpenPageModal] = useState(false)
  const currentSocket = useRef()
  const formRef = useRef()
  const currentWebSocketObject = useRef();
	const currentGenerationSetFunc = useRef()
	const [currentGenerationFieldKey,setCurrentGenerationFieldKey] = useState()
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
  const [generationLoading, setGenerationLoading] = useState(false);
  const [tokensFillModal, setTokensFillModal] = useState(false);
  //const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
  const theme = useTheme();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openStoryboardModal, setOpenStoryboardModal] = useState(false);
  const [oneSceneImageGen, setOneSceneImageGen] = useState(0);
  const [storyBuild, setStoryBuild] = useState(0);
  const [projectState, setProjectState] = useState({
    title: 0,
    tagline: 0,
    quickstartSummary: 0,
  });
  const [titleError, setTitleError] = useState(false);
  const [taglineError, setTaglineError] = useState(false);
  const [quickstartSummaryError, setQuickstartSummaryError] = useState(false);
  const [quickstartSummaryAdjustError, setQuickstartSummaryAdjustError] = useState(false);
  const [rulesError, setRulesError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const handleTypeChange = (event) => {
    setType(event.target.value); // Update the type state based on the selected value
  };

  useEffect(() => {
    console.log('projectId', projectId)
    console.log('Selected Type:', type); // This should log the updated type whenever it changes
  }, [type]);

  useEffect(()=>{
    console.log('projectId', projectId)
    getProject()
    console.log('Selected Type:', type);
  },[projectId]) 

  function checkIfCardHasUnsavedData () {
		if (!project) return

		let isEqualFlag = true

		if (titleInput !== project.title) isEqualFlag = false;
		if (quickstartSummary !== project.quickstartSummary) isEqualFlag = false;
		if (tagline !== project.tagline) isEqualFlag = false;
		if (quickstartSummaryAdjust !== project.quickstartSummaryAdjust) isEqualFlag = false;
		if (rules !== project.rules) isEqualFlag = false;
		if (imageFile || imageInput) isEqualFlag = false;

    // console.log(isEqualFlag)
		if (isEqualFlag) {
			doesCardHaveUnsavedData.current = false
		} else {
			doesCardHaveUnsavedData.current = true
		}
	}

	checkIfCardHasUnsavedData()

  useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		
		};
	 }, []); 

	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				// console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				// console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				// console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					// console.log('clientID set')
					currentSocket.current = data.clientId
          currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					// console.log('got new images')
					currentGenerationSetFunc.current(prev => prev + data.content)
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
          setGenerationLoading(false)
          currentGenerationSetFunc.current('')
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					/* alert.show(`Text was successfully generated`, {
						type: 'success',
					}); */
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
          getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				// console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				setCurrentGenerationFieldKey('');
				// Handle WebSocket connection closure
			};

		});
	}

  const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

  const getProject = () => {
    axios
    .get(constants.BASE_URL + 'api/projects/' + projectId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async function (response) {
      setProject(response.data)
      setTitleInput(response.data.title)
      setType(response.data.type || type)
      setQuickstartSummary(response.data.quickstartSummary)
      setTagline(response.data.tagline)
      setRules(response.data.rules)
      setQuickstartSummaryAdjust(response.data.quickstartSummaryAdjust)
      // console.log(response.data)
    })
    .catch(function (error) {
      if (error.response?.data === 'User Does Not Exist') {
        navigate('login');
        dispatch(logout());
      }
      if (error.response?.status === 404) {
        navigate('gallery');
      }
    });
  }

  const handleGenerateTitle = async (fieldToFillKey, setFieldFunc, adjustFlag) => {
    if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
    
    async function generationFunction () {
      const fields = {
        title: titleInput,
        quickstartSummary,
        }

      const prevValue = fields[fieldToFillKey]

      delete fields[fieldToFillKey]

      let isAnotherFieldsNotEmpty = true

      for (const [key, value] of Object.entries(fields)) {
        if (typeof value !== 'undefined' && value.trim() !== '') {
          isAnotherFieldsNotEmpty = false
        } else {
          delete fields[key]
        }
      }

      if (isAnotherFieldsNotEmpty) {
        alert.show(`To generate this field you should fill ${Object.keys(fields)[0]} field`, {
          type: 'error',
        });
      } else {
        setIsGenerationButtonAllowed(false)
        setGenerationLoading(true)
        const data = {
          fieldToFillKey,
          supportFieldsObj : fields,
          clientId: currentSocket.current
        }
        if (prevValue) {
          data.prevValue = prevValue
        }
        if (adjustFlag) {
          data.quickstartSummaryAdjust = quickstartSummaryAdjust
        }
        // console.log(data)
        currentGenerationSetFunc.current = setFieldFunc;
			  setCurrentGenerationFieldKey(fieldToFillKey);
        axios
        .post(
          constants.BASE_URL + 'api/ai/generate-project-title',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // setFieldFunc(response.data)
          // dispatch(removeLoadingFlag('generate-field'));
          // // console.log(response.data);
          // alert.show(
          //   `Text was successfully generated`,
          //   { type: 'success' }
          // );
        })
        .catch(function (error) {
          if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
            if (localStorage.getItem('role')=== 'Enterprise User') {
              alert.show(
                `Problem with the subscription. Please notify your Enterprise Admin.`,
                { type: 'error' }
              );
            } else {
              alert.show(
                `Problem with subscription. Please check the Account Info page.`,
                { type: 'error' }
              );
            }
						
          } else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation.`,
							{ type: 'error' }
						);
						// console.log(error);
					}
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
        });
      }
    }
  }

  const handleGenerateQuickstartSummary = async (fieldToFillKey, setFieldFunc, adjustFlag) => {
    if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
    
    async function generationFunction () {
      const fields = {
        title: titleInput,
        quickstartSummary,
        }

      const prevValue = fields[fieldToFillKey]

      delete fields[fieldToFillKey]

      let isAnotherFieldsNotEmpty = true

      for (const [key, value] of Object.entries(fields)) {
        if (typeof value !== 'undefined' && value.trim() !== '') {
          isAnotherFieldsNotEmpty = false
        } else {
          delete fields[key]
        }
      }

      if (isAnotherFieldsNotEmpty) {
        alert.show(`To generate this field you should fill ${Object.keys(fields)[0]} field`, {
          type: 'error',
        });
      } else {
        setIsGenerationButtonAllowed(false)
        setGenerationLoading(true)
        const data = {
          type,
          fieldToFillKey,
          supportFieldsObj : fields,
          clientId: currentSocket.current,
          projectRules: rules
        }
        if (prevValue) {
          data.prevValue = prevValue
        }
        if (adjustFlag) {
          data.quickstartSummaryAdjust = quickstartSummaryAdjust
        }
        // console.log(data)
        currentGenerationSetFunc.current = setFieldFunc;
			  setCurrentGenerationFieldKey(fieldToFillKey);
        axios
        .post(
          constants.BASE_URL + 'api/ai/generate-project-quickstartSummary',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // setFieldFunc(response.data)
          // dispatch(removeLoadingFlag('generate-field'));
          // // console.log(response.data);
          // alert.show(
          //   `Text was successfully generated`,
          //   { type: 'success' }
          // );
        })
        .catch(function (error) {
          if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
            if (localStorage.getItem('role')=== 'Enterprise User') {
              alert.show(
                `Problem with the subscription. Please notify your Enterprise Admin.`,
                { type: 'error' }
              );
            } else {
              alert.show(
                `Problem with subscription. Please check the Account Info page.`,
                { type: 'error' }
              );
            }	
          } else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation`,
							{ type: 'error' }
						);
						// console.log(error);
					}
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
        });
      }
    }
  }
  const handleGenerateTagValue = async () => {
    if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
    
    async function generationFunction () {
      const data = {
        clientId: currentSocket.current
      }

      if (quickstartSummary?.length !== 0) data.quickstartSummary = quickstartSummary
      if (titleInput?.length !== 0) data.title = titleInput

      // console.log(data)

      if (quickstartSummary?.length === 0 || titleInput?.length === 0) {
        alert.show(`To generate this field you should fill at least one of the other fields`, {
          type: 'error',
        });
      } else {
        setIsGenerationButtonAllowed(false)
        currentGenerationSetFunc.current = setTagline;
			  setCurrentGenerationFieldKey('tagline');
        setGenerationLoading(true)
        axios
        .post(
          constants.BASE_URL + 'api/ai/generate-project-tagline',
          {data},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // setTagline(response.data)
          // dispatch(removeLoadingFlag('generate-field'));
          // alert.show(
          //   `Text was successfully generated`,
          //   { type: 'success' }
          // );
        })
        .catch(function (error) {
          if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					} else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
            if (localStorage.getItem('role')=== 'Enterprise User') {
              alert.show(
                `Problem with the subscription. Please notify your Enterprise Admin.`,
                { type: 'error' }
              );
            } else {
              alert.show(
                `Problem with subscription. Please check the Account Info page.`,
                { type: 'error' }
              );
            }	
          } else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation`,
							{ type: 'error' }
						);
						// console.log(error);
					}
          setGenerationLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
        });
      }
    }
  }
  const handleGenerateQuickstart = () => {
    if (!loading && !generationLoading) {
      dispatch(addLoadingFlag('generate-quickstart'));
      setGenerationLoading(true);
      axios
        .post(
          constants.BASE_URL + 'api/projects/generate-quickstart',
          {text: quickstartSummary, title: titleInput, project_id: projectId, type},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          getUserTokens()
          dispatch(removeLoadingFlag('generate-quickstart'));
          setGenerationLoading(false);

          // console.log(response.data)
          if (type === 'non-fiction') {
            navigate('/project/' + projectId + '?page=story');
          } else if (type === 'fiction') {
            navigate('/project/' + projectId + '?page=characters');
          }       
         })
         .catch(function (error) {
          // Remove both loading states on error
          dispatch(removeLoadingFlag('generate-quickstart'));
          setGenerationLoading(false);
          
          if (error.response?.data === 'Text incorrect for quickstart.') {
            setSnackbarMessage('Please make sure that the text in the Quickstart field contains characters, settings, and a summary. You can use the generate button to format your story correctly.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          } else if (error.response?.data === 'error with openAi') {
            setSnackbarMessage('An error occurred with the AI server');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          } else if (error.response?.data === 'Not enough tokens') {
            if (localStorage.getItem('role') === 'Enterprise User') {
              setSnackbarMessage('Not enough AI tokens. Please notify your Enterprise Admin.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            } else {
              setSnackbarMessage('You don\'t have enough tokens for this action.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setTokensFillModal(true);
            }		
          } else {
            setSnackbarMessage('Something went wrong with text generation');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        });
    }
    }
    
  const handleFileChange = (event) => {
		// console.log(event);
		const file = event.target.files[0];
		// console.log(file);
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				// console.log('error');
				setImageFile('');
				setImageInput('');
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				alert.show(`Image was successfully uploaded`, {
					type: 'success',
				});
			}
		}
	};

  const handleSaveChanges = async (e) => {
    if (loading || generationLoading) {
      return
    }

		// e.preventDefault();
		const data =  new FormData(e)
    data.append('type', type);
		if (data.get('image')?.size === 0) data.delete('image');
		if (
			!wasImageChanged ||
			constants.IMAGE_URL + project?.image === data.get('imageURL')
		) {
      data.delete('imageURL');
    }
		
      
		if (data.get('title')) {
			if (await checkImageSize(imageInput)) {
				//dispatch(addLoadingFlag('change-projects'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');
					try {
						const options = {
							maxSizeMB: 0.90
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image',compressedFile)
						await request()

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request()
				}
				
			}
		}
		async function request () {
			await axios
					.put(
						constants.BASE_URL + 'api/projects/' + projectId,
						data,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(async function (response) {
						alert.show(
							`Project '${
								titleInput 
							}' was successfully saved!`,
							{ type: 'success' }
						);
            getProject()
						dispatch(removeLoadingFlag('change-projects'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('change-projects'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					
							alert.show(
								`Something went wrong, could not add illustrations.`,
								{ type: 'error' }
							);
						
					});
		}
	};
  
  useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		constants.BASE_URL + 'api/fees/get-fees',
		{
			headers: {
			Authorization: `Bearer ${token}`,
			},
		}
		).then(function (response) {
		dispatch(removeLoadingFlag('get-fees'));
		if(response.data.hasOwnProperty('project')){
			setProjectState(response.data.project)
		}
    if(response.data.hasOwnProperty('storyBuild')){
      setStoryBuild(response.data.storyBuild)
    }
    if(response.data.hasOwnProperty('oneSceneImageGen')){
      setOneSceneImageGen(response.data.oneSceneImageGen)
    }
		}).catch(function (error) {
		if (error.response.data === 'Permission denied') {
			navigate('/#');
		}
		// console.log(error);
		dispatch(removeLoadingFlag('get-fees'));
		});
	},[alert,dispatch,token])

	function sendMessageToServer(message) {
		// console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			// console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
	}


  const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};

  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    setTitleInput(newValue);
    if (newValue.length >= 100) {
      setTitleError(true);
      setSnackbarMessage('Maximum character limit reached for this field.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setTitleError(false);
    }
  };

  const handleTaglineChange = (e) => {
    const newValue = e.target.value;
    setTagline(newValue);
    if (newValue.length >= 100) {
      setTaglineError(true);
      setSnackbarMessage('Maximum character limit reached for this field.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setTaglineError(false);
    }
  };

  const handleQuickstartSummaryChange = (e) => {
    const newValue = e.target.value;
    setQuickstartSummary(newValue);
    if (newValue.length >= 8000) {
      setQuickstartSummaryError(true);
      setSnackbarMessage('Maximum character limit reached for this field.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setQuickstartSummaryError(false);
    }
  };

  const handleQuickstartSummaryAdjustChange = (e) => {
    const newValue = e.target.value;
    setQuickstartSummaryAdjust(newValue);
    if (newValue.length >= 5000) {
      setQuickstartSummaryAdjustError(true);
      setSnackbarMessage('Maximum character limit reached for this field.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setQuickstartSummaryAdjustError(false);
    }
  };

  const handleRulesChange = (e) => {
    const newValue = e.target.value;
    setRules(newValue);
    if (newValue.length >= 5000) {
      setRulesError(true);
      setSnackbarMessage('Maximum character limit reached for this field.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setRulesError(false);
    }
  };

    if (loading) {
      return <Loader />
    }
    return (
        <Container sx={(theme) => ({backgroundColor: theme.palette.background.default})} component="main" maxWidth='lg'>
          <CssBaseline />
          <Dialog
            open={openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            align='center' 
          >
            <DialogTitle id="alert-dialog-title">{"Are You Ready To Build Your Project?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Happy with your Quickstart AI text? If you want to change something, you can manually edit it directly, or you can give the AI some comments and click generate one more time. Clicking "Build It" will save the page and use the text to build your project. 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={() => setOpenConfirmationModal(false)} color="primary">
                Cancel
              </Button>
              <Button
                variant='contained'
                onClick={async () => {
                  setOpenConfirmationModal(false);
                  if (doesCardHaveUnsavedData.current) {
                    await handleSaveChanges(formRef.current); 
                    handleGenerateQuickstart();
                  } else {
                    handleGenerateQuickstart();
                  }
                }}
                color="primary"
                autoFocus
              >
                Build It
              </Button>
            </DialogActions>
          </Dialog>
          <InstantStoryboard
            open={openStoryboardModal}
            handleClose={() => setOpenStoryboardModal(false)}
            text={quickstartSummary}
            projectId={projectId}
            clientId={currentSocket.current}
          />
          <TabChangeModal submitText={'SAVE AND BUILD'} open={openModal} setOpen={setOpenModal} onSubmit={async() => {
              await handleSaveChanges(formRef.current)
              handleGenerateQuickstart()
          }}/>
          <AutoFillTokensModal open={tokensFillModal} setOpen={setTokensFillModal}/>
          <TabChangeModal open={openPageModal} setOpen={setOpenPageModal} onSubmit={() => navigate('/project/' + projectId + '?page=characters&episodes=0')}/>
          {!loading && <>
            
              <Box
              ref={formRef}
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.default,
                  marginTop: {
                    xs: 0,
                    md: 0,
                  },
                  marginBottom: 0,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                })}
                component='form' submit={()=> console.log('submit not active')}noValidate
              >
              <Box mt={2} component="span" display="flex" flexDirection="column" justifyContent="center" alignItems="center" border={2} p={2} sx={{ borderRadius: '40px' }}>
              <Typography mr={0} fontSize={26} fontWeight={500} align='center'> Welcome To Your New Project! </Typography> 
              <Typography ml={4} mr={4} mt={2} fontSize={18} fontWeight={300} align='center'> Using our Quickstart below, you'll be able to start creating an entire story, script, book, or whatever else you can dream up using only a few sentences. Next to each box you will find a 🤖 button. This causes the AI to generate that item. The more fields of info that you fill out on any page, the better the AI's generation will become.  </Typography>
              </Box>
              {project && 
                <Box
                onClick={() => {
                  if (doesCardHaveUnsavedData.current) {
                    setOpenPageModal(true)
                  } else {
                    navigate('/project/' + projectId + '?page=characters&episodes=0')
                  }
                }}
                  sx={{
                    position: 'relative',
                    height: 400,
                    width: 300,
                    background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}
                >
                  <CardMedia
                    component='div'
                    sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 2,
                    }}
                    image={constants.IMAGE_URL + project.image}
                    alt={'Project avatar'}
                    onClick={() => {
                      if (doesCardHaveUnsavedData.current) {
                        setOpenPageModal(true)
                      } else {
                        navigate('/project/' + projectId + '?page=characters&episodes=0')
                      }
                    }}
                  />
                  
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '40%',
                      //left: '35%',
                      left: '0',
                      right: '0',
                      textAlign: 'center',
                      //color: 'black',
                      fontSize: '40px',
                      textTransform: 'uppercase',
                      color: 'white',
                      zIndex: 1,
                    }}
                  >
                     	{project?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
                  </Typography>
                </Box>}
              {project && (<Box sx={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}>
                   <Button
              onClick={() => {
                navigate('/project/' + projectId + '?page=characters&episodes=0');
              }}
              sx={{
                color: 'white',
                borderRadius: '8px',
                height: '30px',
                marginTop: '-20px',
                marginBottom: '-10px',
                textDecoration: 'underline',
              }}
            >
              <ArrowLeft fontSize='large' color='primary'/> {'Back to Project Page'}
            </Button>
            <Box sx={fieldContainerStyles}> 
              <TextField
              margin='normal'
              required
              fullWidth
              id='title'
              label='Project Title'
              name='title'
              InputLabelProps={{ shrink: true }}
              value={titleInput}
              onChange={handleTitleChange}
              disabled={currentGenerationFieldKey === 'title' ? true : false}
              inputProps={{ maxLength: 100 }}
              error={titleError}
            />
            <Tooltip arrow title={`AI Tokens: ${projectState.title}`}>
            <Button
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateTitle('title',setTitleInput)}
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'title') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'title') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'title') ? <StopIcon /> : ('🤖'))}
							</Button>
              </Tooltip>
            </Box>
             
            <Box sx={fieldContainerStyles}>
							
              <TextField
              margin='normal'
              fullWidth
              id='tagline'
              label='Project Tagline'
              placeholder='A catchy one-liner for your project!'
              name='tagline'
              disabled={currentGenerationFieldKey === 'tagline' ? true : false}
              InputLabelProps={{ shrink: true }}
              value={tagline}
              onChange={handleTaglineChange}
              inputProps={{ maxLength: 100 }}
              error={taglineError}
            />
            <Tooltip arrow title={`AI Tokens: ${projectState.tagline}`}>
            <Button
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateTagValue()}
								variant='contained'
								component='label'
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'tagline') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'tagline') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'tagline') ? <StopIcon /> : ('🤖'))}
							</Button>
              </Tooltip>
            </Box>
            
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <TextField
                margin='normal'
                fullWidth
                name='imageURL'
                label='Enter image link'
                id='image'
                disabled={currentGenerationFieldKey === 'imageUrl' ? true : false}
                helperText='To delete image type: default'
                InputLabelProps={{ shrink: true }}
                value={imageInput}
                onChange={(e) => {
                  setImageInput(e.target.value);
                  setImageFile('');
                  setWasImageChanged(true);
                }}
              />
              <Typography sx={{ m: 0, p: 2 }}>OR</Typography>
              <Button
                variant='contained'
                component='label'
                sx={{
                  height: '56px',
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
                disabled={!isGenerationButtonAllowed}
              >
                Upload
                <input
                  hidden
                  value={imageFile}
                  name='image'
                  accept='image/*'
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                  type='file'
                />
              </Button>
            </div>
            <Box component="span" display="flex" flexDirection="column" justifyContent="center" alignItems="center" backgroundColor="" border={2} p={2} sx={{borderRadius: '40px' }}>
              <Typography mt={0} mr={0} fontSize={26} fontWeight={500} align='center'>PROJECT QUICKSTART: </Typography> 
              <Typography ml={4} mr={4} mt={2} fontSize={18} fontWeight={300} align='center'> Type your ideas about your story below and then click generate 🤖. Quickstart creates a list of characters & locations for you, and a summary of your story. You can make adjustments to the text directly or using the "AI Adjust Box" below. After making your adjustments, click the 🤖 again. Once satisfied, click "Build Your Project..." and our AI will build your story database. It only takes a few sentences to get started! </Typography>
              </Box>
         {/* Dropdown for Fiction or Non-Fiction */}
         <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ width: '225px' }} margin="normal">
              <InputLabel id="type-select-label">Select Project Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Select Project Type"
                onChange={handleTypeChange}  // Handle the change of type
              >
                <MenuItem value="fiction">Fiction</MenuItem>
                <MenuItem value="non-fiction">Non-Fiction</MenuItem>
              </Select>
            </FormControl>
          </Box>
            <Box sx={fieldContainerStyles}>
            <TextField
              margin='normal'
              fullWidth
              multiline
              minRows={4}
              maxRows={12}
              InputLabelProps={{ shrink: true }}
              name='quickstartSummary'
              disabled={currentGenerationFieldKey === 'quickstartSummary' ? true : false}
              label="QUICKSTART AI"
              placeholder="Our AI will take whatever you write here and use it to build out your project for you. Write whatever comes to mind about your story here. Who are the main characters, the heroes, the villains? What are they doing or what are they pursuing? What are the major plot points of the story? What themes are at work? What genre is it? What other movies, books, TV shows, etc is it like?"
              id='quickstartSummary'
              value={quickstartSummary}
              onChange={handleQuickstartSummaryChange}
              inputProps={{ maxLength: 8000 }}
              error={quickstartSummaryError}
            />
            </Box>
            
            <Box sx={{my: '-10px', display: 'flex', alignItems: 'center', gap: '10px'}}>
              <Tooltip arrow title={`AI Tokens: ${projectState.quickstartSummary}`}>
              <Button
								// onClick={() => handleFillFieldWithAI('skills')}
								variant='contained'
								component='label'
                onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleGenerateQuickstartSummary('quickstartSummary',setQuickstartSummary, true)}
								sx={{
									height: '56px',
									marginTop: '16px',
									marginBottom: '8px',
									fontSize: '36px',
								}}
								disabled={!isGenerationButtonAllowed ? !(!generationLoading && currentGenerationFieldKey === 'quickstartSummary') : false}
								>
									{(generationLoading && currentGenerationFieldKey === 'quickstartSummary') ? (<CircularProgress size={25}/>) : ((!generationLoading && currentGenerationFieldKey === 'quickstartSummary') ? <StopIcon /> : ('🤖'))}
							</Button>
              </Tooltip>
              
              <WestIcon sx={{ verticalAlign: 'middle' }} />
              <Typography fontWeight={300} mr='5px'>Click here to generate. You can make adjustments below and then click the same 🤖 again.</Typography>
            </Box>
              <Box sx={fieldContainerStyles}> 
                <TextField
                  margin='normal'
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={12}
                  InputLabelProps={{ shrink: true }}
                  name='quickstartSummaryAdjust'
                  label='How should the 🤖 AI adjust your quickstart?'
                  placeholder='Tell the AI it needs to include a specific character or setting. Tell it you want something different to happen during the story, or you want it to take place during a different time or place.'
                  id='quickstartSummaryAdjust'
                  value={quickstartSummaryAdjust}
                  onChange={handleQuickstartSummaryAdjustChange}
                  inputProps={{ maxLength: 5000 }}
                  error={quickstartSummaryAdjustError}
                /> 
                </Box>

                <Box sx={{width: '100%'}}>
                  <Tooltip arrow title={`AI Tokens: ${oneSceneImageGen} per Image`}>
                  <Button
                    variant='contained'
                    component='label'
                    fullWidth
                    disabled={!isGenerationButtonAllowed}
                    onClick={() => {
                      handleSaveChanges(formRef.current)
                      setOpenStoryboardModal(true)}}
                    sx={{ mt: 1, mb: 2, textAlign: 'center',color: '#fff' }}
                  >
                    Visualize your Quickstart with an Instant Storyboard
                  </Button>
                  </Tooltip>
                  <Tooltip arrow title={type === 'non-fiction' ? 'AI Tokens: 0' : `AI Tokens: ${storyBuild}`}>                  <Button
                    variant='contained'
                    component='label'
                    fullWidth
                    disabled={!isGenerationButtonAllowed}
                    onClick={() => setOpenConfirmationModal(true)}
                    sx={{ mt: 1, mb: 2, color: '#fff' }}
                  >
                    Build Your Project with Quickstart AI
                  </Button>
                  </Tooltip>
                  <Button
                  fullWidth
                  onClick={() => handleSaveChanges(formRef.current)}
                  variant='contained'
                  sx={{ mt: 1, color: '#fff' }}
                  disabled={!isGenerationButtonAllowed}
                >
                  Save
                </Button>
              </Box>
               
              <Box sx={fieldContainerStyles}> 
              <TextField
              margin='normal'
              fullWidth
              multiline
              minRows={4}
              maxRows={12}
              InputLabelProps={{ shrink: true }}
              name='rules'
              label="What are the RULES of your project?"
              placeholder="*ADVANCED EXPERT SETTING* Ignore this until later, as it can exercise fine control over everything that the AI generates for you. These are rules or styles the AI will keep in mind whenever it is generating content for this project. Do you need your Openings handled a specific way? Do you want the writing style to mimic a specific writing style? Set things like that here."
              id='rules'
              value={rules}
              inputProps={{ maxLength: 5000 }}
              onChange={handleRulesChange}
              disabled={currentGenerationFieldKey === 'rules' ? true : false}
              error={rulesError}
              /> 
              </Box> 
           
            </Box>)}
             
            </Box>
          </>}
          <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={6000} 
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert 
              onClose={() => setSnackbarOpen(false)} 
              severity={snackbarSeverity} 
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
      </Container>
  );
};

