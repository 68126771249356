import React, { useCallback, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import GalleryProjectCard from '../../components/GalleryProjectCard';
import GalleryProjectCardAddNew from '../../components/GalleryProjectCardAddNew';
import plus from '../../assets/images/plus.png'
import { useSelector , useDispatch } from 'react-redux';
import CreateNewProject from '../../components/modals/CreateNewProject'
import { overrideProjects } from '../../store/slices/projectsSlice';
import { SortableContainer } from 'react-sortable-hoc';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import { addLoadingFlag,removeLoadingFlag } from '../../store/slices/loadingSlice';
import { logout } from '../../store/slices/authSlice';
import { useTheme } from '@mui/material/styles';
import StartGuideModal from '../../components/modals/StartGuideModal';

const Projects = () => {
  const [newProjectCreateOpen, setNewProjectCreateOpen] = useState(false);
  const navigate = useNavigate();
  // const projects = useSelector((state) => state.projects.projects);
  const [projects, setProjects] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const theme = useTheme();

  const handleClick = (id) => {
    navigate('/project/' + id + '?page=landing-page')
  };

  function handleDragEnd ({ oldIndex, newIndex }) {
    const reorderedItems = [...projects];
    const [removed] = reorderedItems.splice(oldIndex, 1);
    reorderedItems.splice(newIndex, 0, removed);
    setProjects(reorderedItems.map((project,index) => {
      return {
        ...project,
        order: index
      }
    }))
    changeProjectsOrderInDataBase(reorderedItems.map((project,index) => {
      return {
        id: project._id,
        order: index
      }
    }))
  }

  const changeProjectsOrderInDataBase = async (projects) => {
    await axios.post(constants.BASE_URL + 'api/projects/update-projects-order' , {
      projects,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    } )
    .then(function (response) {

    })
    .catch(function (error) {
      if (error.response?.data === 'User does not exist.') {
        navigate('login')
        dispatch(logout())
      } 
    });
  }

  const getAllProjects = useCallback(() => {
    dispatch(addLoadingFlag('get-projects'))
    axios.get(constants.BASE_URL + 'api/projects', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(function (response) {
      const sortedData = response?.data?.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      setProjects(sortedData)
      dispatch(overrideProjects(response.data));
      dispatch(removeLoadingFlag('get-projects'))
    })
    .catch(function (error) {
      if (error.response?.data === 'User does not exist.') {
        navigate('login')
        dispatch(logout())
      }
      dispatch(removeLoadingFlag('get-projects'))
    });
  },[dispatch,token,navigate])

  useEffect(()=> {
    getAllProjects()
  },[getAllProjects])

  const SortableGrid = SortableContainer(({ items }) => (
      <Box
        sx={{
          marginTop: {
            xs: 10,
            md: 8,
          },
          marginBottom: 4,
          display: {
            xs: 'flex',
            md: 'grid',
          },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: {
            md: 'space-between',
            xs: 'space-around',
          },
          flexWrap: {
            xs: 'wrap',
          },
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '30px 0'
        }}
      >
        <GalleryProjectCardAddNew handleClick={() => setNewProjectCreateOpen(true)} image={plus} title={'New Project'} />
          {items.map((project,index) => <GalleryProjectCard key={index} index={index} projectId={project._id} handleClick={() => {window.scrollTo(0, 0); handleClick(project._id)}} project={project} image={project.image} title={project.title} getAllProjects={getAllProjects}/>)}
       </Box>
  ));
    if (loading) {
      return <Loader />
    }
    return (
        <Container sx={(theme) => ({
          backgroundColor: theme.palette.background.default, py:'20px'})} component="main">
          <StartGuideModal />
          <CssBaseline />
          {!loading && <>
          <SortableGrid
            items={projects}
            onSortEnd={handleDragEnd}
            pressDelay={200} // Wait 200ms before dragging
            axis="xy" // Enable both X and Y axis sorting
          />
          <CreateNewProject open={newProjectCreateOpen} setOpen={setNewProjectCreateOpen} getAllProjects={getAllProjects} projectsLength={projects.length}/>
          </>}
      </Container>
  );
};

export default Projects;

