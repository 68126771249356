import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Container, Card, CardContent, CardMedia, Box, Typography, Grid, Button, Toolbar, Modal, IconButton, Menu, MenuItem, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.css';
import { styled } from '@mui/system';
import logoLong from '../../assets/images/logo-long.png';
import Logo from '../../assets/images/logo.png';
import * as constants from '../../helpers/constants';
import StoryGenIcon from '../../assets/images/home/storygenicon.webp';
import StoryIcon from '../../assets/images/home/storyicon.webp';
import CharacterIcon from '../../assets/images/home/charactericon.webp';
import SettingIcon from '../../assets/images/home/settingicon.webp';
import QuickIcon from '../../assets/images/home/quickicon.webp';
import QuickIcon2 from '../../assets/images/home/quickicon2.webp';
import GenreIcon from '../../assets/images/home/genreicon.webp';
import ChatIcon from '../../assets/images/home/chaticon.webp';
import ImageIcon from '../../assets/images/home/imageicon.webp';
import AnalysisIcon from '../../assets/images/home/analysisicon.webp';
import CoverageIcon from '../../assets/images/home/coverageicon.webp';
import StoryboardIcon from '../../assets/images/home/storyboardicon.webp';
//import StorybuiltBillions from '../../assets/images/home/Storybuilt-Billions.mp4';
import StorybuiltHowEasy from '../../assets/images/home/Storybuilt-How-Easy.mp4';
import HowEasy from '../../assets/images/home/How-Easy-Is-It.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuIcon from '@mui/icons-material/Menu';
import BoltIcon from '@mui/icons-material/Bolt';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import QuickstartVideo from '../../assets/images/home/quickstart.mp4';
import ImageGenVideo from '../../assets/images/home/imagegenmatilda.mp4';
import ChatMatildaVideo from '../../assets/images/home/chat-matilda.mp4';
import { Helmet } from 'react-helmet';

gsap.registerPlugin(ScrollTrigger);

const mediaCardWidth = 300;

const textStyle = {
  variant: 'h2',
  component: 'span',
  sx: {
    position: 'absolute',
    color: 'primary.main',
    left: {xs:'50%', md: 'null'},
    transform: {xs: 'translateX(-50%)', md: 'translateX(0)'},
    mt: { xs: 8, md: 0 },
    whiteSpace: 'nowrap',
  },
};

const FooterContainer = styled(Container)({
    borderTop: '1px solid',
    borderTopColor: constants.DARK_GREY,
    paddingTop: '50px', // add padding to make the border and content separate
    paddingBottom: '50px',
    marginTop: '20vh',
    });

const CustomLink = styled(MuiLink)({
    color: 'primary', // Replace with your theme's primary color
    '&:visited': {
      color: 'primary', // Replace with your desired visited link color
    },
  });

  const majorFeatures = [
    {
      title: 'Quick Launch',
      description: 'Our Quickstart AI allows you to take just a few sentences of an idea and transform it in seconds.',
      backgroundColor: '#90e7e8', 
      videoSrc: QuickstartVideo, 
    },
    {
      title: 'See Your Vision',
      description: 'Our image generation will bring your characters, settings... your imagination to life.',
      backgroundColor: '#9fe3ec',
      videoSrc: ImageGenVideo,
    },
    {
      title: 'Talk To Your Characters',
      description: 'From brainstorming to being able to interact with your characters, our advanced AI chatbot is super unique.',
      backgroundColor: '#9bece0', 
      videoSrc: ChatMatildaVideo,
    },
  ];

// Sample data for media cards
const featureCards = [
    { title: "Quickstart AI", description: "You give our AI your idea, and it turns it into a full summary, a list of characters, and a list of settings. It then builds out an entire story database for you and gets you producing quality work in no time.", image: QuickIcon },
    { title: "Story Database", description: "We break the storywriting process down into manageable pieces. Using our AI, you build out profiles of characters and settings, and then use these to drive your AI generations.", image: StoryIcon },
    { title: "Character Database", description: "Build a profile of your major and minor characters. Use the AI to write their backstory, their motivations, and to generate an image of them.", image: CharacterIcon },
    { title: "Setting Database", description: "When and where your story takes place can set it apart. Our AI will assist you as you build out your world. Then use the universe you've built to generate unique scenes.", image: SettingIcon },
    { title: "Story Generator", description: "Once you've built out your databases as much or as little as you want, you can then start generating stories inside the universe you have created.", image: StoryGenIcon },
    { title: "Genre / Structure", description: "The story generator can use your characters, settings, and other stories to now write new stories for your universe, quickly changing genres and story structure so that you can experiment with new ideas.", image: GenreIcon },
];

const mediaCards = [
    { title: "The Story Builder", description: "Our writing AI, coupled with our Quickstart AI, allows you to take just a few sentences of an idea, and transform it from concept to outline to full-fledged book or script in minutes.", image: QuickIcon2 },
    { title: "Genre & Story Structure", description: "We've worked with professional writers to build AI models that understand how specific genres or types of shows are structured and written. Turn your sci-fi action adventure into a procedural crime drama with just a few clicks.", image: GenreIcon },
    { title: "Brainstorm AI", description: "We've built a chatbot that has deep knowledge of your projects. It can answer questions you have about your story, it can make suggestions, and soon it wil be able to assume the role of your characters so that you can have conversations with them.", image: ChatIcon },
    { title: "Image Generation", description: "A full image generation suite allows you to generate images of your characters, locations, or scenes from your stories. Select from an ever-growing list of image styles, so you can find just the right aesthetic for your story.", image: ImageIcon },
    { title: "Storyboard Generator", description: "Using pieces from your story, our AI generates a sequence of images and text which you can use as storyboards or export as part of a presentation.", image: StoryboardIcon },
    { title: "Story Analysis", description: "Get instant feedback on your writing. We've built a specialized AI tool that can read what you have written, tell you what is working, what isn't, and how to improve it.", image: AnalysisIcon },
    { title: "Script Coverage", description: "Real AI script coverage. Our AI works thru the entire script, and returns an industry-standard coverage sheet. You can also generate a scene-by-scene or an executive summary.", image: CoverageIcon },
];

const pricingPlans = [
    { title: "Individual", price: ["$5/month", <br></br>, <Typography mt='-5px' display="block"> plus AI tokens </Typography>],  features: ["FREE 30 DAY TRIAL with Promo Code: LAUNCH", "Story and Image Generation", "Story Analysis and Script Coverage", "Comes with 2000 tokens. Additional tokens may be purchased."] },
    { title: "Team", price: ["$10/month", <br></br>, <Typography mt='-5px'>per seat</Typography>], features: ["50% off with Promo Code: LAUNCH", "Admin account with user management control", "Each seat comes with 5000 tokens", "Admin can purchase additional tokens if needed"] },
    //{ title: "Enterprise", price: "$60/month", features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"] }
];  

// Duplicate the media cards for the looping effect
const  cardLoop = [...mediaCards, ...mediaCards, ...mediaCards];

function Home() {
    useEffect(() => {
        document.querySelector('html').style.backgroundColor = 'black';
        document.body.style.backgroundColor = 'black';
      }, []); 
    // GSAP animation logic inside a useEffect hook
    useEffect(() => {
        // Select all spans inside the changing-words class
        const changingWords = document.querySelectorAll('.changing-words span');
        const lastIndex = changingWords.length - 1; // Find the index of the last word

        // Check if the viewport is xs or sm using a media query
        const isXsOrSmViewport = window.matchMedia('(max-width: 960px)').matches;
    
        // Create a timeline instance with repeating and initial delay options
        const timeline = gsap.timeline({ repeat: -1, delay: 1 });
    
        // Base durations for scrolling effects
        const scrollInDuration = 0.5; // Time for scrolling in
        const scrollOutDuration = 0.5; // Time for scrolling out
        const holdDuration = 0.5; // Time to hold the word before scrolling out
        const extendedHoldDuration = 2; // Time to hold the last word
        const totalDurationPerWord = scrollInDuration + holdDuration + scrollOutDuration;
    
        // Set the first word to be initially visible
        if (changingWords.length > 0) {
            gsap.set(changingWords[0], { x: 0, y: 0, opacity: 1 });
        }

        // Loop through each word to set up the animation
        changingWords.forEach((word, index) => {
            // Calculate the delay for each subsequent word based on the index
            const wordDelay = totalDurationPerWord * index;

            // Set initial positions for subsequent words
            if (index > 0) {
                gsap.set(word, isXsOrSmViewport ? { x: 35, opacity: 0 } : { y: -50, opacity: 0 });
            }

            // Determine the hold duration (regular for all except the last word)
            const currentHoldDuration = index === lastIndex ? extendedHoldDuration : holdDuration;

            // Add the animations to the timeline
            timeline
                .to(word, {
                    opacity: 1,
                    x: isXsOrSmViewport ? 0 : undefined,
                    y: !isXsOrSmViewport ? 0 : undefined,
                    duration: scrollInDuration,
                    ease: "power1.out"
                }, wordDelay) // Scroll in
                .to(word, {
                    opacity: 0,
                    x: isXsOrSmViewport ? -100 : undefined,
                    y: !isXsOrSmViewport ? 50 : undefined,
                    duration: scrollOutDuration,
                    ease: "power1.in",
                    delay: currentHoldDuration
                }); // Scroll out
        });
    }, []);

    // Ref for the media cards scrolling
    const scrollContainerRef = useRef(null);
    const scrollTimelineRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
    
        if (scrollContainer) {
            const cardWidth = mediaCardWidth; // Approximate width of one card
            const totalScrollWidth = scrollContainer.scrollWidth;
            const visibleWidth = scrollContainer.clientWidth;
            
            let scrollTimeout = null;
            let isUserScrolling = false;
        
            // Set up GSAP timeline for automatic scrolling
            const timeline = gsap.timeline({ repeat: -1, delay: 0.5 });
            scrollTimelineRef.current = timeline;
        
            // Define the scrolling animation
            timeline.to(scrollContainer, {
                scrollLeft: `+=${totalScrollWidth / 2}`,
                duration: 70, // Adjust to control speed
                ease: 'linear',
                onComplete: () => {
                    scrollContainer.scrollLeft = 0; // Start from the beginning
                    console.log("Animation cycle completed.");
                }
            });
        
            // Pause GSAP timeline
            const pauseAnimation = () => {
                if (scrollTimelineRef.current && !scrollTimelineRef.current.paused()) {
                    scrollTimelineRef.current.pause();
                    console.log("Animation paused.");
                }
            };
        
            // Resume GSAP timeline
            const startAnimation = () => {
                if (scrollTimelineRef.current && scrollTimelineRef.current.paused()) {
                    scrollTimelineRef.current.play();
                    console.log("Animation resumed.");
                }
            };
        
            // Handle manual scrolling and ensure it pauses the animation
            const handleManualScroll = (e) => {
                if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                    isUserScrolling = true;
                }
            
                if (isUserScrolling) {
                    if (scrollTimeout !== null) {
                        clearTimeout(scrollTimeout);
                    }
                
                    pauseAnimation();
                
                    scrollTimeout = setTimeout(() => {
                        startAnimation();
                        isUserScrolling = false;
                    }, 4000); // Adjust the delay for the restart period
                }
            };
        
            // Adjust scroll position for infinite scrolling
            const handleScroll = () => {
                const currentScrollLeft = scrollContainer.scrollLeft;
            
                // Wrap to the beginning when reaching the end
                if (currentScrollLeft >= totalScrollWidth - visibleWidth - cardWidth) {
                    scrollContainer.scrollLeft = cardWidth;
                }
                // Wrap to the end when reaching the beginning
                else if (currentScrollLeft <= 0) {
                    scrollContainer.scrollLeft = totalScrollWidth - visibleWidth - 2 * cardWidth;
                }
            };
        
            // Add event listeners for manual and wheel scrolling
            scrollContainer.addEventListener('wheel', handleManualScroll);
            scrollContainer.addEventListener('scroll', handleScroll);
        
            // Cleanup listeners
            return () => {
                scrollContainer.removeEventListener('wheel', handleManualScroll);
                scrollContainer.removeEventListener('scroll', handleScroll);
                if (scrollTimeout !== null) {
                    clearTimeout(scrollTimeout);
                }
            };
        }
    }, []);

    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(`/register?plan=user`);
         // Push a custom event to the dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'gtm.linkClick', 
            'gtm.elementText': 'Join Individual Plan',
            'gtm.triggers': '193320863_11',
            plan_type: 'individual'
        });
    };

    const handleSubmitEnterprise = () => {
        navigate(`/register?plan=team`);
         // Push a custom event to the dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'gtm.linkClick',
            'gtm.elementText': 'Join Team Plan',
            'gtm.triggers': '193320863_11',
            plan_type: 'team'
        });
    };
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handlePlayClick = () => {
        const video = document.getElementById('hero-video');
        if (video) {
            if (video.paused) {
                video.play().then(() => {
                    setIsPlaying(true);
                    // Request fullscreen after playback starts
                    if (video.requestFullscreen) {
                        video.requestFullscreen();
                    } else if (video.mozRequestFullScreen) { // Firefox
                        video.mozRequestFullScreen();
                    } else if (video.webkitRequestFullscreen) { // Chrome, Safari and Opera
                        video.webkitRequestFullscreen();
                    } else if (video.msRequestFullscreen) { // IE/Edge
                        video.msRequestFullscreen();
                    }
                }).catch(error => {
                    console.error("Error attempting to play video:", error);
                });
            } else {
                video.pause();
                setIsPlaying(false);
                // Check if the document is in fullscreen before attempting to exit
                if (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                }
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            const video = document.getElementById('hero-video');
            if (!document.fullscreenElement &&
                !document.webkitFullscreenElement &&
                !document.mozFullScreenElement &&
                !document.msFullscreenElement) {
                // Check if the video exists and is still playing when exiting fullscreen
                if (video) {
                    setIsPlaying(!video.paused);
                }
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const video = document.getElementById('hero-video');
        
        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);

        if (video) {
            video.addEventListener('play', handlePlay);
            video.addEventListener('pause', handlePause);
        }

        return () => {
            if (video) {
                video.removeEventListener('play', handlePlay);
                video.removeEventListener('pause', handlePause);
            }
        };
    }, []);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMobileMenuOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMenuOpen(false);
    };

    useEffect(() => {
        // Preload critical assets
        const preloadLinks = [
            { rel: 'preload', href: logoLong, as: 'image' },
            { rel: 'preload', href: HowEasy, as: 'image' },
            { rel: 'preload', href: StorybuiltHowEasy, as: 'fetch' },  // Changed from 'video' to 'fetch'
        ];

        preloadLinks.forEach(link => {
            const linkElement = document.createElement('link');
            linkElement.rel = link.rel;
            linkElement.href = link.href;
            linkElement.as = link.as;
            if (link.as === 'fetch') {
                linkElement.setAttribute('crossorigin', 'anonymous');  // Add this for video preloading
            }
            document.head.appendChild(linkElement);
        });
    }, []);

    return (
        <>
         {/* React Helmet for OG and SEO Meta Tags */}
         <Helmet>
                <title>Storybuilt.AI - Unleash Your Creative Potential</title>
                <meta name="description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Storybuilt.AI - Unleash Your Creative Potential" />
                <meta property="og:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.storybuilt.ai/" />
                <meta property="og:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@StorybuiltAI" />
                <meta name="twitter:title" content="Storybuilt.AI - Unleash Your Creative Potential" />
                <meta name="twitter:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                <meta name="twitter:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Additional Meta Tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.storybuilt.ai/" />
            </Helmet>
            {/* Navigation Bar */}
            <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
                <Toolbar>
                    {/* Branding/Logo */}
                    <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box component="img" src={logoLong} alt='' sx={{height: { xs: '35px', md: '80px' } }} />
                        </Link>
                    </Typography>

                    {/* Navigation Links */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: {xs:1, md:2} }}>
                        {/* Mobile Menu */}
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { xs: 'flex', md: 'none' } }}
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={mobileMenuOpen}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose} component={Link} to="/publishing">
                                Publishing Your Story
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose} component={Link} to="/blog">
                                Our Blog
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose} component={Link} to="/whats-new">
                                What's New
                            </MenuItem>
                        </Menu>

                        {/* Desktop Menu */}
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2, mr: 2 }}>
                            <Button 
                                component={Link} 
                                to="/publishing" 
                                variant="outlined"
                                sx={{ 
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'secondary.main',
                                        color: 'secondary.main',
                                    },
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                }}
                            >
                                Publishing Your Story
                            </Button>
                            <Button 
                                component={Link} 
                                to="/blog" 
                                variant="outlined"
                                sx={{ 
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'secondary.main',
                                        color: 'secondary.main',
                                    },
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                }}
                            >
                                Our Blog
                            </Button>
                            <Button 
                                component={Link} 
                                to="/whats-new" 
                                variant="outlined"
                                sx={{ 
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'secondary.main',
                                        color: 'secondary.main',
                                    },
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                }}
                            >
                                What's New
                            </Button>
                        </Box>
                        <Button variant="contained" 
                            component={Link} 
                            to="/register" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 40px' },
                                height: { xs: '35px', md: '55px' } }}
                        >
                            Join
                        </Button>
                        <Button variant="contained" 
                            component={Link} 
                            to="/login" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 24px' },
                                height: { xs: '35px', md: '55px' } }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Spacer to adjust content down */}
            <Toolbar />

        {/* Main Content */}
        <Container maxWidth="lg" sx={{ mt: 0, mb: 2,}}>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
                {/* Left Column */}
                <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Typography 
                        variant="h1" 
                        sx={{ 
                            ml: {xs: 0, md: 6},
                            fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                            fontWeight: 'bold',
                            background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            marginBottom: '20px',
                        }}
                    >
                        Unleash Your Creative Potential
                    </Typography>
                    <Typography 
                        variant="h2" 
                        sx={{ 
                            fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
                            fontWeight: 'normal',
                            color: '#FFFFFF',
                            mb: 4,
                            px: 4,
                            ml: {xs: 0, md: 2},
                        }}
                    >
                        AI-Powered Writing for Fiction, Non-Fiction, and Beyond...
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, mb: 4, justifyContent: { xs: 'center', md: 'flex-start' }, ml: {xs: 0, md: 6} }}>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            component={Link} 
                            to="/register" 
                            sx={{ 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '12px 24px', md: '16px 32px' },
                            }}
                        >
                            Try for Free
                        </Button>
                        <Button 
                            variant="outlined" 
                            color="secondary" 
                            onClick={() => setIsModalOpen(true)}
                            sx={{ 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '12px 24px', md: '16px 32px' },
                            }}
                        >
                            Watch Demo
                        </Button>
                    </Box>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6}>
                    <Box 
                        sx={{ 
                            position: 'relative',
                            cursor: 'pointer',
                            mt: {xs: 0, md: -10},
                            maxWidth: { xs: '100%', md: 'none' },
                            margin: { xs: '0 auto', md: 0 }
                        }}
                        onClick={handlePlayClick}
                    >
                        <video
                            id="hero-video"
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                            }}
                            controls={isPlaying}
                            preload='auto'
                            poster={HowEasy}
                            onEnded={() => setIsPlaying(false)}
                        >
                            <source src={StorybuiltHowEasy} type="video/mp4" />
                            Your browser does not support this video type.
                        </video>
                        {!isPlaying && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents: 'none',
                                }}
                            >
                                <PlayArrowIcon sx={{ fontSize: 100, color: 'white' }} />
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>

        {/* Media Cards Use Case Section */}
        <Box
            sx={{
                backgroundColor: 'black',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
        <Box
            className="changing-words"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: {xs: 0, md: 30},
                justifyContent: 'space-between', // Creates space between static and changing texts
                mt: 0,
            }}
        >
        {/* Static Text */}
        <Typography variant="h2">Endless</Typography>

        {/* Changing Text */}
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10, // Adjust gap between the animated text items
            }}
        ></Box>
          <Typography {...textStyle}>stories</Typography>
          <Typography {...textStyle}>scripts</Typography>
          <Typography {...textStyle}>novels</Typography>
          <Typography {...textStyle}>articles</Typography>
          <Typography {...textStyle}>writing</Typography>
          <Typography {...textStyle}>possibilities.</Typography>
      </Box>

      <Box sx={{ padding: '50px 50px', width: '90%', mt: '100px', }}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Card elevation={0} sx={{ textAlign: 'center', padding: '20px', mx: '-2px', height: '250px' }}>
            <BoltIcon sx={{ fontSize: '50px', marginBottom: '20px' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Streamline Your Process
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Transform your raw ideas into fully fleshed-out stories in minutes. Let our AI handle the details while you focus on the creative big picture.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card elevation={0} sx={{ textAlign: 'center', padding: '20px', mx: '-2px', height: '250px' }}>
            <GroupWorkIcon sx={{ fontSize: '50px', marginBottom: '20px' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Collaborate with AI
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Work hand-in-hand with AI to generate characters, settings, and plots. Elevate your storytelling with AI that understands your vision.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card elevation={0} sx={{ textAlign: 'center', padding: '20px', mx: '-2px', height: '250px' }}>
          <AutoAwesomeIcon sx={{ fontSize: '50px', marginBottom: '20px' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Unlimited Creative Freedom
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Experiment with genres, styles, and structures at the click of a button. Discover endless possibilities with AI-powered creative tools.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ padding: '50px 0' }}>
      <Grid container spacing={4} justifyContent="center">
        {majorFeatures.map((section, index) => (
          <Grid key={index} item xs={12} md={10}>
            <Box sx={{ backgroundColor: section.backgroundColor, padding: {xs: '5px', md: '20px'}, paddingBottom: {xs: '20px', md: '20px'}, borderRadius: '8px' }}>
              <Typography variant="h4" color='#002e3a' gutterBottom>{section.title}</Typography>
              <Typography variant="body1" color='#002e3a' gutterBottom>{section.description}</Typography>
              <Paper elevation={0} sx={{ margin: {xs: '0px', md: '40px'}, backgroundColor: 'transparent', marginTop: '20px' }}>
                <Box
                  component="video"
                  src={section.videoSrc}
                  autoPlay
                  muted
                  loop
                  playsInline 
                  controls={false} 
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
      </Box>
        <Typography 
            variant="h4" 
            sx={{ 
                mt: 15, 
                mb: 5, 
                px: 5, 
                textAlign: 'center', 
                textShadow: '0 0 5px rgba(255, 255, 255, 0.7), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 0, 255, 0.5)', // Glow effect
                transition: 'text-shadow 0.3s ease-in-out', // Smooth transition
                '&:hover': {
                    textShadow: '0 0 10px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 0, 255, 1)', // Stronger glow on hover
                },
            }}
        >
            ...writing will never be the same.
        </Typography>
        {/* <Box
            ref={scrollContainerRef}
            sx={{
                display: "flex",
                overflowX: "auto",
                gap: 2,
                p: 2,
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": { display: "none" },
            }}
        >
            {cardLoop.map((card, index) => (
                <Card
                    key={index}
                    sx={{
                        height: 600,
                        width: mediaCardWidth,
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        maxWidth: '100%',
                    }}
                >
                    <CardMedia
                        component="img"
                        height="250"
                        image={card.image}
                        alt={card.title}
                        sx={{ width: '100%', objectFit: 'cover' }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" component="div">{card.title}</Typography>
                        <Typography sx={{ fontSize: '1.2rem', mt: 1 }} variant="body2" color="text.secondary">{card.description}</Typography>
                    </CardContent>
                </Card>
            ))}
        </Box> */}
        {/* Features Section */}
       {/*  <Box
      sx={{
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
            <Typography  variant='h4' sx={{mx: '15vw', mt: '20vh', mb:'10vh'}}>
                    Transform your creativity and productivity with our innovative approach to writing (with AI).            
            </Typography>
        </Box>
        <Grid container spacing={4} alignItems="center" direction="column">
            {featureCards.map((card, index) => {
                // Reverse order if index is odd
                const isReversed = index % 2 !== 0;

                return (
                    <Grid
                        item
                        container
                        spacing={2}
                        key={index}
                        direction={isReversed ? "row-reverse" : "row"} // Reverse for odd indexes
                        alignItems="center"
                        justifyContent='center'
                        sx={{width:{xs:'90%', md:'70%'}}}
                    >
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Box sx={{ width: '100%', maxWidth: '350px', margin: '0 auto'}}>
                                    <CardMedia
                                        component="img"
                                        height='350px'
                                        image={card.image}
                                        alt={card.title}
                                        sx={{ width: '100%', objectFit: 'cover' }}
                                    />
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', py: '30px', display: 'flex', alignItems: 'center'}}>
                                <CardContent sx={{ }}>    
                                    <Typography variant="h5" component="div">{card.title}</Typography>
                                    <Typography variant="body1" color="textSecondary">{card.description}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid> */}
        <Box
            sx={{
                backgroundColor: 'black',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <Typography 
                variant='h4' 
                sx={{
                    mt: {xs:'10vh', md:'20vh'}, 
                    mb: '5vh', 
                    mx: '10vh',
                    background: 'linear-gradient(270deg, #FF00FF, #00FFFF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    display: 'inline-block', // Ensures the gradient applies correctly
                }}
            >
                Choose Your Creative Journey
            </Typography>
        </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: 'center' }}>
            {pricingPlans.map((plan, index) => (
                <Card key={index} raised sx={{ width: 310, height: 475, display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" component="h2" gutterBottom color='primary'>
                            {plan.title}
                        </Typography>
                        <Typography variant="h4" fontWeight='300' color="primary" mt='-10px'>
                            {plan.price}
                        </Typography>
                        <ul style={{ textAlign: 'left', padding: 2 }}>
                            {plan.features.map((feature, featureIndex) => (
                                <Typography component="li" key={featureIndex} sx={{ textAlign: 'left', mb: 1, ml: 3, mr: 2 }}>
                                    {feature}
                                </Typography>
                            ))}
                        </ul>
                    </CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 5 }}>
                        {plan.title.toLowerCase() === 'team' ? (
                            <Button variant="contained" color="primary" onClick={handleSubmitEnterprise} sx={{ color: 'white' }}>
                                Choose Plan
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ color: 'white' }}>
                                Choose Plan
                            </Button>
                        )}
                    </Box>
                </Card>
            ))}
        </Box>
        <footer>
            <FooterContainer>
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center">
                    <Typography textAlign='left' variant="body1" color="textSecondary">Reach out:</Typography>
                    <CustomLink href="mailto:Hello@Storybuilt.AI">Hello@Storybuilt.AI</CustomLink>
                </Grid>
                <Grid item xs={12} md={6} container direction="column" alignItems="center">
                    <Box component="img" src={Logo} alt="Storybuilt.AI Logo" width={175} height="auto" />
                    <Typography mt='5px' variant="body1" color="textSecondary">Endless Possibilites.</Typography>
                </Grid>
                </Grid>
            </FooterContainer>
        </footer>
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 800,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/evIpu_g3Cyo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Box>
        </Modal>
        </>
    );
}

export default Home;