import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Container, AppBar, Toolbar, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import logoLong from '../../assets/images/logo-long.png';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';

const GradientTypography = styled(Typography)(({ theme }) => ({
    background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
}));

const faqs = [
    {
        question: "What is Storybuilt.AI?",
        answer: "Storybuilt.AI is an innovative AI-powered platform designed to assist writers in creating stories, scripts, and novels. It offers tools for character development, world-building, plot generation, and more, all powered by advanced AI technology."
    },
    {
        question: "How does Storybuilt.AI work?",
        answer: "Storybuilt.AI uses a combination of AI models and writing techniques to help you develop your story. You can input your ideas, and the AI will help expand them into full narratives, characters, and settings. The platform breaks down the writing process into manageable steps, allowing you to focus on creativity while the AI handles the details."
    },
    {
        question: "What types of writing can I do with Storybuilt.AI?",
        answer: "Storybuilt.AI supports various types of writing, including fiction, non-fiction, screenplays, novels, short stories, and more. Whether you're working on a fantasy epic, a crime thriller, or a historical biography, our AI can assist you in developing your project."
    },
    {
        question: "How do AI tokens work?",
        answer: "AI tokens are credits used for AI-powered generation on our platform. Different tasks consume different amounts of tokens. For example, generating a short piece of text might use 10 tokens, while creating an image could use 70 tokens. You can purchase additional tokens as needed, and some plans come with a set number of tokens included."
    },
    {
        question: "Can Storybuilt.AI write my entire story for me?",
        answer: "While Storybuilt.AI is a powerful tool, it's designed to assist and enhance your writing, not replace you as the author. The AI can help generate ideas, expand on concepts, and fill in details, but the core creativity and direction of the story should come from you. Think of it as a highly advanced writing partner or assistant."
    },
    {
        question: "Is my work on Storybuilt.AI private and secure?",
        answer: "Yes, we take the privacy and security of your work very seriously. All your stories, characters, and other creations are kept private and are only accessible by you. We use industry-standard encryption and security measures to protect your data."
    },
    {
        question: "Can I collaborate with others on Storybuilt.AI?",
        answer: "Currently, Storybuilt.AI is designed for individual use. However, we're exploring collaboration features for future updates. Stay tuned for announcements about team and collaboration capabilities."
    },
    {
        question: "How can I get started with Storybuilt.AI?",
        answer: "To get started, simply sign up for an account on our website. You can choose a plan that fits your needs, and you'll immediately have access to our AI-powered writing tools. We recommend starting with our 'Quickstart AI' feature to turn your initial idea into a full story outline."
    },
    {
        question: "What if I'm not satisfied with the AI-generated content?",
        answer: "Our AI is highly customizable, and you can always regenerate or edit the content it produces. If you're not happy with a particular output, you can adjust your input or use our editing tools to refine the results. Remember, the AI is a tool to enhance your creativity, not replace it."
    },
    {
        question: "Does Storybuilt.AI offer any learning resources for writers?",
        answer: "Yes! We provide a variety of resources to help you improve your writing skills and make the most of our platform. This includes tutorials on using our AI tools, writing tips, and guides on story structure and character development. Check out our 'Resources' section for more information."
    }
];

const FAQs = () => {
    return (
        <>
            <Helmet>
                <title>Storybuilt.AI - Frequently Asked Questions</title>
                <meta name="description" content="Find answers to common questions about using Storybuilt.AI for your writing projects." />
            </Helmet>
            <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box component="img" src={logoLong} alt='Storybuilt.AI' sx={{height: { xs: '45px', md: '80px' } }} />
                        </Link>
                    </Typography>
                    <Box sx={{ display: 'flex', gap: {xs:1,md:2} }}>
                        <Button variant="contained" 
                            component={Link} 
                            to="/register" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 40px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Join
                        </Button>
                        <Button variant="contained" 
                            component={Link} 
                            to="/login" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 24px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Sign In
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg">
                <Box sx={{ my: 8, textAlign: 'center' }}>
                    <GradientTypography variant="h2" component="h1" gutterBottom>
                        Frequently Asked Questions
                    </GradientTypography>
                    <Typography variant="h5" sx={{ mb: 6 }}>
                        Find answers to common questions about Storybuilt.AI
                    </Typography>
                </Box>

                <Box sx={{ mb: 8 }}>
                    {faqs.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}a-content`}
                                id={`panel${index}a-header`}
                            >
                                <Typography variant="h6">{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>

                <Box sx={{ my: 8, textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        Still have questions?
                    </Typography>
                    <Button variant="contained" color="primary" size="large" component={Link} to="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default FAQs;

