import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import * as constants from '../../helpers/constants'
import { useNavigate } from 'react-router-dom';
import story from '../../assets/images/story.png';
import script from '../../assets/images/script.png';
import vaEngage from '../../assets/images/vaEngage.png';
import customai from '../../assets/images/customai.png';
import UpdateModal from '../../components/modals/UpdateModal';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Products = () => {
  const navigate = useNavigate();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const checkForUpdates = async () => {
      console.log('Checking for updates');
      try {
        const response = await axios.get(`${constants.BASE_URL}api/auth/last-seen-version`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { lastSeenVersion, latestVersion } = response.data;
        if (lastSeenVersion !== latestVersion) {
          setUpdateModalOpen(true);
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    };

    checkForUpdates();
  }, [token]);

  return (
    <Container component="main" maxWidth='lg'>
      <CssBaseline />
      <Typography align="center" variant='h4' mt={15}>Please Select Your AI Tool</Typography>

      <Box
        sx={{
          marginTop: {
            xs: 3,
            md: 5,
          },
          marginBottom: 4,
          display: 'flex', // Use flexbox for layout
          flexDirection: {
            xs: 'column', // Column layout for extra-small screens
            md: 'column', // Column layout for medium screens and up
          },
          justifyContent: {
            md: 'flex-start', // Align items at the start for medium screens and up
            xs: 'center', // Center items for extra-small screens
          },
          alignItems: 'center', // Align items in the center
          gap: '30px',
          position: 'relative',
          paddingBottom: '100px',
        }}
      >
        <Box
          onClick={()=> navigate('/project-gallery')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems:"center",
            width: {xs:'100%', md: '500px'},
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '8px solid ' + constants.TEAL,
          }}
        >
          <img src={story} alt="product icon" style={{height: '80px', width: 'auto'}} />
          <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>Start here!</Typography>
          <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>Our revolutionary AI writing tool.</Typography>
        </Box>
        <Box
          onClick={()=> navigate('/analysis')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems:"center",
            width: {xs:'100%', md: '500px'},
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <img src={script} alt="product icon" style={{height: '80px', width: 'auto'}} />
          <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>AI Script Coverage!</Typography>
          <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>Script Coverage, Style Analysis, and Production Planning</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems:"center",
            width: {xs:'100%', md: '500px'},
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <img src={vaEngage} alt="product icon" style={{height: '80px', width: 'auto'}} />
          <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>COMING SOON!</Typography>
          <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>AI assistants to keep your team on task, engaged, and in the loop. </Typography>
        </Box>
        <Box
          onClick={() => window.location.href = 'mailto:hello@storybuilt.ai'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems:"center",
            width: {xs:'100%', md: '500px'},
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <img src={customai} alt="product icon" style={{height: '80px', width: 'auto'}} />
          <Typography align="center" sx={{fontSize: '20px', fontWeight: 'bold'}}>Custom AI Solutions!</Typography>
          <Typography align="center" sx={{fontSize: '20px', fontWeight: ''}}>Contact us about building custom AI for your organization or training a model for your project.  </Typography>
        </Box>
      </Box>
      <UpdateModal 
        open={updateModalOpen} 
        onClose={() => setUpdateModalOpen(false)} 
      />
    </Container>
  );
};

export default Products;

