export const GREY = '#2F3944'
export const BLUE_GREY = '#24262c'
export const DARK_GREY = '#131518'
export const BLACK = '#020202'
//export const DARK_GREY = '#111B26'
export const ORANGE = '#FF6633'
export const TEAL = '#11D3AF'
export const DARK_TEAL = '#26BDA1'
export const PURPLE = '#6637C4'
export const PRIMARY = '#11D3AF'
export const PRIMARY_DARK = '#26BDA1'
export const SECONDARY = '#6637C4'
export const BACKGROUND = '#2F3944'
export const TEXT = '#FFFFFF'
export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL
export const IMAGE_URL = process.env.REACT_APP_BASE_URL + 'api/projects/imagery/'
export const VERSION = '0.9.0'
export const PROMPTLIST = ['Non-Fiction', 'Self-Help / Advice', 'Marketing / Ad Brief', 'Traditional Three-Act', 'Action / Adventure', 'Comedy', 'Drama', 'Dark Comedy', 'Procedural or True Crime', 'Thriller / Suspense', 'Horror', 'Episodic show','Podcast', 'Sitcom', 'Nolan-style Non-linear', 'Cameron-style Epic', 'Roadtrip', 'Documentary', 'TV Movie', 'Children\'s Story', 'Custom']
export const STORYTYPES = {
  fiction: ['Children\'s Story', 'Traditional Three-Act', 'Action / Adventure', 'Comedy', 'Drama', 'Dark Comedy', 'Procedural or True Crime', 'Thriller / Suspense', 'Horror', 'Episodic show','Podcast', 'Sitcom', 'Nolan-style Non-linear', 'Cameron-style Epic', 'Roadtrip', 'Documentary', 'TV Movie', 'Custom'],
  nonFiction: ['Non-Fiction', 'Self-Help / Advice', 'Marketing / Ad Brief']
}
export const IMAGESTYLESARRAY = ['Anamorphic Cinema Film Lens', 'Black & White Analog Film Camera', 'Candid Instagram Influencer-style Photo', '1977 Star Wars Style', '1979 Alien Style', 'Cyberpunk Style', 'Dune 2021 Style', 'Comic Book Illustration', 'Comic Book: Neal Adams Style', 'Comic Book: Steve Ditko Style', 'Comic Book: Jack Kirby Style', 'Classic Disney Animation Style', 'Pixar Animation Style', '1990s Cartoons', 'Modern Anime', 'Storyboard Sketch, Black & White', 'Charcoal Sketch', 'Retro Sci-Fi Pulp Novel', 'The Atomic Age', 'Fantasy Novel Cover Art', 'Pixel Art','Character Sheet','Custom']
