import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Container, CircularProgress, CssBaseline, Typography, Checkbox, Avatar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import {StyledBadge} from './style'
import ChangePassword from '../../components/modals/ChangePassword';
import { setTokens } from '../../store/slices/authSlice';
import { ReactComponent as Discord } from '../../assets/images/discord-logo-blue.svg';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';


const User = ({dialog}, currentProject) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const [user, setUser] = useState(null)
  const [changeModalOpen, setChangeModalOpen] = useState(false)
  const currentFullUrlWithoutParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const [autoFillTokensFlag,setAutoFillTokensFlag] = useState(false);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [entAdmin, setEntAdmin] = useState(null); 
  const [tokensFillModal, setTokensFillModal] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const handleClickCancelOpen = () => {
    setOpenCancelConfirm(true);
  };

  const handleCancelClose = () => {
    setOpenCancelConfirm(false);
  };

  const handleCancelConfirm = () => {
    resetUserSubscriptionPlan(localStorage.getItem('userId'));
    setOpenCancelConfirm(false); 
  };

  useEffect(() => {
    if (token) {
      getUser(); 
    }
  }, [token]);

  // console.log('token',token)
  const getUser = () => {
    dispatch(addLoadingFlag('get-user-info'));
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-user-info',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-user-info'));
      setUser(response.data)
      setAutoFillTokensFlag(response.data?.autoFillTokensFlag)
      setEntAdmin(response.data?.enterpriseAdminEmail);
      setSubscription(response.data?.subscriptionPlan);
      setSubscriptionStatus(response.data?.subscriptionStatus);
      console.log('response.data sub', response.data?.subscriptionPlan)
    }).catch(function (error) {
      console.error(error);
      dispatch(removeLoadingFlag('get-user-info'));
    });
  }

  const changeUserSubscriptionPlan = async () => {
    dispatch(addLoadingFlag('change-plan'));
    await axios
      .post(constants.BASE_URL + 'api/auth/change-user-subscription-plan', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('change-plan'));
        alert.show(
          `Subscription changed`,
          { type: 'success' }
        );
        setUser(prev => { return {...prev,subscriptionPlan: 'Pro'}})

      }).catch(function (error) {
        // console.log(error);
        dispatch(removeLoadingFlag('change-plan'));
      });
    }

  const createUserSubscriptionBill = async () => {

    dispatch(addLoadingFlag('get-users'));
    await axios
      .post(constants.BASE_URL + 'api/auth/create-user-subscription-plan-bill', {
       successUrl: currentFullUrlWithoutParams,
       cancelUrl: currentFullUrlWithoutParams,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('get-users'));
        if (response.data === 'subscription active') {
          changeUserSubscriptionPlan()
        } else {
          window.location.replace(response.data);
        }
      }).catch(function (error) {
        // console.log(error);
        dispatch(removeLoadingFlag('get-prompts'));
      });
    }

  const autoTokensfill = async () => {
    dispatch(addLoadingFlag('add-tokens'));
    await axios
      .post(constants.BASE_URL + 'api/auth/add-automatically-tokens',{}, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('add-tokens'));
        if (response.data === 'subscription not bought') {
          alert.show(
            `Subscription not bought`,
            { type: 'error' }
          );
        } else if (response.data === 'subscription not active') {
          alert.show(
            `Subscription not active`,
            { type: 'error' }
          );
        }else {
          alert.show(
            `Tokens successfully added`,
            { type: 'success' }
          );
          getUserTokens()
        }
      }).catch(function (error) {
        // console.log(error);
        dispatch(removeLoadingFlag('add-tokens'));
      });
    }

  const resetUserSubscriptionPlan = async (userId) => {
    dispatch(addLoadingFlag('reset-subscription'));
    await axios
      .post(constants.BASE_URL + 'api/auth/reset-user-subscription-plan', {
       userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('reset-subscription'));
        alert.show(
          `Subscription canceled`,
          { type: 'success' }
        );
        getUser()
      }).catch(function (error) {
        // console.log(error);
        dispatch(removeLoadingFlag('reset-subscription'));
      });
    }
  const resetCancelUserSubscriptionPlan = async (userId) => {
    dispatch(addLoadingFlag('reset-subscription'));
    await axios
      .post(constants.BASE_URL + 'api/auth/reset-cancel-user-subscription-plan', {
       userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      } ).then(function (response) {
        dispatch(removeLoadingFlag('reset-subscription'));
        alert.show(
          `Subscription continued`,
          { type: 'success' }
        );
        getUser()
      }).catch(function (error) {
        // console.log(error);
        dispatch(removeLoadingFlag('reset-subscription'));
      });
    }
    const getUserTokens = () => {
      axios
      .get(
        constants.BASE_URL + 'api/auth/get-user-info',
        {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        }
      ).then(function (response) {
        // console.log(response.data)
        dispatch(setTokens(response.data?.tokens))
      }).catch(function (error) {
        // console.log(error);
      });
      }

      async function changeFlag (flagValue) {
        await axios
          .post(constants.BASE_URL + 'api/auth/change-user-tokens-auto-fill-flag', {
            flagValue
          }, {
            headers: {
            'Authorization': `Bearer ${token}`
            },
          } ).then(function (response) {
          }).catch(function (error) {
            // console.log(error);
          });
      }    

    const convertTimestamp = (timestamp) => {
      const date = new Date(timestamp * 1000);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const transformRole = (role) => {
      if (role === 'User') {
        return 'Individual';
      }
      if (role === 'Admin') {
        return 'Storybuilt.AI Admin';
      }
      return role.charAt(0).toUpperCase() + role.slice(1);
    };

    // Add this new function to handle opening the customer portal
    const openCustomerPortal = async () => {
      dispatch(addLoadingFlag('customer-portal'));
      try {
        const response = await axios.post(
          constants.BASE_URL + 'api/auth/create-customer-portal-session',
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );
        
        // Try to open in new window
        const newWindow = window.open(response.data.url, '_blank');
        
        // Check if popup was blocked
        if (newWindow === null) {
          // If blocked, inform user and provide alternative
          alert.show(
            'Pop-up was blocked. Click OK to open billing portal in current window.',
            { type: 'info' }
          );
          // Fall back to opening in same window
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.error('Error opening customer portal:', error);
        alert.show(
          'Unable to open billing portal. Please try again later.',
          { type: 'error' }
        );
      } finally {
        dispatch(removeLoadingFlag('customer-portal'));
      }
    };

    // Update the PaymentFailureWarning component to be more generic
    const BillingSection = ({ subscriptionStatus }) => {
      const loading = useSelector((state) => 
        state.loading.loadingFlags.includes('customer-portal')
      );

      return (
        <>
          {subscriptionStatus === 'payment_failed' && (
            <Box 
              sx={{ 
                backgroundColor: '#FDE7E9', 
                color: '#000000',
                padding: 2,
                borderRadius: 1,
                border: '1px solid #FFA4A4',
                width: '100%',
                textAlign: 'center'
              }}
            >
              <Typography variant="body1">
                There was a problem with your last payment. Please update your payment method to continue using the service.
              </Typography>
            </Box>
          )}
          
          <Box sx={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            <Button
              onClick={openCustomerPortal}
              variant="contained"
              component="label"
              disabled={loading}
              sx={{ 
                height: '36px',
                color: 'white',
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '14px',
                marginRight: '8px',
                minWidth: '140px'
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Manage Billing'}
            </Button>
          </Box>
        </>
      );
    };

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <AutoFillTokensModal 
            open={tokensFillModal} 
            setOpen={setTokensFillModal} 
            currentProjectName={currentProject?.title || undefined}
            noCountdown={true}
          />
          <ChangePassword open={changeModalOpen} setOpen={setChangeModalOpen} />
          {user && (
            <>
              <Box
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 2,
                  },
                  marginBottom: 1,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px 0',
                  position: 'relative',
                  paddingBottom: '0px',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    bottom: '-1px',
                    padding: '0px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                />
                
                <IconButton sx={{ p: 0 }}>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar sx={{width: '100px', height: '100px'}} src="/static/images/avatar/1.jpg" />
                    </StyledBadge>
                  </StyledBadge>
                </IconButton>
                
                {user && (
                  <>
                    <Typography>Name: {user.name}</Typography>
                    <Typography>Email: {user.email}</Typography>
                    <Typography>Account Type: {transformRole(user.role)}</Typography>
                    {user?.role === 'Enterprise User' && <Typography>Enterprise Admin: {entAdmin}</Typography>}
                    {user?.role !== 'Enterprise User' && <Typography>AI Tokens: {user?.tokens}</Typography>}
                    <Typography>
                      Subscription: {
                        user.role === 'Enterprise User' 
                          ? user?.type === 'not subscribed' 
                            ? 'Problem with the subscription. Please notify your Enterprise Admin.' 
                            : 'Active' 
                          : subscriptionStatus === 'payment_failed' 
                            ? 'Payment Failed' 
                            : subscriptionStatus === 'active' 
                              ? 'Active' 
                              : 'Not subscribed'
                      }
                    </Typography>
                    
                    {/* Show Billing Section for non-Enterprise users with an active subscription */}
                    {user?.role !== 'Enterprise User' && 
                     (subscriptionStatus === 'active' || subscriptionStatus === 'payment_failed') && 
                      <BillingSection subscriptionStatus={subscriptionStatus} />
                    }

                    {user?.role !== 'Enterprise User' && (
                      <Box>
                        <Box sx={{
                          zIndex: '10',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}>
                          <Button 
                            onClick={() => setTokensFillModal(true)} 
                            variant="contained" 
                            component="label" 
                            sx={{ 
                              height: '36px', 
                              color: 'white', 
                              marginTop: '0px', 
                              marginBottom: '0px', 
                              fontSize: '14px', 
                              marginRight: '8px' 
                            }}
                          >
                            Purchase Tokens
                          </Button>
                        </Box>
                      </Box>
                    )}
                    
                    <Button
                      onClick={() => setChangeModalOpen(true)}
                      variant="contained"
                      component="label"
                      sx={{
                        height: '36px',
                        color: 'white',
                        marginTop: '0px',
                        marginBottom: '8px',
                        fontSize: '14px',
                        marginRight: '8px'
                      }}
                    >
                      Change password 
                    </Button>

                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      {user?.subscriptionExpireDate && (
                        <Typography>
                          Subscription expires: {convertTimestamp(user?.subscriptionExpireDate)}
                        </Typography>
                      )}
                      
                      {user.role !== 'Enterprise User' && (
                        <>
                          {/* ... subscription related buttons ... */}
                          <Dialog
                            open={openCancelConfirm}
                            onClose={handleCancelClose}
                            align="center"
                          >
                            <DialogTitle>Are You Sure You Want to Cancel Your Account?</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Your account will be deleted at the end of the current billing period. <br />
                                Any tokens you have remaining at that time will be lost.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button variant="contained" onClick={handleCancelClose} color="primary">
                                Cancel
                              </Button>
                              <Button variant="contained" onClick={handleCancelConfirm} color="primary" autoFocus>
                                Confirm
                              </Button>
                            </DialogActions>
                          </Dialog>    
                        </>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Container>
    );
};

export default User;
