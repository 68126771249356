import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { addLoadingFlag, removeLoadingFlag } from '../../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';

const EditUser = ({ open, setOpen, user, getUsers }) => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
  
    useEffect(() => {
      setName(user?.name || '');
      setEmail(user?.email || '');
    }, [user]);
  
    const handleSave = async () => {
      dispatch(addLoadingFlag('edit-user'));
      try {
        await axios.post(constants.BASE_URL + 'api/auth/update-user', {
          userId: user._id,
          name,
          email
        }, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          },
        });
  
        dispatch(removeLoadingFlag('edit-user'));
        alert.show('User updated successfully', { type: 'success' });
        getUsers();
        setOpen(false);
      } catch (error) {
        dispatch(removeLoadingFlag('edit-user'));
        if (error.response && error.response.data && error.response.data.error) {
          alert.show(`Error: ${error.response.data.error}`, { type: 'error' });
        } else {
          alert.show('Error updating user', { type: 'error' });
        }
      }
    };
  
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Edit User</h2>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    );
  };
  
  export default EditUser;
