import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {  Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '80vw',
	},

	bgcolor: 'background.paper',
	border: '2px solid #000',
	outline: 'none',
	boxShadow: 24,
	p: 4,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	gap: '30px'
};

export default function StartGuideModal() {
	const token = useSelector((state) => state.auth.token);
	const userId = localStorage.getItem('userId');
	const tutorialFlag = localStorage.getItem('tutorialFlag');
	const [open,setOpen] = useState(tutorialFlag === 'false' ? false : true)
	const [activeTab, setActiveTab] = useState(0)

	const contentArray = [
		{
			title: "",
			text: 'Please create a New Project to get started.'
		},
	]

	function handleChangeActiveTab (direction) {
		if (direction === 'next') {
			if (activeTab < contentArray.length -1) {
				setActiveTab(activeTab + 1)
			}
		} else {
			if (activeTab > 0) {
				setActiveTab(activeTab - 1)
			}
		}
	}
	async function changeFlag () {
		await axios
			.post(constants.BASE_URL + 'api/auth/change-user-tutorial-flag', {
				userId
			}, {
				headers: {
				'Authorization': `Bearer ${token}`
				},
			} ).then(function (response) {
			}).catch(function (error) {
				// console.log(error);
			});
	}

	function onHandleClose () {
		changeFlag()
		setOpen(false)
		localStorage.setItem('tutorialFlag', false)
	}
	

	return (
			<Modal
				open={open}
				onClose={()=> onHandleClose()}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='div'
					sx={style}
				>
					<IconButton
						aria-label="close"
						onClick={onHandleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
					<CloseIcon />
					</IconButton>
					<Typography variant='h5'>Welcome to Storybuilt.AI's Revolutionary Writing Tool!</Typography>
					<Box sx={{
						display: 'flex',flexDirection: 'column', alignItems: 'center', gap: '15px'
					}}>
						<Typography>{contentArray[activeTab].title}</Typography>
						<Typography variant='h5' fontWeight={300}>{contentArray[activeTab].text}</Typography>
					</Box>
					<Box sx={{
						display: 'flex', gap: '30px'
					}}>
						{activeTab !== 0 && (
						<Button
							variant='contained'
							component='label'
							sx={{
								color: 'white',
								marginTop: '16px',
								marginBottom: '8px',
								width: '102px'
							}}
							onClick={()=> handleChangeActiveTab('prev')}
						>
							previous
						</Button>)}
						<Button
							variant='contained'
							component='label'
							sx={{
								color: 'white',
								marginTop: '16px',
								marginBottom: '8px',
								width: '102px'
							}}
							onClick={()=> activeTab === contentArray.length - 1 ? onHandleClose() : handleChangeActiveTab('next')}
						>
							{activeTab === contentArray.length - 1 ? 'Ok' : 'next'}
						</Button>
					</Box>
				</Box>
			</Modal>
	);
}
