// Import necessary hooks
import React, { lazy, Suspense } from 'react';
import Header from './components/Header';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Provider, useSelector } from 'react-redux';
import store from './store/store';
import Login from './pages/Login';
import ProtectedRoute from './utils/auth-routes/ProtectedRoute';
import ProjectGallery from './pages/ProjectGallery';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Project from './pages/Project';
import Register from './pages/Register';
import RegisterSuccess from './pages/Register/Success';
import ErrorBoundary from './utils/ErrorBoundary';
import UserMgmt from './pages/UserMgmt';
import './style.css';
import UserInfo from './pages/UserInfo';
import Contact from './pages/Contact';
import ProjectInfo from './pages/ProjectInfo';
import Products from './pages/Products';
import Analysis from './pages/Analysis';
import Home from './pages/Home';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import Publishing from './pages/Publishing/Publishing';
import Publish from './pages/Publish/Publish';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/Posts/BlogPostTemplate';
import Admin from './pages/Admin';
import VersionControl from './pages/Admin/VersionControl';
import Prompts from './pages/Admin/Prompts';
import Fees from './pages/Admin/Fees';
import GenerationTransactions from './pages/Admin/GenerationTransactions';
import UserList from './pages/Admin/UserList';
import WhatsNew from './pages/WhatsNew/WhatsNew';
import FAQs from './pages/FAQs/FAQs';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: '999999999'
  }
};

const BaseRoute = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.token);
  return isLoggedIn ? <Navigate to="/products" /> : <Navigate to="/home" />;
};

const Layout = () => {
  const location = useLocation();
  const hideHeaderPaths = ['/', '/home', '/blog', '/FAQs', '/publishing', '/login', '/register', '/register-success', '/forgot-password', '/reset-password', '/whats-new'];
  const showHeader = !hideHeaderPaths.includes(location.pathname) && 
                     !location.pathname.startsWith('/reset-password') &&
                     !location.pathname.startsWith('/blog/');

  return (
    <>
      {showHeader && <Header />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<BaseRoute />} />
          <Route path="/home" element={<Home />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:url" element={<BlogPost />} />
          <Route path="/publishing" element={<Publishing />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-success" element={<RegisterSuccess />} />
          <Route path="/whats-new" element={<WhatsNew />} />
          <Route path="/analysis" element={<ProtectedRoute><Analysis /></ProtectedRoute>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project-gallery" element={<ProtectedRoute><ProjectGallery /></ProtectedRoute>} />
          <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
          <Route path="/user-mgmt" element={<ProtectedRoute><UserMgmt /></ProtectedRoute>} />
          <Route path="/user-info" element={<ProtectedRoute><UserInfo /></ProtectedRoute>} />
          <Route path="/project-info" element={<ProtectedRoute><ProjectInfo /></ProtectedRoute>} />
          <Route path="/project-info/:id" element={<ProtectedRoute><ProjectInfo /></ProtectedRoute>} />
          <Route path="/project" element={<ProtectedRoute><ProjectGallery /></ProtectedRoute>} />
          <Route path="/project/:id" element={<ProtectedRoute><Project /></ProtectedRoute>} />
          <Route path="/publish" element={<ProtectedRoute><Publish /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute adminOnly={true}><Admin /></ProtectedRoute>} />
          <Route path="/admin/version-control" element={<ProtectedRoute adminOnly={true}><VersionControl /></ProtectedRoute>} />
          <Route path="/admin/fees" element={<ProtectedRoute adminOnly={true}><Fees /></ProtectedRoute>} />
          <Route path="/admin/generation-transactions" element={<ProtectedRoute adminOnly={true}><GenerationTransactions /></ProtectedRoute>} />
          <Route path="/admin/prompts" element={<ProtectedRoute adminOnly={true}><Prompts /></ProtectedRoute>} />
          <Route path="/admin/user-list" element={<ProtectedRoute adminOnly={true}><UserList /></ProtectedRoute>} />
          <Route path="*" element={<ProtectedRoute><Products /></ProtectedRoute>} />
        </Routes>
      </Suspense>
    </>
  );
};

const App = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </AlertProvider>
    </ErrorBoundary>
  </Provider>
);

export default App;
