import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Typography, MenuItem,	FormControl, InputLabel, Select, Tooltip } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import { logout,setTokens } from '../../../store/slices/authSlice';
import ModalLoader from '../../ModalLoader';
import CardMedia from '@mui/material/CardMedia';
import placeholder from '../../../assets/images/placeholder.png';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		sm: '400px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};


export default function AddNewImage({
	open,
	setOpen,
	episodesArray,
	setCardsToRenderFunc,
	cardsToRenderLength,
	setting,
	characters,
	stories,
	setImagesToRenderFunc,
	setTokensFillModal
}) {
	const [generationMode, setGenerationMode] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const episodesQueryValue = query.get('episodes') || '0';
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const stateLoading = useSelector((state) => state.loading.loading);
	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const [prompt, setPrompt] = useState('');
	const [title, setTitle] = useState('');
	const [titleModal, setTitleModal] = useState(false)
	const [generatedLink, setGeneratedLink] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
	const [storyValue, setStoryValue] = useState('');
	const [actValue, setActValue] = useState('');
	const [sceneValue, setSceneValue] = useState('');
	const [characterValue, setCharacterValue] = useState([]);
	const [settingValue, setSettingValue] = useState([]);
	const [imageStyles, setImageStyles] = useState();
	const isSaveButtonClicked = useRef(false);
	const pageQueryValue = query.get('page');
	const [oneImageGenerationCost, setOneImageGenerationCost] = useState(0);
	const [oneSceneImageGen, setOneSceneImageGen] = useState(0);

	useEffect(() => {
		if (pageQueryValue === 'characters' || pageQueryValue === 'setting') {
		  setGenerationMode('3');
		} else {
		  setGenerationMode(null);
		}
	  }, [pageQueryValue]);
	
	const imageStylesArray = constants.IMAGESTYLESARRAY;
	const handleClose = () => {
		setOpen(false);
		setGenerationMode(null)
		setStoryValue('')
		setPrompt('')
		setSceneValue('')
		setCharacterValue([])
		setSettingValue([])
		setImageStyles('')
		setLoading(false)
		setGeneratedLink('')
		setActValue('')
		setTitleModal(false)
	} 

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

	const handleSubmit = async () => {
		isSaveButtonClicked.current = true
		if (stateLoading || loading) {
			return
		}
		const data = {
			prompt,
		}

		if ((generationMode === '2' && (storyValue === ''  || sceneValue === '' || !imageStyles || actValue === '' )) || (generationMode === '1' && ( !imageStyles))) {
			alert.show(
				`Please fill out all fields.`,
				{ type: 'error' }
			);	
			return
		}
		const chosenStory = generationMode === '2' ? stories?.find(item => item?.story?.id === storyValue) : null
		const chosenAct = generationMode === '2' ? chosenStory?.story?.acts?.find((item,index) => index === actValue) : null
		const chosenScene = generationMode === '2' ? chosenAct?.scenes?.find(item => item?.id === sceneValue) : null

		// console.log('chosenScene',chosenScene)
		const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
			const character = characters?.find(item => item?.character?.id === charId)
			return accumulator = [...accumulator,character]
		},[])

		const settingToAI = (settingArr) => settingArr?.reduce((accumulator,setId) => {
			const settingItem = setting?.find(item => item?.settingItem?.id === setId)
			return accumulator = [...accumulator,settingItem]
		},[])
	
		if (chosenScene?.characters && generationMode === '2') data.characters = charactersToAI(chosenScene?.characters);
		if (chosenScene?.setting && generationMode === '2') data.setting = settingToAI(chosenScene?.setting);
		if (generationMode === '2') data.scene_script = chosenScene.scene_full_script

		const chosenCharacter = generationMode === '3' ? characterValue : null
		const chosenSetting = generationMode === '3' ? settingValue : null

		// console.log('Characters + Settings:',chosenCharacter + chosenSetting)
	
		if (chosenCharacter?.length && generationMode === '3') data.characters = charactersToAI(chosenCharacter);
		if (chosenSetting?.length && generationMode === '3') data.setting = settingToAI(chosenSetting);

		data.imageStyles = imageStyles

		setLoading(true)
		if (generationMode === '2') {
			request('generate-scene-image')
		} else if (generationMode === '3') {
			request('generate-character-image')
		} else {
			request('generate-image')
		}
		
		async function request (link) {
			await axios
			.post(
				constants.BASE_URL +
					'api/ai/' + link,
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				// console.log(response.data)
				setGeneratedLink(response.data)
				alert.show('New image was successfully added!', {
					type: 'success',
				});
				setLoading(false)
				getUserTokens()
				isSaveButtonClicked.current = false
			})
			.catch(function (error) {
				setLoading(false)
				if (error.response?.data === 'User does not exist.') {
					navigate('login');
					dispatch(logout());
				}
				if (error.response?.data === 'error with openAi') {
					alert.show(
					  `Something went wrong with AI server`,
					  { type: 'error' }
					);
				} else if (error.response?.data === 'Not enough tokens') {
					if (localStorage.getItem('role') === 'Enterprise User') {
					  alert.show(
						`Not enough AI tokens. Please notify your Enterprise Admin.`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`You don't have enough tokens for this action.`,
						{ type: 'error' }
					  );
					  setTokensFillModal(true)
					}		
				} else {
					alert.show(
						`Something went wrong with image generation.`,
						{ type: 'error' }
					);
					// console.log(error);
				}
				isSaveButtonClicked.current = false
			});
		}
	};

	useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		constants.BASE_URL + 'api/fees/get-fees',
		{
			headers: {
			Authorization: `Bearer ${token}`,
			},
		}
		).then(function (response) {
		dispatch(removeLoadingFlag('get-fees'));
		if(response.data.hasOwnProperty('oneImageGenerationCost')){
			setOneImageGenerationCost(response.data.oneImageGenerationCost)
		}
		if(response.data.hasOwnProperty('oneSceneImageGen')){
			setOneSceneImageGen(response.data.oneSceneImageGen)
		}
		}).catch(function (error) {
		if (error.response.data === 'Permission denied') {
			navigate('/#');
		}
		// console.log(error);
		dispatch(removeLoadingFlag('get-fees'));
		});
	},[alert,dispatch,token]);

	async function handleSaveImage () {
		isSaveButtonClicked.current = true
		if (!title) return

		const data = {
			title,
			imageURL: generatedLink,
			prompt,
			generationMode: '1',
			imageStyles,
			order: cardsToRenderLength
		}
// console.log(title + "//" + data)

		const chosenStory = generationMode === '2' ? stories?.find(item => item?.story?.id === storyValue) : null
		const chosenAct = generationMode === '2' ? chosenStory?.story?.acts?.find((item,index) => index === actValue) : null
		const chosenScene = generationMode === '2' ? chosenAct?.scenes?.find(item => item?.id === sceneValue) : null

		if (generationMode === '2') data.sceneValue = sceneValue;
		if (generationMode === '2') data.storyValue = storyValue;
		if (generationMode === '2') data.scene_script = chosenScene.scene_full_script
		if (generationMode === '2') data.generationMode = '2'
	
		if (episodesQueryValue !== '0' && episodesArray[parseInt(episodesQueryValue) - 1]?.id) {
			if (query.get('page') !== 'pages') {
				dispatch(addLoadingFlag('set-image'));
			} 
			setLoading(true)
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/set-episode-image/' +
						episodesArray[parseInt(episodesQueryValue) - 1]?.id,
							data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					const params = {};
					for(let entry of searchParams.entries()) {
					  params[entry[0]] = entry[1];
					}
					alert.show('New image was successfully saved!', {
						type: 'success',
					});
					setTitle('')
					//setGeneratedLink('')
					//setPrompt('')
					setOpen(false);
					setGenerationMode(null)
					setStoryValue('')
					setSceneValue('')
					setCharacterValue([])
					setSettingValue([])
					setImageStyles('')
					setTitleModal(false)
					dispatch(removeLoadingFlag('set-image'));
					if (query.get('page') === 'pages') {
						setImagesToRenderFunc()
						setLoading(false)
					} else {
						setSearchParams({...params, item: cardsToRenderLength})
						setCardsToRenderFunc();
					}
					isSaveButtonClicked.current = false
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('set-image'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						
					}
					alert.show(
						`Something went wrong, could not add illustrations.`,
						{ type: 'error' }
					);
					setLoading(false)
					isSaveButtonClicked.current = false
				});
		} else {
			if (query.get('page') !== 'pages') {
				dispatch(addLoadingFlag('set-image'));
			}
			setLoading(true)
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/set-image/' +
						projectId(),
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					const params = {};
					for(let entry of searchParams.entries()) {
					  params[entry[0]] = entry[1];
					}
			
					alert.show('New image was successfully saved!', {
						type: 'success',
					});
					setTitle('')
					setGeneratedLink('')
					setPrompt('')
					dispatch(removeLoadingFlag('set-image'));
					if (query.get('page') === 'pages') {
						setImagesToRenderFunc()
						setLoading(false)
					}
					if (query.get('page') === 'characters' || query.get('page') === 'setting') {
						//setImagesToRenderFunc()
						setLoading(false)
					} else {
						setSearchParams({...params, item: cardsToRenderLength})
						setCardsToRenderFunc();
					}
					isSaveButtonClicked.current = false
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('set-image'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
					alert.show(
						`Something went wrong, could not add illustrations.`,
						{ type: 'error' }
					);
					setLoading(false)
					isSaveButtonClicked.current = false
				});
		}
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
		
				<Box
					component='form'
					noValidate
					sx={style}
				>
				 <>
				 {titleModal && 
					<Modal
					open={titleModal}
					onClose={()=> setTitleModal(false)}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box
						component='form'
						noValidate
						sx={style}
					>
						<Typography sx={{ m: 0, p: 2 }}>SAVE IMAGE</Typography>
						<IconButton
							aria-label='close'
							onClick={()=> setTitleModal(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						{loading && <ModalLoader loading={loading} />}
						<TextField
							margin='normal'
							required
							fullWidth
							id='title'
							label='Title'
							name='title'
							autoFocus
							placeholder='Name Your Image'
							value={title}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setTitle(e.target.value)}
							onKeyDown={(ev) => {
								if (ev.key === 'Enter') {
									ev.preventDefault();  // Prevent form submission
									if (title.trim().length > 0) {
										handleSaveImage();  // Call the save function
									}
								}
							}}
						/>
						<Button
							onClick={() => handleSaveImage()}
							fullWidth
							disabled={title.trim().length === 0}
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Save
						</Button>
					</Box>
					
				</Modal>
				 }
				
				{generationMode === null && <>
					<Typography sx={{ m: 0, p: 2 }}>CHOOSE IMAGE GENERATION MODE</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					
					<Button
						onClick={() => {
							setGenerationMode('1')
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Manual
					</Button>
					<Button
						onClick={() => {
							setGenerationMode('2')
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						From a Scene
					</Button>
					<Button
						onClick={() => {
							setGenerationMode('3')
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						From a Character or Setting
					</Button>
				</>}
				{generationMode === '1' && <>
					<Typography sx={{ m: 0, p: 2 }}>GENERATE IMAGE</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
						}}
					>
						{loading && <ModalLoader loading={loading} />}
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Style
							</InputLabel>
							<Select
								value={imageStyles}
								onChange={(e)=> setImageStyles(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose style'
								//fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 300,  
											maxWidth: '100%',
											overflow: 'auto', 
										},
									},
								}}
							>
								{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
							</Select>
						</FormControl>
						<TextField
							margin='normal'
							required
							name='prompt'
							label='Describe what should be in the image:'
							id='prompt'
							placeholder='For best results, focus on the physical attributes of your characters and the scene.'
							fullWidth
							minRows={4}
							maxRows={12}
							multiline
							value={prompt}
							onChange={(e) => {
								setPrompt(e.target.value);
							}}
							InputLabelProps={{ shrink: true }}
							onKeyPress={(ev) => {
								// console.log(`Pressed keyCode ${ev.key}`);
								if (ev.key === 'Enter') {
								  ev.preventDefault();
								}
							  }}
						/>

						{generatedLink.length > 1 && 
							<Box sx={{
								display: 'flex',
								justifyContent: 'center'
							}}>
								<CardMedia
									component="img"
									sx={{
										maxWidth: '90%',
										height: 'auto',
									}}
									image={generatedLink}
									alt={'generated image'}
									onError={(e) => {
										e.target.src = placeholder;
									}}
								/>	
							</Box>
						}
					</Box>
					<Tooltip arrow title={`AI Tokens: ${oneImageGenerationCost}`}>
					<Button
						onClick={() => handleSubmit()}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						generate
					</Button>
					</Tooltip>
				</>}
				{generationMode === '2' && <>
					<Typography sx={{ m: 0, p: 2 }}>GENERATE IMAGE</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
						}}
					>
						{loading && <ModalLoader loading={loading} />}
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Style
							</InputLabel>
							<Select
								value={imageStyles}
								onChange={(e)=> setImageStyles(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose style'
								fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Story
							</InputLabel>
							<Select
								value={storyValue}
								onChange={(e)=> setStoryValue(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose story'
								fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								{stories?.map(item => <MenuItem value={item?.story?.id}>{item?.story?.title}</MenuItem>)}
							</Select>
						</FormControl>
						{storyValue !== '' && 
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose act 
							</InputLabel>
							<Select
								value={actValue}
								onChange={(e)=> setActValue(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose act'
								fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								{stories?.find(item => item?.story?.id === storyValue)?.story?.acts?.map((item,index) => <MenuItem value={index}>Act {item?.act_number + 1}</MenuItem>)}
							</Select>
						</FormControl>
						}
						{actValue !== '' && 
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose scene *
							</InputLabel>
							<Select
								value={sceneValue}
								onChange={(e)=> setSceneValue(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose scene'
								fullWidth
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								{stories?.find(item => item?.story?.id === storyValue)?.story?.acts[actValue]?.scenes?.map((scene,sceneIndex) => <MenuItem value={scene?.id}>Scene {scene?.scene_number + 1}</MenuItem>)}
							</Select>
						</FormControl>
						}
						<TextField
							margin='normal'
							required
							name='prompt'
							label='Describe what should be in the image:'
							id='prompt'
							placeholder='For best results, focus on the physical attributes of your characters and the scene.'
							fullWidth
							minRows={4}
							maxRows={12}
							multiline
							value={prompt}
							onChange={(e) => {
								setPrompt(e.target.value);
							}}
							InputLabelProps={{ shrink: true }}
							onKeyPress={(ev) => {
								// console.log(`Pressed keyCode ${ev.key}`);
								if (ev.key === 'Enter') {
								  ev.preventDefault();
								}
							  }}
						/>
						

						{generatedLink.length > 1 && 
							<Box sx={{
								display: 'flex',
								justifyContent: 'center'
							}}>
								<CardMedia
									component="img"
									sx={{
										maxWidth: '90%',
										height: 'auto',
									}}
									image={generatedLink}
									alt={'generated image'}
									onError={(e) => {
										e.target.src = placeholder;
									}}
								/>	
							</Box>
						}
					</Box>
					<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen}`}>
					<Button
						onClick={() => handleSubmit()}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						generate
					</Button>
					</Tooltip>
				</>}
				{generationMode === '3' && <>
					<Typography sx={{ m: 0, p: 2 }}>GENERATE IMAGE:</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '60vh',
							overflow: 'scroll',
							paddingRight: '8px',
						}}
					>
						{loading && <ModalLoader loading={loading} />}
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Style
							</InputLabel>
							<Select
								value={imageStyles}
								onChange={(e)=> setImageStyles(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose style'
								fullWidth
								notched={true}
								//InputLabelProps={{ shrink: true }}
							>
								{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Characters
							</InputLabel>
							<Select
								value={characterValue}
								onChange={(e)=> setCharacterValue(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose characters'
								fullWidth
								multiple
								notched={true}
								//InputLabelProps={{ shrink: true }}
							>
								{characters?.map(item => <MenuItem value={item?.character?.id}>{item?.character?.name}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: "10px" }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
								shrink={true}
							>
								Choose Settings
							</InputLabel>
							<Select
								value={settingValue}
								onChange={(e)=> setSettingValue(e.target.value)}
								labelId='test-select-label'
								variant='outlined'
								label='Choose settings'
								fullWidth
								multiple
								notched={true}
								InputLabelProps={{ shrink: true }}
							>
								{setting?.map(item => <MenuItem value={item?.settingItem?.id}>{item?.settingItem?.title}</MenuItem>)}
							</Select>
						</FormControl>
						<TextField
							margin='normal'
							required
							name='prompt'
							label='Describe what should be in the image:'
							id='prompt'
							placeholder='For best results, focus on the physical attributes of your characters and the scene.'
							fullWidth
							minRows={4}
							maxRows={12}
							multiline
							value={prompt}
							onChange={(e) => {
								setPrompt(e.target.value);
							}}
							InputLabelProps={{ shrink: true }}
							onKeyPress={(ev) => {
								// console.log(`Pressed keyCode ${ev.key}`);
								if (ev.key === 'Enter') {
								  ev.preventDefault();
								}
							  }}
						/>
						

						{generatedLink.length > 1 && 
							<Box sx={{
								display: 'flex',
								justifyContent: 'center'
							}}>
								<CardMedia
									component="img"
									sx={{
										maxWidth: '90%',
										height: 'auto',
									}}
									image={generatedLink}
									alt={'generated image'}
									onError={(e) => {
										e.target.src = placeholder;
									}}
								/>	
							</Box>
						}
					</Box>
					<Tooltip arrow title={`AI Tokens: ${oneImageGenerationCost}`}>
					<Button
						onClick={() => handleSubmit()}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						generate
					</Button>
					</Tooltip>
				</>}
					{
						generatedLink && 
						<Button
							onClick={() => setTitleModal(true)}
							fullWidth
							variant='contained'
							sx={{ mt: 0, mb: 2, color: '#fff' }}
						>
							Save
						</Button>
					}
					
					</>
				</Box>
			</Modal>
		</div>
	);
}
