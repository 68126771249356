import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  AppBar,
  Toolbar,
  Chip,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import logoLong from '../../assets/images/logo-long.png';
import blogPosts from './BlogPosts'; // Import the blogPosts data

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline-block',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}));

const Blog = () => {
  return (
    <>
       {/* React Helmet for OG and SEO Meta Tags */}
       <Helmet>
                <title>Storybuilt.AI Blog - Discovering AI-Assisted Writing and Publishing</title>
                <meta name="description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Storybuilt.AI - Unleash Your Creative Potential" />
                <meta property="og:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.storybuilt.ai/" />
                <meta property="og:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Storybuilt.AI - Unleash Your Creative Potential" />
                <meta name="twitter:description" content="AI-Powered Writing for Fiction, Non-Fiction, and Beyond. Transform your creativity and productivity." />
                <meta name="twitter:image" content="https://www.storybuilt.ai/assets/images/logo-long.png" />
                
                {/* Additional Meta Tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.storybuilt.ai/" />
            </Helmet>

      <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, mt: 1 }}>
            <Link to="/" aria-label="Storybuilt.AI Home" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box component="img" src={logoLong} alt="Storybuilt.AI Logo" sx={{ height: { xs: '45px', md: '80px' } }} />
            </Link>
          </Typography>
          <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
            <Button
              variant="contained"
              component={Link}
              to="/register"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 40px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Join
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              color="secondary"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: { xs: '1rem', md: '1.25rem' },
                padding: { xs: '8px 16px', md: '12px 24px' },
                height: { xs: '45px', md: '55px' },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <main>
        <Container 
          maxWidth="lg" 
          sx={{ 
            mb: 10, // Add margin to the bottom
            px: { xs: 2, sm: 3, md: 4 }, // Add responsive horizontal padding
          }}
        >
          {/* Hero Section */}
          <Box component="section" sx={{ my: 8, textAlign: 'center' }}>
            <GradientTypography variant="h1" component="h1" gutterBottom>
              Storybuilt.AI Blog
            </GradientTypography>
            <Typography variant="h2" sx={{ mb: 6, fontSize: '1.5rem' }}>
              Insights, tips, and stories about AI-assisted writing and publishing
            </Typography>
          </Box>

          {/* Blog Posts Grid */}
          <Grid component="section" container spacing={4} aria-label="Blog posts">
            {blogPosts.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post.id}>
                <article>
                  <StyledCard
                    component={Link}
                    to={`/blog/${post.url}`}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={post.image}
                      alt={post.title}
                      aria-hidden="true"
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h3" component="h2" sx={{ fontSize: '1.25rem' }}>
                        {post.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {post.excerpt}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, mb: 1 }}>
                          {Array.isArray(post.category) ? (
                            post.category.map((cat, index) => (
                              <Chip key={index} label={cat} color="primary" size="small" />
                            ))
                          ) : (
                            <Chip label={post.category} color="primary" size="small" />
                          )}
                        </Stack>
                        <time dateTime={post.date}>
                          <Typography variant="caption" color="text.secondary">
                            {new Date(post.date).toLocaleDateString()}
                          </Typography>
                        </time>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </article>
              </Grid>
            ))}
          </Grid>

          {/* Load More Button removed */}
        </Container>
      </main>

      {/* Structured Data for SEO */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Blog',
            name: 'Storybuilt.AI Blog',
            description: 'Insights, tips, and stories about AI-assisted writing and publishing',
            url: 'https://storybuilt.ai/blog',
            publisher: {
              '@type': 'Organization',
              name: 'Storybuilt.AI',
              logo: {
                '@type': 'ImageObject',
                url: 'https://storybuilt.ai/logo.png',
              },
            },
            blogPost: blogPosts.map((post) => ({
              '@type': 'BlogPosting',
              headline: post.title,
              description: post.excerpt,
              datePublished: post.date,
              image: post.image,
              url: `https://storybuilt.ai/blog/${post.url}`,
              author: {
                '@type': 'Organization',
                name: 'Storybuilt.AI',
              },
            })),
          })}
        </script>
      </Helmet>
    </>
  );
};

export default Blog;
