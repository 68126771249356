import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    sm: '400px'
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function DeleteScene({open,setOpen,sceneIndex, deleteFunc, text}) {
  const handleClose = () => setOpen(false);
  const stateLoading = useSelector((state) => state.loading.loading);

  const handleSubmit = async (e) => {
    if (stateLoading) {
      return
    }
    deleteFunc()
    handleClose()
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" noValidate sx={style}>
        <Typography textAlign='center' sx={{ m: 0, p: 2 }}>
          DELETE: "{text} {sceneIndex + 1}"?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{
          display: 'flex', gap: '10px', justifyContent: 'center'
        }}>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff' }}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            
            variant="contained"
            sx={{ mt: 3, mb: 2, color: '#fff' }}
          >
            No
          </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}