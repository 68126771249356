import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, Typography, TextField, Button, List, ListItem, 
  ListItemText, IconButton, Dialog, DialogTitle, DialogContent, 
  DialogActions, Box, Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

const VersionControl = () => {
  const [versions, setVersions] = useState([]);
  const [newVersion, setNewVersion] = useState('');
  const [newNotes, setNewNotes] = useState(['']);
  const [editingVersion, setEditingVersion] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);

  const debouncedResize = useCallback(
    debounce(() => {
      setVersions([...versions]);
    }, 100),
    [versions]
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [debouncedResize]);

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      const response = await axios.get(`${constants.BASE_URL}api/auth/version-history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVersions(response.data);
    } catch (error) {
      console.error('Error fetching versions:', error);
      setError('Failed to fetch versions. Please try again.');
    }
  };

  const handleAddVersion = async () => {
    try {
      await axios.post(
        `${constants.BASE_URL}api/auth/admin/add-version`,
        { version: newVersion, updateNotes: newNotes.filter(note => note.trim() !== '') },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewVersion('');
      setNewNotes(['']);
      fetchVersions();
    } catch (error) {
      console.error('Error adding version:', error);
    }
  };

  const handleEditVersion = (version) => {
    setEditingVersion({...version, updateNotes: [...version.updateNotes]});
    setOpenDialog(true);
  };

  const handleUpdateVersion = async () => {
    try {
      await axios.put(
        `${constants.BASE_URL}api/auth/admin/update-version/${editingVersion._id}`,
        {...editingVersion, updateNotes: editingVersion.updateNotes.filter(note => note.trim() !== '')},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenDialog(false);
      fetchVersions();
    } catch (error) {
      console.error('Error updating version:', error);
    }
  };

  const handleDeleteVersion = async (id) => {
    try {
      await axios.delete(`${constants.BASE_URL}api/auth/admin/delete-version/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchVersions();
    } catch (error) {
      console.error('Error deleting version:', error);
    }
  };

  const handleAddNote = () => {
    setNewNotes([...newNotes, '']);
  };

  const handleRemoveNote = (index) => {
    setNewNotes(newNotes.filter((_, i) => i !== index));
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...newNotes];
    updatedNotes[index] = value;
    setNewNotes(updatedNotes);
  };

  const handleEditingNoteChange = (index, value) => {
    const updatedNotes = [...editingVersion.updateNotes];
    updatedNotes[index] = value;
    setEditingVersion({...editingVersion, updateNotes: updatedNotes});
  };

  const handleAddEditingNote = () => {
    setEditingVersion({
      ...editingVersion,
      updateNotes: [...editingVersion.updateNotes, '']
    });
  };

  const handleRemoveEditingNote = (index) => {
    setEditingVersion({
      ...editingVersion,
      updateNotes: editingVersion.updateNotes.filter((_, i) => i !== index)
    });
  };

  return (
    <Container maxWidth="md" sx={{marginTop: 10}}>
      <Typography variant="h4" gutterBottom>Version Control</Typography>
      <Box sx={{ mb: 4 }}>
        <TextField
          label="New Version"
          value={newVersion}
          onChange={(e) => setNewVersion(e.target.value)}
          fullWidth
          margin="normal"
        />
        {newNotes.map((note, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
            <TextField
              label={`Update Note ${index + 1}`}
              value={note}
              onChange={(e) => handleNoteChange(index, e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
            <IconButton onClick={() => handleRemoveNote(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1, mb: 2 }}>
          <Button startIcon={<AddIcon />} onClick={handleAddNote}>
            Add Note
          </Button>
        </Box>
        <Button variant="contained" onClick={handleAddVersion} fullWidth>
          Add New Version
        </Button>
      </Box>
      <List>
        {versions.map((version) => (
          <ListItem key={version._id} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditVersion(version)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteVersion(version._id)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText
              primary={`Version ${version.version}`}
              secondary={
                <Box mr={4}>
                  {version.updateNotes.map((note, index) => (
                    <Chip key={index} label={note} sx={{ m: 0.5 }} />
                  ))}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Version</DialogTitle>
        <DialogContent>
          <TextField
            label="Version"
            value={editingVersion?.version || ''}
            onChange={(e) => setEditingVersion({...editingVersion, version: e.target.value})}
            fullWidth
            margin="normal"
          />
          {editingVersion?.updateNotes.map((note, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                label={`Update Note ${index + 1}`}
                value={note}
                onChange={(e) => handleEditingNoteChange(index, e.target.value)}
                fullWidth
                margin="normal"
                multiline
                rows={2}
              />
              <IconButton onClick={() => handleRemoveEditingNote(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button startIcon={<AddIcon />} onClick={handleAddEditingNote}>
            Add Note
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleUpdateVersion}>Update</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default VersionControl;