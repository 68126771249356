import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import * as constants from '../../../helpers/constants';
import { setUser } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { Box, Typography, CircularProgress } from '@mui/material';
import logoLong from '../../../assets/images/logo-long.png';

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('register success')
    setTimeout(() => {
      setLoading(true);
    }, 5000);
    const sendUserDataToBackend = async () => {
      const userData = JSON.parse(sessionStorage.getItem('stripeUser'));
      if (userData) {
        const session_id = new URLSearchParams(location.search).get('session_id');
        console.log('session_id', session_id)
        try {
          const response = await axios.post(constants.BASE_URL + 'api/auth/stripe-subscription-update-webhook', {
            ...userData,
            session_id
          });

          // Clear session storage after use
          sessionStorage.removeItem('stripeUser');
          console.log('response', response)
          // Check for the auth token in the response
          const payload = {
            user: response.data.email,
            token: response.data.token,
            userId: response.data.userId,
          };
          console.log('payload:', payload);
            localStorage.setItem('email', response.data.email)
            localStorage.setItem('access_token', response.data.token)
            localStorage.setItem('userId', response.data.userId)
          dispatch(setUser(payload));
            // Navigate to the products page after a short delay to ensure data processing
            setTimeout(() => {
              navigate('/products');
            }, 200); // Adjust the delay as needed

        } catch (error) {
            console.error('Error:', error);
            }
        }
    }
    sendUserDataToBackend();
  }, [location, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        backgroundColor: '#000' // Optional background color
      }}
    >
      <Box
        component="img"
        width={300}
        src={logoLong}
        alt="Logo"
        sx={{ marginBottom: 2 }} // Space between image and text
      />
      {!loading ? (
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          mx={4}
        >
          Registration successful!
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="primary" />
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            mt={2}
          >
            Building your account now!
          </Typography><Typography
            variant="h6"
            align="center"
            color="textPrimary"
            mt={2}
          >
            Please wait...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Success;
