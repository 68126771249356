import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
    // CardActionArea,
    CardActions,
    Typography,
    Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChangeCharacter from '../modals/ChangeCharacter';
import DeleteCharacter from '../modals/DeleteCharacter';
import { SortableElement } from 'react-sortable-hoc';
import * as constants from '../../helpers/constants'

const CharacterCardGrid = SortableElement(({character, characterId,episodeIndex,onCardHandleClick,index})=> {
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeCharacterOpen, setChangeCharacterOpen] = useState(false);
    const [deleteCharacterOpen, setDeleteCharacterOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
            <Card
                sx={{p: 1, maxWidth: '186px', zIndex: '99999', cursor: 'pointer'}}
                onClick={() => onCardHandleClick()}
            >
                <div style={{position: 'relative'}}>

                <ChangeCharacter open={changeCharacterOpen} setOpen={setChangeCharacterOpen} character={character} characterId={characterId} episodeIndex={episodeIndex}/>
                <DeleteCharacter open={deleteCharacterOpen} setOpen={setDeleteCharacterOpen} character={character} characterId={characterId} episodeIndex={episodeIndex}/>

                {/* <CardActionArea> */}
                {(character?.image !== 'default') && (<Box
					
                            sx={(theme) => ({
								position: 'relative',
								height: 140,
								width: 140,
								background: `linear-gradient(to bottom left,  ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								cursor: 'pointer',
								overflow: 'hidden',
							})}
						>
							<CardMedia
								component='div'
								sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								objectFit: 'cover',
                                zIndex: 2,
								}}
								image={constants.IMAGE_URL + character?.image + '?time=' + character?.lastImageUpdate}
								alt={character?.alt}
							
							/>
                            <Typography
								sx={{
									position: 'absolute',
									top: '30%',
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									fontSize: '40px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									 {character?.name ? 
									(character.name.split(/\s/).slice(0, 3).map(word => word[0]).join('')) 
									: ''}
							</Typography>
						</Box>)}
                 {(character?.image === 'default') && (
							<Box sx={{
								position: 'relative',
							}}>
								<CardMedia
									component='div'
									sx={(theme) => ({
										height: 140,
								width: 140,
										background: `linear-gradient(to bottom left,  ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									})}
				
									alt={character?.alt}
								
								/>	
								<Typography
									sx={{
										position: 'absolute',
										top: '35%',
										left: '35%',
										fontSize: '40px',
										textTransform: 'uppercase',
										color: 'white'
									}}
								>
									{character?.name?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
								</Typography>
							</Box>
						)}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => setChangeCharacterOpen(true)}>
                        <Typography textAlign="center">Change</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteCharacterOpen(true)}>
                        <Typography textAlign="center">Delete</Typography>
                    </MenuItem>
                </Menu>
            
                <CardActions>
                    <Typography 
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                            textAlign: 'left',
                            width: '100%',
                            fontSize: '16px',
                            paddingTop: '10px',
                            // maxWidth: 'calc(100% - 20px)',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {character?.name}
                    </Typography>
                </CardActions>
                {/* </CardActionArea> */}
                <Tooltip>
                    <IconButton
                        onClick={handleOpen}
                        size="small"
                        sx={{ ml: 2,
                            position: 'absolute',
                            right: '0',
                            bottom: '6px',
                            display: 'none'
                        }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>
                            <MoreVertIcon style={{color: '#fff' }}/>
                        </Avatar>
                    </IconButton>
                </Tooltip>
                </div>
            </Card>
    );
})

export default CharacterCardGrid;
