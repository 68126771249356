import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ComicPageCard from '../../components/ComicPageCard';
import { useSelector } from 'react-redux';
import AddNewPage from '../../components/modals/AddNewPage';
import * as constants from '../../helpers/constants';
import ProjectHeader from '../../components/ProjectHeader';
import useQuery from '../../helpers/hooks/useQuery';
import CharacterCards from '../../components/CharacterCards';
import ImageCard from '../../components/ImageCard';
import AddNewCharacter from '../../components/modals/AddNewCharacter';
import SettingCard from '../../components/SettingCard';
import AddNewSettingItem from '../../components/modals/AddNewSettingItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AllCardsView from '../../components/modals/AllCardsView';
import AppsIcon from '@mui/icons-material/Apps';
import axios from 'axios';
import Loader from '../../components/Loader';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../store/slices/loadingSlice';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import ProjectInfo from '../ProjectInfo';
import EditCharacter from '../../components/FieldsEdit/EditCharacter';
import EditSetting from '../../components/FieldsEdit/EditSetting';
import EditStory from '../../components/FieldsEdit/EditStory';
import EditStoryboardFolder from '../../components/FieldsEdit/EditStoryboardFolder';
import StoryCard from '../../components/StoryCard';
import AddNewStory from '../../components/modals/AddNewStory';
import AddNewImage from '../../components/modals/AddNewImage';
import CardMedia from '@mui/material/CardMedia';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import EditPage from '../../components/FieldsEdit/EditPage';
import TabChangeModal from '../../components/modals/TabChangeModal';
import AddNewFolder from '../../components/modals/AddNewFolder';
import ImageFolderCard from '../../components/ImageFolderCard/ImageFolderCard';
import ChatModal from '../../components/modals/ChatModal';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';
import ArrowRight from '@mui/icons-material/TurnRight';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Project = () => {
	const query = useQuery();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [addNewPageOpen, setAddNewPageOpen] = useState(false);
	const [addNewCharOpen, setAddNewCharOpen] = useState(false);
	const [addNewStoryOpen, setAddNewStoryOpen] = useState(false);
	const [addNewImageOpen, setAddNewImageOpen] = useState(false);
	const [addNewFolderOpen, setAddNewFolderOpen] = useState(false);
	const [allCardsOpen, setAllCardsOpen] = useState(false);
	const [cardsToRender, setCardsToRender] = useState([]);
	const [addNewSettingItemOpen, setAddNewSettingItemOpen] = useState(false);
	const projectId = location.pathname.slice(9, location.pathname.length);
	const pageQueryValue = query.get('page');
	const episodesQueryValue = query.get('episodes');
	const itemRef = useRef(query.get('item'))
	const [itemState, setItemState] = useState(query.get('item'))
	const [pageRerenderFlag,setPageRerenderFlag] = useState(true)
	const token = useSelector((state) => state.auth.token);
	const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
	console.log('hideTokensFillModal',hideTokensFillModal)
	const [tokensFillModal, setTokensFillModal] = useState(false);
	const loading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const [currentProject, setCurrentProject] = useState({});
	const episodesArray = useRef([]);
	const navigate = useNavigate();
	const [setting, setSetting] = useState([]);
	const [characters, setCharacters] = useState([]);
	const [stories, setStories] = useState([]);
	const [contextStories, setContextStories] = useState([]);
	const [images, setImages] = useState([]);
	const doesCardHaveUnsavedData = useRef(false);
	const [openCardModal, setOpenCardModal] = useState(false);
	const [isProjectHeaderVisible, setIsProjectHeaderVisible] = useState(true)
	const currentLink = useRef();const [openChars, setOpenChars] = useState(false);
	const [openSettings, setOpenSettings] = useState(false);
	const [openStory, setOpenStory] = useState(false);

	const handleClickOpen = (type) => {
	  if (type === 'characters') setOpenChars(true);
	  else if (type === 'settings') setOpenSettings(true);
	  else if (type === 'story') setOpenStory(true);
	};
	
	const handleClose = () => {
	  setOpenChars(false);
	  setOpenSettings(false);
	  setOpenStory(false);
	};

	useEffect(() => {
		setItemState(query.get('item'))
		if (itemRef !== itemState) {
			setPageRerenderFlag(prev => !prev)
		}
	},[query.get('item')])

	useEffect(()=> {
		doesCardHaveUnsavedData.current = false
	},[pageQueryValue])

	const handleProjectHeaderVisibility = (flag) => {
		const pageWidth = window.innerWidth
		if(pageWidth < 600) {
			setIsProjectHeaderVisible(flag)
		} else {
			setIsProjectHeaderVisible(true)
		}
	}

	const handleExportPdf = async () => {
		const items = [...cardsToRender];
		const currentItemIndex = query.get('item') !== null && query.get('item') !== 'null' ? query.get('item') : 0;
		const currentItem = cardsToRender[currentItemIndex];
		
		if (query.get('page') === 'story') {
			const itemsModifiedCharacters = updateCharactersDeep(items,'characters', characters)
			const itemsModifiedSummaryCharacters = updateCharactersDeep(itemsModifiedCharacters,'summaryCharacters', characters)
			const itemsModifiedSummarySettingAndCharacters = updateSettingDeep(itemsModifiedSummaryCharacters,'summarySetting', setting)
			const itemsModifiedSettingAndCharacters = updateSettingDeep(itemsModifiedSummarySettingAndCharacters,'setting', setting)
			const content = formatItemAsStory(currentItem, currentProject);

			const documentDefinition = { content };
	
			const pdfDoc = pdfMake.createPdf(documentDefinition);
			pdfDoc.download('output.pdf');

		} else {
			const content = [];

			for (const item of items) {
				const formattedJSON = JSON.stringify(item, null, '\u200B\t');
		  
				content.push({ text: formattedJSON, font: 'Roboto', fontSize: 12, margin: [0, 0, 0, 10] });
			  }
	
			const documentDefinition = {
				content,
			};
	
			const pdfDoc = pdfMake.createPdf(documentDefinition);
			pdfDoc.download('output.pdf');
		}
	
	};
	
	function formatItemAsStory(item) {
		const styledContent = [];

		if (currentProject && currentProject.title) {
			styledContent.push({
				text: currentProject.title.toUpperCase(),
				font: 'Roboto',
				fontSize: 18, // Adjust fontSize as needed
				bold: true,
				decoration: 'underline',
				margin: [0, 200, 0, 0], // Adjust margin as needed
				alignment: 'center',
			});
		}
		if (currentProject && currentProject.tagline) {
			styledContent.push({
				text: currentProject.tagline,
				font: 'Roboto',
				fontSize: 14, // Adjust fontSize as needed
				italics: true,
				alignment: 'center',
				pageBreak: 'after',
				margin: [0, 0] // Adjust margin as needed
			});
		}
		if (item.story.title) {
			styledContent.push({ 
				text: item.story.title.toUpperCase(), 
				font: 'Roboto',
				fontSize: 16, 
				bold: true, 
				alignment: 'center',
				decoration: 'underline',
				margin: [0, 10]
			});
		}
		if (item.story.summary) {
			styledContent.push({ 
				text: item.story.summary, 
				font: 'Roboto',
				fontSize: 14, 
				bold: false, 
				margin: [10, 10]
			});
		}
		if (item.story.list_of_acts) {
			styledContent.push({ 
				text: item.story.list_of_acts, 
				font: 'Roboto',
				fontSize: 14, 
				margin: [10, 10]
			});
		}
	
		if (item.story.acts && item.story.acts.length > 0) {
			item.story.acts.forEach(act => {
				if (act.scenes && act.scenes.length > 0) {
					act.scenes.forEach(scene => {
						styledContent.push({ 
							text: scene.scene_full_script, 
							font: 'Roboto',
							fontSize: 14, 
							margin: [0, 10]
						});
					});
				}
			});
		}
	
		return styledContent;
	}
	
	

	function updateCharactersDeep(obj, fieldName, newValue) {
		if (Array.isArray(obj)) {
		  return obj.map((item) => updateCharactersDeep(item, fieldName, newValue));
		} else if (typeof obj === 'object' && obj !== null) {
		  const updatedObj = { ...obj };
		  for (const key in updatedObj) {
			updatedObj[key] = updateCharactersDeep(updatedObj[key], fieldName, newValue);
		  }
		  if (updatedObj.hasOwnProperty(fieldName)) {
			const newCharactersArray = updatedObj[fieldName]?.reduce((accumulator,charId) => {
				const character = newValue?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character.character.name]
			},[])
			updatedObj[fieldName] = newCharactersArray
		  }
		  return updatedObj;
		} else {
		  return obj;
		}
	  }
	  
	function updateSettingDeep(obj, fieldName, newValue) {
		if (Array.isArray(obj)) {
		  return obj.map((item) => updateSettingDeep(item, fieldName, newValue));
		} else if (typeof obj === 'object' && obj !== null) {
		  const updatedObj = { ...obj };
		  for (const key in updatedObj) {
			updatedObj[key] = updateSettingDeep(updatedObj[key], fieldName, newValue);
		  }
		  if (updatedObj.hasOwnProperty(fieldName)) {
			const newSettingArray = updatedObj[fieldName]?.reduce((accumulator, setId) => {
				const settingItem = newValue?.find(item => item?.settingItem?.id === setId);
				return [...accumulator, settingItem.settingItem.title];
			}, [])
			updatedObj[fieldName] = newSettingArray
		  }
		  return updatedObj;
		} else {
		  return obj;
		}
	  }
	  
	const setImagesToRenderFunc = async () => {
		const imagesToRenderArr = [];
		const cardsToRenderArr = [];
	
		await getImages(imagesToRenderArr,cardsToRenderArr)
		if (episodesQueryValue !== '0') {
			// await getEpisodeImages(imagesToRenderArr,cardsToRenderArr)
		}

		setImages(imagesToRenderArr)
	}

	const setCardsToRenderFunc = useCallback(async () => {
		dispatch(addLoadingFlag('get-data'));
		const cardsToRenderArr = [];
		const settingToRenderArr = [];
		const charactersToRenderArr = [];
		const storiesToRenderArr = [];
		const imagesToRenderArr = [];

		if (pageQueryValue === 'project-info') {
			dispatch(removeLoadingFlag('get-data'));
			setCardsToRender([]);
			return;
		  }

		if (pageQueryValue === 'story') {
			dispatch(addLoadingFlag('get-stories'));
			
			// if (episodesQueryValue !== '0') {
			// 	dispatch(addLoadingFlag('get-episode-stories'));
			// }
			await getStories()

			/* Pages current episode render */
			if (episodesQueryValue !== '0') {
				// await getEpisodeStories()
			}
		}
		if (pageQueryValue === 'pages') {
			/* Pages common render */
			dispatch(addLoadingFlag('get-pages'));

			// if (episodesQueryValue !== '0') {
			// 	dispatch(addLoadingFlag('get-episode-pages'));
			// }
				
				await getPages();
		
			/* Pages current episode render */
			if (episodesQueryValue !== '0') {
				
				// await getEpisodePages()

			}
		}
		if (pageQueryValue === 'images') {
			
			dispatch(addLoadingFlag('get-images'));
			// if (episodesQueryValue !== '0') {
			// 	dispatch(addLoadingFlag('get-episode-images'));
			// }
			await getImagesTab()

			/* Pages current episode render */
			if (episodesQueryValue !== '0') {

				// await getEpisodeImagesTab()

			}

		}
		if (pageQueryValue === 'characters') {
			/* characters common render */
			// if (episodesQueryValue !== '0') {
			// 	dispatch(addLoadingFlag('get-episode-characters'));
			// }
			dispatch(addLoadingFlag('get-characters'));
			await getCharacters()
			
			/* characters current episode render */
			if (episodesQueryValue !== '0') {
				// await getEpisodeCharacters()
			}
		}
		if (pageQueryValue === 'setting') {
			/* setting common render */
			dispatch(addLoadingFlag('get-setting'));
			// if (episodesQueryValue !== '0') {
			// 	dispatch(addLoadingFlag('get-episode-setting'));
			// }
			await getSetting()
			/* setting current episode render */
			if (episodesQueryValue !== '0') {
				// await getEpisodeSetting()
			}
		}
		if (pageQueryValue === 'folders') {

			dispatch(addLoadingFlag('get-folders'));
	
			await getFolders()

		}
		async function getCharacters () {
			await axios
			.get(
				constants.BASE_URL +
					'api/projects/get-characters/' +
					projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
					charactersToRenderArr.push(
						...response.data.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					dispatch(removeLoadingFlag('story-characters'))
				} else {
					cardsToRenderArr.push(
						...response.data.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
				}
				
				dispatch(removeLoadingFlag('get-characters'));
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('get-characters'));
				if (error.response?.data === 'User does not exist.') {
					navigate('login');
					dispatch(logout());
				}
			});
		}
		
		async function getPages () {
			await axios
				.get(
					constants.BASE_URL + 'api/projects/get-pages/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					cardsToRenderArr.push(
						...response.data?.pages.map((page, index) => {
							return {
								page: page,
								imageIndex: index,
							};
						})
					);
					storiesToRenderArr.push(
						...response.data?.stories.map((story, index) => {
							return {
								story: story,
								characterId: index,
							};
						})
					);
					settingToRenderArr.push(
						...response.data?.setting.map((settingItem, index) => {
							return {
								settingItem: settingItem,
								settingItemId: index,
							};
						})
					);
					charactersToRenderArr.push(
						...response.data?.characters.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					const filteredData = response.data?.images.filter(item => item !== null)
					
					imagesToRenderArr.push(
						...filteredData.map((image, index) => {
							return {
								image: image,
								imageIndex: index,
							};
						})
					);
					dispatch(removeLoadingFlag('get-pages'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-pages'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}

		async function getEpisodePages () {
			 	await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-pages/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.pages.map((page, index) => {
								return {
									page: page,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
						const filteredData = response.data?.images.filter(item => item !== null)
						
						imagesToRenderArr.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						dispatch(removeLoadingFlag('get-episode-pages'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-pages'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}
		 async function getStories () {
			 await axios
				.get(
					constants.BASE_URL + 'api/projects/get-stories/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					const fetchedStories = response.data?.stories || [];
					setContextStories(fetchedStories);
					cardsToRenderArr.push(
						...response.data?.stories.map((story, index) => {
							return {
								story: story,
								imageIndex: index,
							};
						}).filter(item => item?.story !== null)

					); 
					settingToRenderArr.push(
						...response.data?.setting.map((settingItem, index) => {
							return {
								settingItem: settingItem,
								settingItemId: index,
							};
						})
					);
					charactersToRenderArr.push(
						...response.data?.characters.map((character, index) => {
							return {
								character: character,
								characterId: index,
							};
						})
					);
					dispatch(removeLoadingFlag('get-stories'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-stories'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
			}
		
		 async function getEpisodeStories () {
			 await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-stories/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									imageIndex: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
						dispatch(removeLoadingFlag('get-episode-stories'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-stories'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}

		async function getEpisodeCharacters () {
			await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-characters/' +
							projectId +
							'/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
							charactersToRenderArr.push(
								...response.data.map((character, index) => {
									return {
										character: character,
										characterId: index,
									};
								})
							);
							dispatch(addLoadingFlag('story-episode-characters'))
						} else {
							cardsToRenderArr.push(
								...response.data.map((character, index) => {
									return {
										character: character,
										characterId: index,
									};
								})
							);
						}
						dispatch(removeLoadingFlag('get-episode-characters'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get--episodecharacters'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}

		async function getSetting () {
			 await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-setting/' +
						projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
						settingToRenderArr.push(
							...response.data?.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						dispatch(addLoadingFlag('story-setting'))
					} else {
						cardsToRenderArr.push(
							...response.data?.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
					}
					dispatch(removeLoadingFlag('get-setting'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-setting'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});

		}
		async function getFolders () {
			 await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-folders/' +
						projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						cardsToRenderArr.push(
							...response.data?.map((folder, index) => {
								return {
									folder: folder,
									folderId: index,
								};
							})
						);
					
					dispatch(removeLoadingFlag('get-folders'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-folders'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}

		 async function getEpisodeSetting () {
			 await axios
					.get(
						constants.BASE_URL +
							'api/projects/get-episode-setting/' +
							episodesArray.current[
								parseInt(episodesQueryValue) - 1
							]?.id,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						if (pageQueryValue === 'story' || pageQueryValue === 'images' || pageQueryValue === 'pages') {
							settingToRenderArr.push(
								...response.data?.map((settingItem, index) => {
									return {
										settingItem: settingItem,
										settingItemId: index,
									};
								})
							);
							dispatch(addLoadingFlag('story-episode-setting'))
						} else {
							cardsToRenderArr.push(
								...response.data?.map((settingItem, index) => {
									return {
										settingItem: settingItem,
										settingItemId: index,
									};
								})
							);
						}
						dispatch(removeLoadingFlag('get-episode-setting'));
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('get-episode-setting'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					});
		}
		async function getImagesTab () {
			await axios
				.get(
					constants.BASE_URL + 'api/projects/get-images/' + projectId,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						const filteredData = response.data?.images.filter(item => item !== null)
						cardsToRenderArr.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
					dispatch(removeLoadingFlag('get-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-images'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}	
		async function getEpisodeImagesTab () {
			await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-episode-images/' +
						projectId +
						'/' +
						episodesArray.current[
							parseInt(episodesQueryValue) - 1
						]?.id,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
						const filteredData = response.data?.images.filter(item => item !== null)
						cardsToRenderArr.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
						storiesToRenderArr.push(
							...response.data?.stories.map((story, index) => {
								return {
									story: story,
									characterId: index,
								};
							})
						);
						settingToRenderArr.push(
							...response.data?.setting.map((settingItem, index) => {
								return {
									settingItem: settingItem,
									settingItemId: index,
								};
							})
						);
						charactersToRenderArr.push(
							...response.data?.characters.map((character, index) => {
								return {
									character: character,
									characterId: index,
								};
							})
						);
					dispatch(removeLoadingFlag('get-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-images'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
		}	

		setSetting(settingToRenderArr?.sort((a, b) => parseInt(a?.settingItem?.order) - parseInt(b?.settingItem?.order)));
		setCharacters(charactersToRenderArr?.sort((a, b) => parseInt(a?.character?.order) - parseInt(b?.character?.order)));
		setStories(storiesToRenderArr?.sort((a, b) => parseInt(a?.story?.order) - parseInt(b?.story?.order)));
		setImages(imagesToRenderArr?.sort((a, b) => parseInt(a?.image?.order) - parseInt(b?.image?.order)))
		
		if (pageQueryValue === 'story') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.story?.order) - parseInt(b?.story?.order)));
		}
		if (pageQueryValue === 'pages') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.page?.order) - parseInt(b?.page?.order)));
		}
		if (pageQueryValue === 'images') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.image?.order) - parseInt(b?.image?.order)));
		}
		if (pageQueryValue === 'characters') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.character?.order) - parseInt(b?.character?.order)));
		}
		if (pageQueryValue === 'setting') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.settingItem?.order) - parseInt(b?.settingItem?.order)));
		}
		if (pageQueryValue === 'folders') {
			setCardsToRender(cardsToRenderArr?.sort((a, b) => parseInt(a?.folder?.order) - parseInt(b?.folder?.order)));
		}

	}, [
		token,
		pageQueryValue,
		episodesQueryValue,
		projectId,
		navigate,
	]);

	async function getImages (imagesToRender,cardsToRenderArr) {
		await axios
			.get(
				constants.BASE_URL + 'api/projects/get-images/' + projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
					const filteredData = response.data?.images.filter(item => item !== null)
					if (pageQueryValue === 'pages') {
						imagesToRender.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					} else {
						cardsToRenderArr.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					}
				dispatch(removeLoadingFlag('get-images'));
			})
			.catch(function (error) {
				dispatch(removeLoadingFlag('get-images'));
				if (error.response?.data === 'User does not exist.') {
					navigate('login');
					dispatch(logout());
				}
			});
	}		
	
	async function getEpisodeImages (imagesToRender,cardsToRenderArr) {
		await axios
				.get(
					constants.BASE_URL +
						'api/projects/get-episode-images/' +
						projectId +
						'/' +
						episodesArray.current[
							parseInt(episodesQueryValue) - 1
						]?.id,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					const filteredData = response.data.filter(item => item !== null)
					if (pageQueryValue === 'pages') {
						imagesToRender.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					} else {
						cardsToRenderArr.push(
							...filteredData.map((image, index) => {
								return {
									image: image,
									imageIndex: index,
								};
							})
						);
					}						
					dispatch(removeLoadingFlag('get-episode-images'));
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('get-episode-images'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				});
	}

	const setEpisodesToRender = useCallback(async () => {
		await setCardsToRenderFunc();
		// dispatch(addLoadingFlag('get-episodes'));
		// await axios
		// 	.get(
		// 		constants.BASE_URL + 'api/projects/get-episodes/' + projectId,
		// 		{
		// 			headers: {
		// 				Authorization: `Bearer ${token}`,
		// 			},
		// 		}
		// 	)
		// 	.then(async function (response) {
		// 		episodesArray.current = response.data;
		// 		await setCardsToRenderFunc();
		// 		dispatch(removeLoadingFlag('get-episodes'));
		// 	})
		// 	.catch(function (error) {
		// 		dispatch(removeLoadingFlag('get-episodes'));
		// 		if (error.response?.data === 'User does not exist.') {
		// 			navigate('login');
		// 			dispatch(logout());
		// 		}
		// 	});
	}, [token, projectId, dispatch, setCardsToRenderFunc, navigate]);

	useEffect(() => {
		axios
			.get(constants.BASE_URL + 'api/projects/' + projectId, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(async function (response) {
				await setCurrentProject(response.data);
			})
			.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login');
					dispatch(logout());
				}
				if (error.response?.status === 404) {
					navigate('gallery');
					// dispatch(logout());
				}
			});

		setEpisodesToRender();
	}, [dispatch, projectId, token, setEpisodesToRender, navigate]);

	useEffect(() => {
		setTimeout(()=> dispatch(removeLoadingFlag('get-data')),0)
	},[cardsToRender])

	const onCardHandleClick = () => {
		const params = {};
		for (let entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setSearchParams({ ...params, item: currentLink.current });
	};

	function handleDragEnd(result) {
		if (!result.destination) return;
		const reorderedItems = [...cardsToRender];
		const [removed] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, removed);

		setCardsToRender(reorderedItems);

		if (pageQueryValue === 'story') {
			const changeStoriesOrderInDataBase = async (stories) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-stories-order' , {
					stories,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeStoriesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.story.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'pages') {
			const changePagesOrderInDataBase = async (pages) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-pages-order' , {
					pages,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changePagesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.page.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'images') {
			const changeImagesOrderInDataBase = async (images) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-images-order' , {
					images,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeImagesOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.image.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'characters') {
			const changeCharactersOrderInDataBase = async (characters) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-characters-order' , {
					characters,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeCharactersOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.character.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'setting') {
			const changeSettingsOrderInDataBase = async (settings) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-settings-order' , {
					settings,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeSettingsOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.settingItem.id,
					order: indx
				}
			}))
		}
		if (pageQueryValue === 'folders') {
			const changeFoldersOrderInDataBase = async (folders) => {
				await axios.post(constants.BASE_URL + 'api/projects/update-folders-order' , {
					folders,
				}, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
				} )
				.then(function (response) {

				})
				.catch(function (error) {
				if (error.response?.data === 'User does not exist.') {
					navigate('login')
					dispatch(logout())
				} 
				});
			}
			changeFoldersOrderInDataBase(reorderedItems.map((item,indx) => {
				return {
					id: item.folder.id,
					order: indx
				}
			}))
		}
	}

	const onNavigateLinkClick = (link) => {
		currentLink.current = link
		
		if (doesCardHaveUnsavedData.current) {
			setOpenCardModal(true)
		} else {
			onCardHandleClick()
		}
	};
	/* useEffect(() => {
		if (query.get('page') === 'landing-page') {
			dispatch(removeLoadingFlag('get-data'));
		}
	}, [query.get('page'), dispatch]); */
	return (
		<>
		{loading && <Loader />}
		<Box sx={{ display: 'flex', overflow: 'hidden' }}>
			<ProjectHeader
				episodesArray={episodesArray.current}
				currentProject={currentProject}
				setEpisodesToRender={setEpisodesToRender}
				handleExportPdf={handleExportPdf}
				doesCardHaveUnsavedData={doesCardHaveUnsavedData}
				isProjectHeaderVisible={isProjectHeaderVisible}
			/>
			{/* Modals */}
			<ChatModal 
				currentProject={currentProject} 
				setTokensFillModal={setTokensFillModal} 
			/>
			<AllCardsView
				open={allCardsOpen}
				setOpen={setAllCardsOpen}
				cardsToRender={cardsToRender}
				setCardsToRender={setCardsToRender}
			/>
			<AddNewPage
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewPageOpen}
				setOpen={setAddNewPageOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewCharacter
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewCharOpen}
				setOpen={setAddNewCharOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewSettingItem
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewSettingItemOpen}
				setOpen={setAddNewSettingItemOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewFolder
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewFolderOpen}
				setOpen={setAddNewFolderOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<AddNewImage
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewImageOpen}
				setOpen={setAddNewImageOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
				setting={setting}
				characters={characters}
				stories={stories}
				setImagesToRenderFunc={setImagesToRenderFunc}
				setTokensFillModal={setTokensFillModal}
			/>
			<AddNewStory
				setCardsToRenderFunc={setCardsToRenderFunc}
				open={addNewStoryOpen}
				setOpen={setAddNewStoryOpen}
				episodesArray={episodesArray.current}
				cardsToRenderLength={cardsToRender.length}
			/>
			<TabChangeModal open={openCardModal} setOpen={setOpenCardModal} onSubmit={onCardHandleClick}/>
			<AutoFillTokensModal open={tokensFillModal} setOpen={setTokensFillModal} currentProjectName={currentProject.title} noCountdown={false}/>
			<CssBaseline />

			{projectId &&
				(pageQueryValue === 'pages' ||
					pageQueryValue === 'characters' ||
					pageQueryValue === 'setting' ||
					pageQueryValue === 'images' ||
					pageQueryValue === 'story' ||
					pageQueryValue === 'folders') && (
					<Drawer
					sx={(theme) => ({
							position: {
								md: 'static',
								xs: 'absolute',
							},
							display: {
								xs:
									query.get('item') &&
									query.get('item') !== 'null'
										? 'none'
										: 'block',
								md: 'block',
							},
							width: {
								md: 240,
								xs: '100vw',
							},
							flexShrink: 0,
							zIndex: '2',
							'& .MuiDrawer-paper': {
								width: {
									md: 240,
									xs: '100vw',
								},
								boxSizing: 'border-box',
								top: '160px',
								left: '0',
								height: 'calc(100vh - 160px)',
								backgroundColor: theme.palette.background.alt,
							},
						})}
						variant='permanent'
						anchor='left'
					>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId='card-list'>
								{(provided) => (
									<Box
										sx={{
											marginTop: 3,
											marginBottom: {
												xs: 10,
												md: 8,
											},
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											gap: '20px',
										}}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{cardsToRender.length < 1 && (
											<div
												style={{
													marginTop: '70px',
													height: '100%',
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
												}}
											>
												Click "Add New" to begin creating.
											</div>
										)}
										{/* Render cards to side bar*/}
										{pageQueryValue === 'pages' &&
											cardsToRender.map((item, index) => (
												<ComicPageCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													imageIndex={item.imageIndex}
													page={item?.page}
													image={item.page?.image}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
												/>
											))}
										{pageQueryValue === 'images' &&
											cardsToRender.map((item, index) => (
												<ImageCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													image={item.image}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
													setting={setting}
													characters={characters}
													stories={stories}
												/>
											))}
										{pageQueryValue === 'characters' &&
											cardsToRender.map((item, index) => (
												<CharacterCards
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													characterId={
														item.characterId
													}
													character={item.character}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
												/>
											))}
										{pageQueryValue === 'setting' &&
											cardsToRender.map((item, index) => (
												<SettingCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													settingItemId={
														item.settingItemId
													}
													settingItem={
														item.settingItem
													}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
												/>
											))}
											{pageQueryValue === 'story' &&
											cardsToRender.map((item, index) => (
												<StoryCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													storyId={
														item.storyId
													}
													story={
														item.story
													}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
												/>
											))}
											{pageQueryValue === 'folders' &&
											cardsToRender.map((item, index) => (
												<ImageFolderCard
													setCardsToRenderFunc={
														setCardsToRenderFunc
													}
													key={index}
													index={index}
													onCardHandleClick={() =>
														onNavigateLinkClick(index)
													}
													folderId={
														item.folderId
													}
													folder={
														item.folder
													}
													episodeIndex={
														item.episodeIndex
															? item.episodeIndex
															: undefined
													}
												/>
											))}
										{provided.placeholder}
										<Box
											sx={(theme) => ({
												position: 'fixed',
												bottom: '0',
												bgcolor: theme.palette.background.alt,
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												gap: '5px',
												width: {
													md: 230,
													xs: 'calc(100vw - 10px)',
												},
												padding: '10px 0',
												zIndex: 5,
											})}
										>
											<Button
												variant='contained'
												onClick={() => {
													if (
														pageQueryValue ===
														'pages'
													)
														setAddNewPageOpen(true);
													if (
														pageQueryValue ===
														'characters'
													)
														setAddNewCharOpen(true);
													if (
														pageQueryValue ===
														'setting'
													)
														setAddNewSettingItemOpen(true);
													if (
														pageQueryValue ===
														'story'
													)
														setAddNewStoryOpen(true);
													if (
														pageQueryValue ===
														'images'
													)
														setAddNewImageOpen(true);
													if (
														pageQueryValue ===
														'folders'
													)
														setAddNewFolderOpen(true);
												}}
												sx={{
													width: {
														md: '50%',
														xs: '30%',
													},
													fontSize: '16px',
													color: 'white',
												}}
												disableElevation
											>
												Add new
											</Button>
											<Button
												variant='contained'
												onClick={() => {
													setAllCardsOpen(true);
												}}
												sx={{
													width: '20%',
													fontSize: '16px',
													color: 'white',
													height: '40px',
												}}
												disableElevation
											>
												<AppsIcon />
											</Button>
										</Box>
									</Box>
								)}
							</Droppable>
						</DragDropContext>
					</Drawer>
				)}
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					bgcolor: 'background.default',
					p: 3,
					overflowX: 'hidden',
					paddingBottom: {
						xs: '80px',
						//md: '200px',
					},
					paddingTop: {
						xs: '160px',
						md: '180px',
					},
				}}
			>
				<Button
					onClick={() => {
						onNavigateLinkClick(null);
					}}
					sx={{
						color: 'white',
						display: {
							xs:
								!query.get('item') ||
								query.get('item') === 'null'
									? 'none'
									: 'block',
							md: 'none',
						},
						borderRadius: '8px',
						height: '40px',
						textDecoration: 'underline',
					}}
				>
					{'<- Back'}
				</Button>
				<Toolbar
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'baseline',
						justifyContent: 'center',
						padding: '0'
					}}
				>
					{pageQueryValue === 'landing-page' && (
						<Box
						sx={{
						  height: '100%',
						  width: {
							sm: '100%', 
							md: '80%', 
							lg: '60%',
							xl: '50%' 
						  },
						  display: 'flex',
						  flexDirection: 'column',
						  justifyContent: 'center',
						  alignItems: 'center',
						  textAlign: 'center',
						  p: 1,
						//bgcolor: 'black',
						  borderRadius: 2,
						  //boxShadow: 3,
						  m: 'auto',
						  overflow: 'hidden',
						  mt: {xs: 5, sm: 0}
						}}
					  >
						<Typography color='white' fontSize={50} fontWeight={600}>Welcome to your project.</Typography>
						<Typography color='white' lineHeight={2} fontSize={20} fontWeight={300} mt={2} ml={5} mr={5} pb={2}>
						  Storybuilt.AI helps you to write your stories by breaking the writing process down into small pieces.  
						  Start by creating your characters and settings, or click the project cover image to use our Quickstart AI. 
						  </Typography> 
						  <Typography fontSize={15} mt={2} fontWeight={300}>Click below for more info, or click the tabs above to get started.</Typography>
						  <Box sx={{
							display: 'flex',  // This Box is a flex container
							flexDirection: { xs: 'column', sm: 'row' }, // Vertical stacking on xs, horizontal on sm and up
							width: '100%',  // Full width of the parent
							alignItems: 'center',  // Center items along the cross axis
							justifyContent: 'center',  // Center items along the main axis
						}}>
							<Button
								fullWidth
								variant='contained'
								sx={{
									mt: { xs: 2, sm: 1 },  // Top margin
									mb: { xs: 2, sm: 1 },  // Bottom margin: larger on xs for vertical spacing, smaller on sm for horizontal consistency
									color: '#fff',
									width: 'fit-content',  // Button width fits its content
									fontSize: 20,
									marginRight: { sm: 2, xs: 0 }  // Right margin: only applies on sm and larger for horizontal spacing
								}}
								onClick={() => handleClickOpen('characters')}
							>
								CHARACTERS
							</Button>
							<Button
								fullWidth
								variant='contained'
								sx={{
									mt: 1,
									mb: { xs: 2, sm: 1 },
									color: '#fff',
									width: 'fit-content',
									fontSize: 20,
									marginRight: { sm: 2, xs: 0 }  
								}}
								onClick={() => handleClickOpen('settings')}
							>
								SETTINGS
							</Button>
							<Button
								fullWidth
								variant='contained'
								sx={{
									mt: 1,
									mb: 1,  
									color: '#fff',
									width: 'fit-content',
									fontSize: 20
								}}
								onClick={() => handleClickOpen('story')}
							>
								STORY
							</Button>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
							<Typography mt={3} fontWeight={300} sx={{flexGrow: 1, flexShrink: 1}}> 
						  		Oh! You can brainstorm with the AI about your project or have it become one of your characters by clicking that chat icon 
							</Typography> 
						  		<ArrowRight sx={{position:'relative', mt:'25px', ml:'2px', fontSize:'40px', alignSelf: 'center', transform: 'rotate(90deg)'}}/> 
						</Box>
					  </Box>
					  
					)}
				  	<Dialog open={openChars} onClose={handleClose}>
						<DialogTitle>{"The Character Database"}</DialogTitle>
						<DialogContent>
							<DialogContentText><Typography fontSize={18} fontWeight={300}>
							On the characters page, you will create a list of all the characters in your story. Be they people, creatures, aliens, or animals. 
							<br></br><br></br>For each character, you will fill out a profile of information on them. As you fill out their profile, you can use the generate 🤖 button to help you fill out each field. 
							<br></br><br></br>The more you fill out, the better the AI will get at writing your character. 
							<br></br><br></br>Then, when you are on the Story tab writing your story, you'll be able to select the characters you've created so that the AI will have the background information that it needs to write your characters more accurately. 
							</Typography></DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Close</Button>
						</DialogActions>
						</Dialog>

						<Dialog open={openSettings} onClose={handleClose}>
						<DialogTitle>{"The Setting Database"}</DialogTitle>
						<DialogContent>
						<DialogContentText><Typography fontSize={18} fontWeight={300}>
							On the setting page, you will create a list of all the settings in your story. This can be any *where* your story takes place: where in place, in time, or in season. These things will help ground your story in the world that you are building. 
							<br></br><br></br>It will also help give the AI the context it needs to understand your version of this world. If you are writing a sci-fi story, a period piece, or a Christmas movie, the AI needs to know about what your version of these things looks like. Is it the 1950s booming America? Is it a galaxy far, far away? Is it a world filled with talking toys and giants?
							<br></br><br></br>Your unique take on these ideas is what will make your story stand out. As you fill out each setting, you can use the generate 🤖 button to help you. Just like on the characters page, the more you fill out, the better the AI will get at writing your setting.  
							<br></br><br></br>Then, when you are on the Story tab writing your story, you'll be able to select the settings you've created so that the AI will have the background information that it needs to bring your world to life. 
							</Typography></DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Close</Button>
						</DialogActions>
						</Dialog>

						<Dialog open={openStory} onClose={handleClose}>
						<DialogTitle>{"Where the magic happens: The Story Tab"}</DialogTitle>
						<DialogContent>
						<DialogContentText><Typography fontSize={18} fontWeight={300}>
							The Story page is what you've been building towards all this time. Here you will create the actual story that you want to tell.
							<br></br><br></br>The Storybuilt.AI approach to writing with AI is unique. We break the story down into small, managable, controllable pieces. You will work with the AI until you are happy with each piece.
							<br></br><br></br>We first have you select what type of story you are going to write. Is it a horror movie, a true crime drama, or maybe the next Avatar. This selection helps to determine the structure of your story that the AI will generate. 
							<br></br><br></br>After that, we break a story down into the following parts: A summary, a list of acts, a list of scenes for each act, a list of beats for each scene, and then the actual scene itself. 
							<br></br><br></br>Acts, scenes, and beats are all just smaller and smaller pieces of a story. This approach allows you to have total control over what the AI is generating for you so that it more closely aligns with your vision.
							</Typography></DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Close</Button>
						</DialogActions>
						</Dialog>

					{/* Display message when no cards to display */}
					{pageQueryValue === 'project-info' && projectId && (
					<ProjectInfo
						projectId={projectId}
						doesCardHaveUnsavedData={doesCardHaveUnsavedData}
						handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
						currentProject={currentProject}
						setTokensFillModal={setTokensFillModal}
					/>
					)}
					{pageQueryValue === 'characters' && cardsToRender.length === 0 && (
						<Box
							sx={{
								//height: '100vh',
								//width: '100vw',
								pt: '5vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No characters created yet. Click "Add New" to begin creating, or click the project cover image to access the Quickstart AI.</Typography>
						</Box>
					)}
					
					{pageQueryValue === 'setting' && cardsToRender.length === 0 && (
						<Box
						sx={{
							//height: '100vh',
							//width: '100vw',
							pt: '5vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No settings created yet. Click "Add New" to begin creating, or click the project cover image to access the Quickstart AI.</Typography>
					</Box>
					)}

					{pageQueryValue === 'story' && cardsToRender.length === 0 && (
						<Box
						sx={{
							//height: '100vh',
							//width: '100vw',
							pt: '5vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No stories created yet. Click "Add New" to begin creating, or click the project cover image to access the Quickstart AI.</Typography>
					</Box>
					)}

					{pageQueryValue === 'images' && cardsToRender.length === 0 && (
						<Box
						sx={{
							//height: '100vh',
							//width: '100vw',
							pt: '5vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No images created yet. Click "Add New" to begin creating, or click the project cover image to access the Quickstart AI.</Typography>
					</Box>
					)}

					{pageQueryValue === 'folders' && cardsToRender.length === 0 && (
						<Box
						sx={{
							//height: '100vh',
							//width: '100vw',
							pt: '5vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No image series created yet. Create a new series from inside your story.</Typography>
					</Box>
					)}

					{pageQueryValue === 'pages' && cardsToRender.length === 0 && (
						<Box
						sx={{
							//height: '100vh',
							//width: '100vw',
							pt: '5vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Typography bgcolor='black' borderRadius='10px' color='white' fontSize={20} fontWeight={300} m={5} p={10} >No storyboards created yet. Click "Add New" to begin creating, or click the project cover image to access the Quickstart AI.</Typography>
					</Box>
					)}
					{/* DISPLAY FIRST CARD WHEN PAGE FIRST LOADED AND ITEM NULL */}
					{(!query.get('item') || query.get('item') === 'null') &&
						cardsToRender.length > 0 && (
							<>
								{(pageQueryValue === 'folders' && pageRerenderFlag) && (
									//setTimeout(()=>onNavigateLinkClick(0),3000),
									<EditStoryboardFolder
										folder={cardsToRender[0]}
										setCardsToRenderFunc={setCardsToRenderFunc}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'folders' && !pageRerenderFlag) && (
									//setTimeout(()=>onNavigateLinkClick(0),3000),
									<EditStoryboardFolder
										folder={cardsToRender[0]}
										setCardsToRenderFunc={setCardsToRenderFunc}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'pages' && pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[0]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'pages' && !pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[0]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'characters' && (
									<EditCharacter
										character={cardsToRender[0]?.character}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'story' && (
									<EditStory
										story={
											cardsToRender[0]?.story
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										setting={setting}
										characters={characters}
										stories={stories}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										episodesArray={episodesArray}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'setting' && (
									<EditSetting
										propsSettingItem={
											cardsToRender[0]?.settingItem
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'images' && (
									<Box
										sx={{
											position: 'relative',
											width: {xs:'90vw', md:'60vh'},
											height: {xs:'90vw', md: '60vh'},
											background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
											cursor: 'pointer',
											overflow: 'hidden',
										}}
										onClick={() => window.open(constants.IMAGE_URL + cardsToRender[0]?.image?.image, '_blank')}
									>
										<CardMedia
											//component='div'
											sx={{
											//position: 'absolute',
											top: 0,
											left: 0,
											width: {xs:'90vw', md:'60vh'},
											height: {xs:'90vw', md: '60vh'},
											objectFit: 'cover',
											}}
											image={constants.IMAGE_URL + cardsToRender[0]?.image?.image}
											alt={'generated image'}
										/>
									</Box>	
								)}
							</>
						)}
					{/* DISPLAY SELECTED CARD ITEM DESCRIPTION */}

					{query.get('item') &&
						query.get('item') !== 'null' &&
						cardsToRender[query.get('item')] && (
							<>
								{(pageQueryValue === 'folders' && pageRerenderFlag) && (
									<EditStoryboardFolder
										folder={cardsToRender[query.get('item')]}
										setCardsToRenderFunc={setCardsToRenderFunc}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'folders' && !pageRerenderFlag) && (
									<EditStoryboardFolder
									folder={cardsToRender[query.get('item')]}
									setCardsToRenderFunc={setCardsToRenderFunc}
									setTokensFillModal={setTokensFillModal}
								/>
								)}
								{(pageQueryValue === 'pages' && pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[query.get('item')]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{(pageQueryValue === 'pages' && !pageRerenderFlag) && (
									<EditPage
										page={cardsToRender[query.get('item')]?.page}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										addNewImage={() => setAddNewImageOpen(true)}
										images={images}
										setImages={setImages}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'characters' && (
									<EditCharacter
										character={
											cardsToRender[query.get('item')]
												?.character
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'story' && (
									<EditStory
										story={
											cardsToRender[query.get('item')]
												?.story
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										setting={setting}
										characters={characters}
										stories={stories}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										episodesArray={episodesArray}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}

								{pageQueryValue === 'setting' && (
									<EditSetting
										propsSettingItem={
											cardsToRender[query.get('item')]
												?.settingItem
										}
										setCardsToRenderFunc={
											setCardsToRenderFunc
										}
										handleExportPdf={handleExportPdf}
										doesCardHaveUnsavedData={doesCardHaveUnsavedData}
										handleProjectHeaderVisibility={(flag)=> handleProjectHeaderVisibility(flag)}
										currentProject={currentProject}
										setTokensFillModal={setTokensFillModal}
									/>
								)}
								{pageQueryValue === 'images' && (
									<Box
										sx={{
											position: 'relative',
											width: {xs:'90vw', md:'60vh'},
											height: {xs:'90vw', md: '60vh'},
											background: 'linear-gradient(to bottom left,  #11D3AF, #6637C4)',
											cursor: 'pointer',
											overflow: 'hidden',
										}}
										onClick={() => window.open(constants.IMAGE_URL + cardsToRender[query.get('item')]?.image?.image, '_blank')}
									>
										<CardMedia
											//component='div'
											sx={{
											//position: 'absolute',
											top: 0,
											left: 0,
											width: {xs:'90vw', md:'60vh'},
											height: {xs:'90vw', md: '60vh'},
											objectFit: 'cover',
											}}
											image={constants.IMAGE_URL + cardsToRender[query.get('item')]?.image?.image}
											alt={'generated image'}
										/>
									</Box>	
								)}
							</>
						)}
				</Toolbar>
			</Box>
		</Box>
		</>
	);
};

export default Project;
