import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Box, Button, Modal, TextField, FormControl, Select, MenuItem, InputLabel, IconButton, Typography, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import { logout } from '../../../store/slices/authSlice';
import imageCompression from 'browser-image-compression';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '100%',
		md: '600px',
	},
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
};

export default function AddNewSettingItem({
	open,
	setOpen,
	episodesArray,
	setCardsToRenderFunc,
	cardsToRenderLength
}) {
	const handleClose = () => setOpen(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const navigate = useNavigate();
	const episodesQueryValue = query.get('episodes');
	const token = useSelector((state) => state.auth.token);
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const [title, setTitle] = useState();
	const [searchParams, setSearchParams] = useSearchParams();

	const [images, setImages] = useState([]);
	const [imageFile, setImageFile] = useState('');
	const [imageInput, setImageInput] = useState('');
	const [imageName, setImageName] = useState('');
	const [imagePreview, setImagePreview] = useState(null); 

	async function getImages() {
		try {
			const response = await axios.get(`${constants.BASE_URL}api/projects/get-images/${projectId()}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const filteredData = response.data?.images.filter(item => item !== null);
			setImages(
				filteredData.map((image, index) => ({
					image: image,
					index: index,
				}))
			); 
		} catch (error) {
			console.error('Failed to fetch images:', error);
			// Assuming there's an alert context or similar error handling
		}
	}

	useEffect(() => {
		if (open) {
		  getImages();
		  //setImageFile('');
			setImageInput('');
			setImageName('');
			setTitle('');
			setImagePreview(null);
		}
	  }, [open]);

	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		data.append('order',cardsToRenderLength)
		if (data.get('image')?.size === 0) data.delete('image');

		// Default Image
		if (!imageInput && !data.get('image') && data.get('title')) {
			data.append('image', 'default'); 
			await request(data);
			return;
		}

		// From Image Gen
		if (imageInput && imageFile === "" && data.get('title')) {
			data.set('image', imageInput); 
			await request(data);
			return;
		}

		if (data.get('title') && !imageInput && data.get('image')) {
			if (await checkImageSize(imageInput)) {
				dispatch(addLoadingFlag('set-setting'));
				if (data.get('image')) {
					const file = data.get('image')
					data.delete('image');

					try {
						const options = {
							maxSizeMB: 0.9,
						}
						const compressedFile = await imageCompression(file, options);
						data.append('image',compressedFile)
						await request(data)

					} catch (error) {
						alert.show(
							`Something went wrong with image compression.`,
							{ type: 'error' }
						);
					}
				} else {
					await request(data)
				}
			}
		}

		async function request (updateData) {
			if (episodesQueryValue !== '0' && episodesArray[parseInt(episodesQueryValue) - 1]?.id) {
				dispatch(addLoadingFlag('set-setting'));
				await axios
					.post(
						constants.BASE_URL +
							'api/projects/set-episode-setting/' +
							episodesArray[parseInt(episodesQueryValue) - 1]
								?.id,
						updateData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						handleClose();
						const params = {};
						for(let entry of searchParams.entries()) {
						  params[entry[0]] = entry[1];
						}
						// console.log(params)
						setSearchParams({...params, item: cardsToRenderLength})
						alert.show('New setting was successfully added!', {
							type: 'success',
						});
						setImageFile('');
						setImageInput('');
						setImageName('');
						setTitle('')
						setImagePreview(null);
						dispatch(removeLoadingFlag('set-setting'));
						setCardsToRenderFunc();
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('set-setting'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
						
							alert.show(
								`Something went wrong, could not add illustrations.`,
								{ type: 'error' }
							);
						
					});
			} else {
				dispatch(addLoadingFlag('set-setting'));
				console.log('update data', updateData.image)
				await axios
					.post(
						constants.BASE_URL +
							'api/projects/set-setting/' +
							projectId(),
						updateData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then(function (response) {
						handleClose();
						const params = {};
						for(let entry of searchParams.entries()) {
						  params[entry[0]] = entry[1];
						}
						// console.log(params)
						setSearchParams({...params, item: cardsToRenderLength})
						alert.show('New setting was successfully added!', {
							type: 'success',
						});
						setImageFile('');
						setImageInput('');
						setImageName('');
						setTitle('')
						setImagePreview(null);
						dispatch(removeLoadingFlag('set-setting'));
						setCardsToRenderFunc();
						window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
					})
					.catch(function (error) {
						dispatch(removeLoadingFlag('set-setting'));
						if (error.response?.data === 'User does not exist.') {
							navigate('login');
							dispatch(logout());
						}
					
							alert.show(
								`Something went wrong, could not add illustrations.`,
								{ type: 'error' }
							);
						
					});
			}
		}
	};
	const handleFileChange = (event) => {
		// console.log(event);
		const file = event.target.files[0];
		// console.log(file);
		if (file) {
			if (file.size > 1024 * 1024 * 4) {
				// console.log('error');
				setImageFile('');
				setImageInput('');
				setImageName('');
				setImagePreview(null);
				// 4MB in bytes
				alert.show(`Image is too large. Maximum allowed size is 4MB.`, {
					type: 'error',
				});
			} else {
				// Proceed with image upload
				setImageFile(event.target.value);
				setImageInput('');
				setImageName(file.name);
				const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
			}
		}
	};

	const checkImageSize = (url) => {
		return fetch(url)
			.then((response) => {
				if (response.headers.get('content-length') > 1024 * 1024 * 4) {
					alert.show(
						`Image is too large. Maximum allowed size is 4MB.`,
						{
							type: 'error',
						}
					);
					setImageFile('');
					setImageInput('');
					setImageName('');
					setImagePreview(null);
					return false;
				} else {
					// Proceed with using the image
					setImageFile('');
					setImageInput(url);
					setImageName('');

					return true;
				}
			})
			.catch((error) => {
				alert.show(
					`Something wrong with image`,
					{ type: 'error' }
				);
				console.error('Error fetching the image:', error);
				return false;
			});
	};
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={style}
				>
					<Typography textAlign='center' sx={{ mb: 2, p: 0 }}>ADD NEW SETTING</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
					<Box
						sx={{
							maxHeight: '75vh',
							overflow: 'scroll',
							paddingRight: '8px',
							'&::-webkit-scrollbar': {
								width: '2px',
								height: 0,
							},
							'&::-webkit-scrollbar-thumb': {
								borderRadius: '6px',
								backgroundColor: constants.PRIMARY,
							},
						}}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='title'
							label='Title'
							name='title'
							autoFocus
							placeholder='Setting Title'
							value={title}
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setTitle(e.target.value)}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<FormControl fullWidth margin="normal">
								<InputLabel id="image-select-label">Select from Image Gen</InputLabel>
								<Select
									labelId="image-select-label"
									name='imageSelect'
									id="imageSelect"
									value={imageInput}
									label="Select from Image Gen"
									onChange={(e) => {
										setImageFile('');
										setImageName('');
										setImageInput(e.target.value);
										setImagePreview(constants.IMAGE_URL + e.target.value);
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												'&::-webkit-scrollbar': {
													width: '2px',
													height: '0px',
												},
												'&::-webkit-scrollbar-thumb': {
													borderRadius: '6px',
													backgroundColor: constants.PRIMARY, // Use your theme's primary color or a custom color
												},
											}
										}
									}}
								>
									<MenuItem value=''>None</MenuItem>
									{images.map((item, index) => (
										<MenuItem key={index} value={item.image.image}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Use flexbox here */}
												<ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}> {/* Reduce default padding */}
													<img src={constants.IMAGE_URL + item.image.image} alt="" style={{ width: 50, height: 50 }} />
												</ListItemIcon>
												<ListItemText primary={item.image.title} />
											</Box>
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Typography sx={{ mx: 2, whiteSpace: 'nowrap' }}>OR</Typography>
							<Button
								variant='contained'
								component='label'
								sx={{
									height: '60px',
									marginTop: '16px',
									marginBottom: '8px',
								}}
							>
								Upload
								<input
									name='image'
									//value={imageFile}
									hidden
									accept='image/*'
									onChange={(e) => {
										setImageInput('');
										handleFileChange(e);
									}}
									type='file'
								/>
							</Button>
						</div>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center', // Centers horizontally
								alignItems: 'center', // Centers vertically
							}}
								>
						{imagePreview && (
                            <img
                                src={imagePreview}
                                alt=""
                                style={{width: '100%', maxWidth: '300px', marginTop: '10px' }}
                            />
                        )}
						</Box>
					</Box>
					{!imagePreview && (<Typography color="GrayText" ml={1} mt={1} mb={-2}>Leave blank for default image.</Typography>)}
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
					>
						Create
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
