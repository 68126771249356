import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import * as constants from '../../helpers/constants';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  CardMedia,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
} from '@mui/material';
import MuiLink from '@mui/material/Link';
import logo from '../../assets/images/logo.png';
import logoLong from '../../assets/images/logo-long.png';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
 
const pricingPlans = [
  { title: "Individual", price: ["$5/month", <br></br>, <Typography mt='-5px' display="block"> plus AI tokens </Typography>],  features: ["FREE 30 DAY TRIAL with Promo Code: LAUNCH", "Story and Image Generation", "Story Analysis and Script Coverage", "Comes with 2000 tokens. Additional tokens may be purchased."] },
  { title: "Team", price: ["$10/month", <br></br>, <Typography mt='-5px'>per seat</Typography>], features: ["50% off with Promo Code: LAUNCH", "Admin account with user management control", "Each seat comes with 5000 tokens", "Admin can purchase additional tokens if needed"] },
  //{ title: "Enterprise", price: "$60/month", features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"] }
];  

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MuiLink color="primary" href="#">
      Storybuilt.AI
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const stateLoading = useSelector((state) => state.loading.loading);
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [types, setTypes] = useState({})
  const currentFullUrlWithoutParams = `${window.location.protocol}//${window.location.host}`;

  const handleSubmit = async () => {
    console.log('submit  triggered')
    if (stateLoading) {
      console.log("Form is blocked due to loading state");
      return
    }
    if (!constants.EMAIL_REGEXP.test(email)) {
      setIsEmailValid(false);
      alert.show('Email is not valid.',{type: 'error'})
      return
    } else if (password.length < 6) {
      setIsPasswordValid(false);
      alert.show('Password needs to be at least 6 characters.',{type: 'error'})
      return
    } else {
      dispatch(addLoadingFlag('register-load'))
      console.log('email', email)
      sessionStorage.setItem('stripeUser', JSON.stringify({ name, email, password, role: 'User', type: 'Default' }));
      console.log('url', currentFullUrlWithoutParams)
      await axios.post(constants.BASE_URL + 'api/auth/register', {
        name: name,
        email: email,
        password: password,
        base_url: currentFullUrlWithoutParams
      })
      .then(function (response) {
        console.log("Redirecting to Stripe checkout");
        window.location.replace(response.data.url); // Redirect to Stripe checkout page
      })
      .catch(function (error) {
        dispatch(removeLoadingFlag('register-load'));
        setIsEmailValid(false);
        setIsPasswordValid(false);
        alert.show('Error or User may already exist', { type: 'error' });
      });
    }
  };

  const handleSubmitEnterprise = async () => {
    console.log('submit enterprise triggered');
    if (stateLoading) {
      console.log("Form is blocked due to loading state");
      return;
    }
    if (!constants.EMAIL_REGEXP.test(email)) {
      setIsEmailValid(false);
      alert.show('Email is not valid.', { type: 'error' });
      return;
    } else if (password.length < 6) {
      setIsPasswordValid(false);
      alert.show('Password needs to be at least 6 characters.', { type: 'error' });
      return;
    } else {
      dispatch(addLoadingFlag('register-load'));
      sessionStorage.setItem('stripeUser', JSON.stringify({ name, email, password, role: 'Enterprise Admin', type: 'Enterprise' }));
      await axios.post(constants.BASE_URL + 'api/auth/register-enterprise-admin', {
        name: name,
        email: email,
        password: password,
        base_url: currentFullUrlWithoutParams
      })
      .then(function (response) {
        console.log("Redirecting to Stripe checkout");
        window.location.replace(response.data.url); // Redirect to Stripe checkout page
      })
      .catch(function (error) {
        dispatch(removeLoadingFlag('register-load'));
        setIsEmailValid(false);
        setIsPasswordValid(false);
        alert.show('Error or User may already exist', { type: 'error' });
      });
    }
  };


  const checkUserExist = async (e) => {
    if (stateLoading) {
      console.log("Form is blocked due to loading state");
      return
    }
    e.preventDefault();
    console.log('check user exist')
    const data = new FormData(e.currentTarget);
    if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
      setIsEmailValid(false);
      alert.show('Email is not valid.',{type: 'error'})
      return
    } else if (data.get('password').length < 6) {
      setIsPasswordValid(false);
      alert.show('Password needs to be at least 6 characters.',{type: 'error'})
      return
    } else {
      dispatch(addLoadingFlag('register-load'))
      await axios.post(constants.BASE_URL + 'api/auth/check-exist', {
        name: data.get('name'),
        email: data.get('email'),
        password: data.get('password'),
      })
      .then(async function (response) {
        setIsEmailValid(true);
        setIsPasswordValid(true);
        console.log('response',response)
        const userTypes = await axios.get(constants.BASE_URL + 'api/auth/get-user-types');
        setTypes(userTypes.data)
        console.log('types',userTypes.data)
        const queryParams = new URLSearchParams(location.search); // Get query parameters
        console.log('query',queryParams)
          if (queryParams.get('plan') === 'team') {
            handleSubmitEnterprise();
          } else if (queryParams.get('plan') === 'user') {
            handleSubmit();
          } else {
            setStep(2);
          }
        dispatch(removeLoadingFlag('register-load'))
      })
      .catch(function (error) {
        dispatch(removeLoadingFlag('register-load'))
        setIsEmailValid(false);
        setIsPasswordValid(false);
        alert.show('Wrong info or user already exists',{type: 'error'})
      });
    }
  };

  console.log('types',stateLoading)
  if (stateLoading) {
    return  <Loader />
  }
    return (
      <>
      {/* Navigation Bar */}
      <AppBar position="static" sx={{ backgroundColor: 'black', pt: 1 }}>
                <Toolbar>
                    {/* Branding/Logo */}
                    <Typography variant="h6" sx={{ flexGrow: 1, mt: 1 }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box component="img" src={logoLong} alt='' sx={{height: { xs: '45px', md: '80px' } }} />
                        </Link>
                    </Typography>

                    {/* Navigation Links */}
                    <Box sx={{ display: 'flex', gap: {xs:1,md:2} }}>
                        {/* <Button color="inherit" component={Link} to="/feature1">
                            Feature 1
                        </Button>
                        <Button color="inherit" component={Link} to="/feature2">
                            Feature 2
                        </Button>
                        <Button color="inherit" component={Link} to="/feature3">
                            Feature 3
                        </Button> */}
                        <Button variant="contained" 
                            component={Link} 
                            to="/register" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 40px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Join
                        </Button>
                        <Button variant="contained" 
                            component={Link} 
                            to="/login" 
                            color="secondary" 
                            sx={{ 
                                mt: 2, 
                                mb: 2, 
                                fontSize: { xs: '1rem', md: '1.25rem' },
                                padding: { xs: '8px 16px', md: '12px 24px' },
                                height: { xs: '45px', md: '55px' } }}>
                            Sign In
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: constants.ORANGE }}> */}
              {/* <LockOutlinedIcon /> */}
              {/* <CardMedia
                  component="img"
                  sx={{
                      height: 60,
                      width: 'auto',
                      cursor: 'pointer'
                    }}
                  image={logo}
                  alt="#"
              /> */}
            {/* </Avatar> */}
            <Typography component="h1" variant="h5" mt="0px">
              Create a New Account
            </Typography>
            {step === 1 && 
            <Box component="form" onSubmit={checkUserExist} sx={{ mt: 0 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Your Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="name"
                variant="filled"
                autoFocus
              />
              <TextField
                margin="normal"
                variant="filled"
                required
                fullWidth
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!isEmailValid}
              />
              <TextField
                margin="normal"
                required
                variant="filled"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                error={!isPasswordValid}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Next
              </Button>
              {/* <Typography textAlign='center'> After you click create, you will be taken to Stripe to fill out your payment information.</Typography> */}
            </Box>
            }
            {step === 2 && 
            <Box component="form" sx={{ mt: 0 }}>
              <Typography textAlign='center'>Please Choose Your Membership Type</Typography>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', gap: 2, textAlign: 'center' }}>
                {pricingPlans.map((plan, index) => (
                    <Card key={index} raised sx={{ width: 310, height: 475, display: 'flex', flexDirection: 'column', margin: 2 }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" component="h2" gutterBottom color='primary'>
                            {plan.title}
                        </Typography>
                        <Typography variant="h4" fontWeight='300' color="primary" mt='-10px'>
                            {plan.price}
                        </Typography>
                            <ul style={{ textAlign: 'left', padding: 2 }}>
                                {plan.features.map((feature, featureIndex) => (
                                    <Typography component="li" key={featureIndex} sx={{ textAlign: 'left', mb: 1, ml: 3, mr: 2 }}>
                                        {feature}
                                    </Typography>
                                ))}
                            </ul>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 5 }}>
                            {index === 1 ? (
                                <Button variant="contained" color="primary" onClick={() => handleSubmitEnterprise()} sx={{ color: 'white' }}>
                                    Choose Plan
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={() => handleSubmit()} sx={{ color: 'white' }}>
                                    Choose Plan
                                </Button>
                            )}
                        </Box>
                    </Card>
                ))}
            </Box>
            <Typography textAlign='center'> After you choose a plan, you will be taken to Stripe to fill out your payment information.</Typography>

            <Button
                onClick={() => setStep(1)}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Back to previous step
              </Button>
             {/*  <Box sx={{display: 'flex', justifyContent: 'space-between' , mt: 4, mb: 4}}>
                <Box sx={{padding: '15px', borderRadius: '10px', background: 'grey' ,textAlign: 'center'}}>
                  <Typography>Individual</Typography>
                  <Typography>some description</Typography>
                  <Typography>per month: {types?.user?.unit_amount_decimal?.slice(0,-2)}.{types?.user?.unit_amount_decimal?.slice(-2)}$</Typography>
                  <Button
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: '#fff' }}
                  >
                    Create
                  </Button>
                </Box>
                <Box sx={{padding: '15px', borderRadius: '10px', background: 'grey' ,textAlign: 'center'}}>
                  <Typography>Enterprise</Typography>
                  <Typography>some description</Typography>
                  <Typography>per month: {types?.enterprise?.unit_amount_decimal?.slice(0,-2)}.{types?.enterprise?.unit_amount_decimal?.slice(-2)}$</Typography>
                  <Button
                    onClick={() => handleSubmitEnterprise()}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: '#fff' }}
                  >
                    Create
                  </Button>
                </Box>
              </Box> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Create
              </Button> */}
            </Box>}
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
        </>
  );
};

export default Register;
