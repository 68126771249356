import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: localStorage.getItem('email'),
  token: localStorage.getItem('access_token'),
  isAuthenticated: !!localStorage.getItem('access_token'), // Ensure this is a boolean
  generationTokens: 0,
  userId: localStorage.getItem('userId'),
  role: localStorage.getItem('role'),
  hideTokensFillModal: localStorage.getItem('hideTokensFillModal'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {      
      state.user = action.payload.email;
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.hideTokensFillModal = action.payload.hideTokensFillModal;
      state.isAuthenticated = !!action.payload.token; // Ensure this is correctly set as a boolean
      state.role = action.payload.role;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.userId = null;
      state.generationTokens = null;
      state.isAuthenticated = false;
      state.role = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('email');
      localStorage.removeItem('tutorialFlag');
      localStorage.removeItem('userId');
      localStorage.removeItem('role');
      localStorage.removeItem('hideTokensFillModal');
    },
    setTokens: (state, action) => {
      state.generationTokens = action.payload;
    },
    setGideTokensFillModal: (state, action) => {      
      state.hideTokensFillModal = action.payload;
    },
  },
});

export const { setUser, logout, setTokens, setGideTokensFillModal } = authSlice.actions;
export default authSlice.reducer;
