import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import * as constants from '../../helpers/constants';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const navigate = useNavigate();
  return (
    <Container component="main" maxWidth='lg'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: {
            xs: 15,
            md: 15,
          },
          marginBottom: 4,
          display: {
            xs: 'flex',
            md: 'grid',
          },
          justifyContent: {
            md: 'space-between',
            xs: 'space-around',
          },
          gridTemplateColumns: '1fr 1fr 1fr',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          flexWrap: {
            xs: 'wrap',
          },
          gap: '30px',
          position: 'relative',
          paddingBottom: '100px',
        }}
      >
        <Box
          onClick={() => navigate('/admin/version-control')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            width: '100%',
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <Typography align="center" sx={{ fontSize: '20px', fontWeight: 'bold' }}>Version Control</Typography>
        </Box>
        <Box
          onClick={() => navigate('/admin/fees')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            width: '100%',
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <Typography align="center" sx={{ fontSize: '20px', fontWeight: 'bold' }}>Fees</Typography>
        </Box>
        <Box
          onClick={() => navigate('/admin/generation-transactions')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            width: '100%',
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <Typography align="center" sx={{ fontSize: '20px', fontWeight: 'bold' }}>Generation Transactions</Typography>
        </Box>
        <Box
          onClick={() => navigate('/admin/prompts')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            width: '100%',
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <Typography align="center" sx={{ fontSize: '20px', fontWeight: 'bold' }}>Prompts</Typography>
        </Box>
        <Box
          onClick={() => navigate('/admin/user-list')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            width: '100%',
            backgroundColor: constants.DARK_GREY,
            borderRadius: '8px',
            padding: '15px',
            cursor: 'pointer',
            border: '1px solid ' + constants.TEAL,
          }}
        >
          <Typography align="center" sx={{ fontSize: '20px', fontWeight: 'bold' }}>User List</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Admin;

