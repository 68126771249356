import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, TextField, Typography, Link } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../../helpers/constants'
import Loader from '../../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

const Fees = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();

  const [projectState, setProjectState] = useState({
    title: 0,
    tagline: 0,
    quickstartSummary: 0,
  });
  
  const [characterState, setCharacterState] = useState({
    name: 0,
    age: 0,
    gender: 0,
    origin: 0,
    skills: 0,
    look: 0,
    relationships: 0,
    motivation: 0,
    emotions: 0,
    backstory: 0,
  });

  const [settingState, setSettingState] = useState({
    title: 0,
    environment: 0,
    area: 0,
    time_of_year: 0,
    unique_characteristics: 0,
    atmosphere: 0,
    climate: 0,
  });

  const [storyState, setStoryState] = useState({
    summary: 0,
    list_of_acts: 0,
    list_of_scenes: 0,
    list_of_beats_for_the_scene: 0,
    scene_full_script: 0,
  });

  const [oneImageGenerationCost, setOneImageGenerationCost] = useState(0);
  const [oneSceneImageGen, setOneSceneImageGen] = useState(0);
  const [chatMessage, setChatMessage] = useState(0);
  const [storyBuild, setStoryBuild] = useState(0);

  const handleProjectChange = (field, value) => {
    setProjectState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
 
  const handleCharacterChange = (field, value) => {
    setCharacterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSettingChange = (field, value) => {
    setSettingState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStoryChange = (field, value) => {
    setStoryState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(()=> {
    dispatch(addLoadingFlag('get-fees'));
    axios
    .get(
      constants.BASE_URL + 'api/fees/get-fees',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-fees'));
      alert.show(
        `Fees were successfully changed`,
        { type: 'success' }
      );
      if(response.data.hasOwnProperty('project')){
        setProjectState(response.data.project)
      }
      if(response.data.hasOwnProperty('characters')){
        setCharacterState(response.data.characters)
      }
      if(response.data.hasOwnProperty('setting')){
        setSettingState(response.data.setting)
      }
      if(response.data.hasOwnProperty('story')){
        setStoryState(response.data.story)
      }
      if(response.data.hasOwnProperty('oneImageGenerationCost')){
        setOneImageGenerationCost(response.data.oneImageGenerationCost)
      }
      if(response.data.hasOwnProperty('oneSceneImageGen')){
        setOneSceneImageGen(response.data.oneSceneImageGen)
      }
      if(response.data.hasOwnProperty('chatMessage')){
        setChatMessage(response.data.chatMessage)
      }
      if(response.data.hasOwnProperty('storyBuild')){
        setStoryBuild(response.data.storyBuild)
      }
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      // console.log(error);
      dispatch(removeLoadingFlag('get-fees'));
    });
  },[alert,dispatch,token])
  const generatePrompts = () => {
    dispatch(addLoadingFlag('fees'));
    axios
    .post(
      constants.BASE_URL + 'api/fees/set-fees',{
        project: projectState,
        characters: characterState,
        setting: settingState,
        story: storyState,
        oneImageGenerationCost: oneImageGenerationCost,
        oneSceneImageGen,
        chatMessage: chatMessage,
        storyBuild: storyBuild
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('fees'));
      alert.show(
        `Fees were successfully changed`,
        { type: 'success' }
      );
      // console.log(response);  
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      // console.log(error);
      dispatch(removeLoadingFlag('fees'));
    });
  }

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          {!loading && <>
            <Box
                 sx={{
                  position: 'fixed',
                  top: 60, 
                  left: 0, 
                  width: '100%', 
                  display: 'flex',
                  flexDirection: 'row', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  backgroundColor: constants.DARK_GREY, 
                  zIndex: 1, 
                  gap: {
                    sm: 'none',
                    md: '30px',
                  }
                }}
              >
                <Button
          onClick={() => navigate('/generation-transactions')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Transactions
        </Button>
        <Button
          onClick={() => navigate('/fees')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Fees
        </Button>
        <Button
          onClick={() => navigate('/prompts')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Prompts
        </Button>
        <Button
          onClick={() => navigate('/user-list')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Users
        </Button>
          </Box>
              <Box
                sx={{
                  marginTop: {
                    xs: 15,
                    md: 15,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
                  <Button
                  onClick={() => generatePrompts()}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '56px',
                    color: 'white',
                    marginTop: '16px',
                    marginBottom: '8px',
                    fontSize: '36px',
                  }}
                >
                  SAVE
                </Button>
              </Box>
               
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Project AI Fees</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='title'
                  label='Project Title'
                  id='title'
                  InputLabelProps={{ shrink: true }}
                  value={projectState.title}
                  onChange={(e) => handleProjectChange('title', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='tagline'
                  label='Tagline'
                  id='tagline'                 
                  InputLabelProps={{ shrink: true }} 
                  value={projectState.tagline}
                  onChange={(e) => handleProjectChange('tagline', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='quickstartSummary'
                  label='Quickstart Summary'
                  id='quickstartSummary'                  
                  InputLabelProps={{ shrink: true }} 
                  value={projectState.quickstartSummary}
                  onChange={(e) => handleProjectChange('quickstartSummary', e.target.value)}
                />
              </Box>
              
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Character AI Fees</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='name'
                  label='Name'
                  type={'number'}
                  id='name'
                  InputLabelProps={{ shrink: true }}
                  value={characterState.name}
                  onChange={(e) => handleCharacterChange('name', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='age'
                  label='Age'
                  type={'number'}
                  id='age'                  
                  InputLabelProps={{ shrink: true }}  
                  value={characterState.age}
                  onChange={(e) => handleCharacterChange('age', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='gender'
                  label='Gender'
                  id='gender'
                  type={'number'}                
                  InputLabelProps={{ shrink: true }}  
                  value={characterState.gender}
                  onChange={(e) => handleCharacterChange('gender', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='origin'
                  label='Where are they from?'
                  id='origin'
                  type={'number'}                 
                  InputLabelProps={{ shrink: true }} 
                  value={characterState.origin}
                  onChange={(e) => handleCharacterChange('origin', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='look'
                  label='What is their physical description?'
                  id='look'
                  type={'number'}                 
                  InputLabelProps={{ shrink: true }}
                  value={characterState.look}
                  onChange={(e) => handleCharacterChange('look', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='skills'
                  label='Is there something unique about them? Unique skills?'
                  id='skills'
                  type={'number'}                  
                  InputLabelProps={{ shrink: true }}
                  value={characterState.skills}
                  onChange={(e) => handleCharacterChange('skills', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='relationships'
                  label='What are the relationships between other characters and this character?'
                  id='relationships'
                  type={'number'}                 
                  InputLabelProps={{ shrink: true }}  
                  value={characterState.relationships}
                  onChange={(e) => handleCharacterChange('relationships', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='motivation'
                  label='What motivates this character to action?'
                  id='motivation'
                  type={'number'}                 
                  InputLabelProps={{ shrink: true }}  
                  value={characterState.motivation}
                  onChange={(e) => handleCharacterChange('motivation', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='backstory'
                  label="What is this character's backstory?"
                  id='backstory'
                  type={'number'}                
                  InputLabelProps={{ shrink: true }}
                  value={characterState.backstory}
                  onChange={(e) => handleCharacterChange('backstory', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='emotions'
                  label='What emotional traits does this character display?'
                  id='emotions'
                  type={'number'}                  
                  InputLabelProps={{ shrink: true }} 
                  value={characterState.emotions}
                  onChange={(e) => handleCharacterChange('emotions', e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Setting AI Fees</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='title'
                  label='Title'
                  id='title'
                  InputLabelProps={{ shrink: true }}               
                  type={'number'}
                  value={settingState.title}
                  onChange={(e) => handleSettingChange('title', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='environment'
                  label='What is it?'
                  id='environment'
                  InputLabelProps={{ shrink: true }}                
                  type={'number'} 
                  value={settingState.environment}
                  onChange={(e) => handleSettingChange('environment', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='area'
                  label='Where is it?'
                  id='area'
                  InputLabelProps={{ shrink: true }}                 
                  type={'number'}     
                  value={settingState.area}
                  onChange={(e) => handleSettingChange('area', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='unique_characteristics'
                  label='What is unique about it?'
                  id='unique_characteristics'
                  InputLabelProps={{ shrink: true }}
                  type={'number'}
                  value={settingState.unique_characteristics}
                  onChange={(e) => handleSettingChange('unique_characteristics', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='time_of_year'
                  label='What time, year, or season is it?'
                  id='time_of_year'
                  InputLabelProps={{ shrink: true }}                
                  type={'number'}
                  value={settingState.time_of_year}
                  onChange={(e) => handleSettingChange('time_of_year', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='atmosphere'
                  label='Is there a mood or atmosphere about this particular setting?'
                  id='atmosphere'
                  InputLabelProps={{ shrink: true }}                
                  type={'number'}
                  value={settingState.atmosphere}
                  onChange={(e) => handleSettingChange('atmosphere', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='climate'
                  label='Does the climate play a role in the story?'
                  id='climate'
                  type={'number'}
                  InputLabelProps={{ shrink: true }} 
                  value={settingState.climate}
                  onChange={(e) => handleSettingChange('climate', e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography sx={{ marginBottom: '8px'}}>Story Generator AI Fees</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='summary'
                  label='Summary'
                  id='summary'
                  type={'number'}
                  InputLabelProps={{ shrink: true }}
                  
                  
                  
                  value={storyState.summary}
                  onChange={(e) => handleStoryChange('summary', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='list_of_acts'
                  label='Create a List of Acts'
                  id='list_of_acts'
                  type={'number'}
                  InputLabelProps={{ shrink: true }} 
                  value={storyState.list_of_acts}
                  onChange={(e) => handleStoryChange('list_of_acts', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  type={'number'}
                  name='list_of_scenes'
                  label='Create a List of Scenes'
                  id='list_of_scenes'
                  InputLabelProps={{ shrink: true }}
                  value={storyState.list_of_scenes}
                  onChange={(e) => handleStoryChange('list_of_scenes', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  type={'number'}
                  name='list_of_beats_for_the_scene'
                  label='Create Story Beats for this Scene'
                  id='list_of_beats_for_the_scene'
                  InputLabelProps={{ shrink: true }}
                  value={storyState.list_of_beats_for_the_scene}
                  onChange={(e) => handleStoryChange('list_of_beats_for_the_scene', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  type={'number'}
                  name='scene_full_script'
                  label={`Now Let's Write the Scene`}
                  id='scene_full_script'
                  InputLabelProps={{ shrink: true }}
                  value={storyState.scene_full_script}
                  onChange={(e) => handleStoryChange('scene_full_script', e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
              <Typography>Common Generator AI Fees</Typography>
             <TextField
                  margin='normal'
                  fullWidth
                  name='imageCost'
                  label={`One image generation tokens cost`}
                  id='imageCost'
                  type={'number'}
                  InputLabelProps={{ shrink: true }}
                  value={oneImageGenerationCost}
                  onChange={(e)=> setOneImageGenerationCost(e.target.value)}
                />
              <TextField
                margin='normal'
                fullWidth
                name='imageSceneCost'
                label={`One scene image generation token cost`}
                id='imageSceneCost'
                type={'number'}
                InputLabelProps={{ shrink: true }}
                value={oneSceneImageGen}
                onChange={(e)=> setOneSceneImageGen(e.target.value)}
              />
             <TextField
                  margin='normal'
                  fullWidth
                  name='chatCost'
                  label={`One chat message generation token cost`}
                  id='chatCost'
                  type={'number'}
                  InputLabelProps={{ shrink: true }}
                  value={chatMessage}
                  onChange={(e)=> setChatMessage(e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='storybuild'
                  label={`Storybuild (quickstart) token cost`}
                  id='storybuild'
                  type={'number'}
                  InputLabelProps={{ shrink: true }}
                  value={storyBuild}
                  onChange={(e)=> setStoryBuild(e.target.value)}
              />
              </Box>
            </Box>
          </>}
      </Container>
  );
};

export default Fees;

